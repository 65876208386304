<template>
    <div :class="wrapperClass">
        <div class="col-12">
            <input v-if="!(cName === 'pnvFile')" type="hidden" :value="!!payload.inner_choice ? payload.inner_choice : payload.choice" :name="hiddenFieldName" :id="fieldID">
            <pnvMenu v-if="cName === 'pnvMenu'" 
                :options="payload.options" 
                :placeholder="payload.placeholder" 
                :choice="theValue"
                :fieldID="fieldID"
                :label="payload.label"
                :config="payload.config"
                :dropDirection="payload.dropDirection"
                :choiceResetPossible="payload.choiceResetPossible"
                :choiceForceSet="payload.choiceForceSet"
                @setSelectedOption="chngChoice($event)"
                ></pnvMenu>

            <pnvMenu v-if="cName === 'pnvMatter'" 
                :options="matterOptions" 
                :placeholder="payload.placeholder" 
                :choice="theValue"
                :fieldID="fieldID"
                :label="payload.label"
                :config="payload.config"
                :dropDirection="payload.dropDirection"
                @setSelectedOption="chngChoice($event)"
                ></pnvMenu>

            <pnvDate v-if="cName === 'pnvDate'" 
                :gap="payload.gap"
                :yearDepth="payload.yearDepth"
                :stylesConfig="payload.config"
                :dropDirection="payload.dropDirection"
                :fieldID="fieldID"
                :choice="!!theValue ? theValue : {}"
                :label="payload.label"
                :outputConfig="payload.outputConfig"
                :upd="(payload.choice && 'ts' in payload.choice) ? payload.choice.ts : 0"
                @setSelectedOption="chngChoice($event)"
                ></pnvDate>

            <pnvString v-if="cName === 'pnvString'"
                :choice="payload.choice"
                :fieldID="fieldID"
                :label="payload.label" 
                :placeholder="!!payload.choice ? payload.choice : payload.placeholder"
                :title="payload.title"
                :autocompleteOn="acHelper"
                :acItems="acItems"
                @setStringValue="chngChoice($event)"
                @autocomplete="autocomplete($event)"
                ></pnvString>

            <pnvFile v-if="cName === 'pnvFile'"
                :choice="payload.choice"
                :fieldID="fieldID"
                :label="payload.label" 
                :fileType="payload.fileType"
                @setFileValue="chngChoice($event)"
                ></pnvFile>

            <pnvText v-if="cName === 'pnvText'" 
                :choice="payload.choice"
                :fieldID="fieldID"
                :label="payload.label" 
                :placeholder="payload.placeholder"
                :title="payload.title"
                @setTextValue="chngChoice($event)"
                ></pnvText>

            <pnvNumber v-if="cName === 'pnvNumber'" 
                :type="payload.type" 
                :choice="parseInt(payload.choice)"
                :fieldID="fieldID"
                :label="payload.label" 
                :placeholder="payload.placeholder"
                :title="payload.title"
                @setNumberValue="chngChoice($event)"
                ></pnvNumber>

            <pnvCheckbox v-if="cName === 'pnvCheckbox'" 
                @setChkbxValue="chngChoice($event)"
                :checkedState="payload.choice === 'true'" 
                :title_class="payload.title_class"
                :label_class="payload.label_class"
                :title="payload.title"
                :lmntId="fieldID"
                ></pnvCheckbox>

            <pnvRadio v-if="cName === 'pnvRadio'" 
                @setRadioValue="chngChoice($event)"
                :checkedState="payload.choice === 'true'" 
                :parent_class="payload.parent_class"
                :child_class="payload.child_class"
                :options="payload.options"
                :title="payload.title"
                ></pnvRadio>

            <pnvPacker v-if="cName === 'pnvPacker'" 
                @setJSON="chngChoice($event)"
                :fieldID="fieldID"
                :title="payload.title"
                :choice="payload.choice"
                :form_config="payload.form_config"
                ></pnvPacker>
        </div>
    </div>
</template>

<script>
import pnvMenu from '../low/inputs/pnvMenu.vue'
import pnvDate from '../low/inputs/pnvDate.vue'
import pnvFile from '../low/inputs/pnvFile.vue'
import pnvString from '../low/inputs/pnvString.vue'
import pnvText from '../low/inputs/pnvText.vue'
import pnvNumber from '../low/inputs/pnvNumber.vue'
import pnvCheckbox from '../low/inputs/pnvCheckbox.vue'
import pnvPacker from '../low/inputs/pnvPacker.vue'

export default {
    name: 'field',
    components: {
        pnvMenu,
        pnvCheckbox,
        pnvString,
        pnvText,
        pnvNumber,
        pnvDate,
        pnvFile,
        pnvPacker,
    },
    props: {
        cName: { type: String, required: true },
        payload: { type: Object, required: true },
        fieldID: { type: String, required: true },
        objName: { type: String, default: "" },
        wrapperClass: { type: String, default: "row my-2" },
        upd: { type: Number, default: 0 },
        acItems: { type: Object, default: function() { return {} } },
    },
    data () {
        return {
            theValue: ""
        }
    },
    computed: {
        acHelper () {
            return !!(this.payload.autocomplete?.path)
        },
        hiddenFieldName () {
            this.upd
            if (this.cName === 'pnvHiddenField') {
                this.chngChoice(this.payload.choice)
            }
            return this.setfieldID
        },
        setfieldID () {
            return this.objName.length === 0 ? this.fieldID : `${this.objName}[${this.fieldID}]`
        },
        matterOptions () {
            if (this.cName === 'pnvMatter') {
                if (!!this.payload.options) {
                    return this.payload.options
                } else {
                    return {
                        "-1": {
                            "id": -1,
                            "badge_name": "не важно",
                            "title": "не важно",
                            "dscr": ""
                        },
                        "1": {
                            "id": 1,
                            "badge_name": "да",
                            "title": "да",
                            "dscr": ""
                        },
                        "0": {
                            "id": 0,
                            "badge_name": "нет",
                            "title": "нет",
                            "dscr": ""
                        },
                    }
                }
            } else { return {}}
        },
    },
    mounted () {
        // console.log("choice: " + this.theValue)
        this.theValue = this.payload.choice
    },
    updated () {
        // console.log("updated-choice: " + this.theValue
        this.theValue = this.payload.choice
    },
    methods: {
        chngChoice(theVal) {
            /*console.log( "inputField-chngChoice:" )
            console.log([this.fieldID, theVal])*/
            this.$emit('chngVal', [this.fieldID, theVal]);
        },
        autocomplete (theVal) {
            this.$emit('autocomplete', [this.fieldID, theVal]);
        },
    }
}
</script>
