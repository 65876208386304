<template>
<div class="row" v-if="uploadPostReady">
    <div class="col-12">
        <div class="row">
            <div class="col-12" v-if="isRepost">
                <div>
                    Репост на:
                </div>
                <div>
                    Post_id: {{this.additionalInfo.repost_id}}
                </div>
                <pnvMenu
                    :options="selectWall.items"
                    :placeholder="selectWall.placeholder"
                    :config="selectWall.config"
                    :choice="selectWall.choice"
                    @setSelectedOption="setNewSelectedOption($event, selectWall);"
                >
                </pnvMenu>
            </div>
        </div>

        <div class="row">
            <form id="wallPostForm" class="col-12" accept-charset="UTF-8">
                <div class="row" style="margin-top: 10vh;">
                    <div class="col-12">
                        <wysiwyg 
                            :additionalInfo="additionalInfo"
                            @content-chng = "chngRqstContent($event)"
                            @savePostDraft="savePostDraft($event)">
                        </wysiwyg>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-3">
                        <pnvMenu 
                            :options="selectPrivacy.items"
                            :placeholder="selectPrivacy.placeholder"
                            :config="selectPrivacy.config"
                            :choice="selectPrivacy.choice"
                            @setSelectedOption="setNewSelectedOption($event, selectPrivacy);"
                            >
                        </pnvMenu>
                    </div>
                    <div class="col-3">
                        <pnvCheckbox v-if="openedCommunityWall" @setChkbxValue="updateCommPostFlag($event)" title="Пост от имени сообщества" lmntId="commPostFlag" :checkedState="additionalInfo.wallPost.comm_post">
                        </pnvCheckbox>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-lg lgrBtn1" @click="savePostDraft(rqstContent)">Сохранить черновик</button>
                    </div>
                    <div class="col-3"><!-- Submit btn -->
                        <input @click.prevent="saveWallPost()" type="submit" name="commit" :value="calcPostSubmitBtn" :data-disable-with="calcPostSubmitBtn" class="btn btn-lg grnBtn2">
                    </div><!-- Submit btn end -->
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import pnvMenu from "../low/inputs/pnvMenu.vue";
import wysiwyg from "../low/wysiwyg.vue";

export default {
    name: 'pnvPostForm',
    components: {
        pnvMenu,
        wysiwyg,
    },
    props: {
        additionalInfo: { type: Object },
    },
    data () {
        return {
            selectPrivacy: {
                items: {},
                placeholder: "Приватность",
                choice: "",
                config: {
                    backgroundColor: "#0062CC",
                    backgroundExpandedColor: "#0062CC",
                    textColor: "antiquewhite",
                    borderRadius: "1.5em",
                    border: "2px solid #ccc",
                    width: 100,
                }
            },
            selectWall: {
                items: {},
                placeholder: "Стена",
                choice: "",
                config: {
                    backgroundColor: "#0062CC",
                    backgroundExpandedColor: "#0062CC",
                    textColor: "antiquewhite",
                    borderRadius: "1.5em",
                    border: "2px solid #ccc",
                    width: 100,
                }
            },
            postContent: '',
            videoCollection: [],
            postServerState: '',
            difBtwnDraftAndPost: 100,
            uploadPostReady: false,
        }
    },
    mounted () {
        this.uploadPost();
        this.selectPrivacy.items = this.additionalInfo.privacy_choices
        this.selectWall.items = this.additionalInfo.wall_choices
    },
    computed: {
        isDraft() {
            return this.additionalInfo.wallPost == null
        },
        isRepost() {
            return this.additionalInfo.isRepost == 'true'
        },
        openedCommunityWall () {
            return this.additionalInfo.wall_type == "Community"
        },
        calcPostSubmitBtn () {
            return !!(this.additionalInfo.postId) ? "Редактировать" : "Написать" + " пост"
        },
        postDraftPath () {
            const id = this.additionalInfo.current_profile_id
            return `/${Helper.pluralModel('Profile')}/${id}/post_drafts/${id}.json`
        },
        createWallPostPath () {
            return '/' + Helper.pluralModel(this.additionalInfo.wall_type) + '/' + this.additionalInfo.wall_id + '/wall_posts.json'
        },
        updateWallPostPath () {
            return '/wall_posts/' + this.additionalInfo.postId
        },
    },
    methods: {
        chngRqstContent (theContent) {
            this.postContent = theContent
        },
        uploadPost() {
            self = this
            if (this.isDraft) {
                axios.get(self.postDraftPath, {"params": {"wall_type": this.additionalInfo.wall_type, "wall_id": this.additionalInfo.wall_id}}) // TODO Request autencity token
                .then(response => {
                    let [wallPost, img_id_url_hash, 
                        vid_link_url_hash, vid_src_url_hash, privacy_choices] = [
                    response.data.post, response.data.img_id_url_hash, 
                    response.data.vid_link_url_hash, response.data.vid_src_url_hash, response.data.privacy_choices]

                    Object.assign(self.additionalInfo, { wallPost, 
                        img_id_url_hash, vid_link_url_hash, vid_src_url_hash, privacy_choices })
                    
                    self.selectPrivacy.items = self.additionalInfo.privacy_choices
                    self.postServerState = self.postContent;
                    self.uploadPostReady = true
                })
                .catch(e => {
                    // self.errors.push(e)
                })    
            } else {
                let [wallPost, img_id_url_hash, 
                        vid_link_url_hash, vid_src_url_hash, privacy_choices] = [
                    self.additionalInfo.wallPost, self.additionalInfo.img_id_url_hash, 
                    self.additionalInfo.vid_link_url_hash, self.additionalInfo.vid_src_url_hash, self.additionalInfo.privacy_choices]

                Object.assign(self.additionalInfo, { wallPost, 
                    img_id_url_hash, vid_link_url_hash, vid_src_url_hash, privacy_choices })
                
                self.selectPrivacy.items = self.additionalInfo.privacy_choices
                self.postServerState = self.postContent;
                self.uploadPostReady = true
            }
        },
        savePostDraft(content) {
            axios.put(this.postDraftPath, {
                authenticity_token: this.additionalInfo.token,
                content: content
            })
            .then(response => {
                // self.postServerState = self.postContent;
            })
        },
        saveWallPost(content, isCommunityPost) {
            const app = this;
            axios({
                method: ( this.isDraft ? 'post' : 'put' ),
                url: ( this.isDraft ? this.createWallPostPath : this.updateWallPostPath ), 
                data: {
                    authenticity_token: app.additionalInfo.token, 
                    wall_posts: {
                        content: app.postContent,
                    
                        wall_type: (app.isRepost ? app.selectWall.items[app.selectWall.choice].wall_type : app.additionalInfo.wall_type),
                    
                        wall_id: (app.isRepost ? app.selectWall.items[app.selectWall.choice].wall_id : app.additionalInfo.wall_id),
                    
                        comm_post: isCommunityPost,
                        privacy_id: app.selectPrivacy.choice,
                        repost_id: app.additionalInfo.repost_id 
                    }
                }
            })
            .then(response => {
                if (!self.isRepost) {
                    let wallComp = Helper.findComponentByName(self, 'pnvwall');
                    if (self.isDraft) {
                        wallComp.$emit('addNewPost', response.data);
                    } else {
                        wallComp.$emit('updatePost', response.data);
                    }   
                }
                document.querySelector('#pnvModal').dispatchEvent(new CustomEvent('modal-show', { 'detail': { show: false } }));
            })
        },
        setNewSelectedOption(selectedOption, selectRatio) {
            selectRatio.choice = selectedOption;
        },
    }
}
</script>
