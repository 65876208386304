<template>
    <div class="row"><!-- wysiwig -->
        <div class="col-6"><!-- Editor -->
            <div class="row">
                <div class="col-12">
                    <h4>Редактор</h4>
                </div>
            </div>
            <div class="row">
                <div style="position:relative;" class="col-12">
                    <textarea v-model="wysContent" class="w-100" id="editorField">
                    </textarea>
                    <div id="emojiContainer">
                        <div>
                            <emoji-picker v-if="emojiBrdFlag" @emoji-click="inputMaterialTag('editorField', 'emoji', $event.detail.unicode)"></emoji-picker>
                        </div>
                        <div>
                            <img 
                                alt="Эмодзи" 
                                title="Выбрать эмодзи" 
                                :src="additionalInfo.assets.emojiImgUrl" 
                                class="btn btn-sm whtBtn1 ms-auto d-block"
                                @click="chngEmojiBrdFlag"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div name="Dropzone" class="col-6">
                    <div class='w-100 h-100 dropzone btn bluBtn1' id='entityDropzone'>
                    </div>
                </div>
                <div class="col-6"><!-- add video -->
                    <button class="btn bluBtn1" @click.prevent="addVideo('link')">Добавить видео из YouTube</button>
                    <input v-model="videoLink" class="d-none" id="video_link_field" placeholder="https://">
                    </input>
                </div><!-- add video end -->
            </div>
        </div><!-- Editor end -->
        <div class="col-6"><!-- Result -->
            <div class="row">
                <div class="col-12">
                    <h4> Результат </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12"><!-- preview -->
                    <pre v-html="wygContent"></pre>
                </div><!-- preview end -->
                <div id="preview_form" class="col-12 d-none"><!-- video preview -->
                    <div class="field w-50">
                        <iframe id="video_preview"></iframe>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <div @click="addFullVideoUrlByLink()"> Добавить </div>
                        </div>
                        <div class="col-2">
                            <div @click="clearVideoPreview()"> Отменить </div>
                        </div>
                    </div>
                </div><!-- video preview end -->
                <div class="col-12">
                    <div class="row"><h5>Загруженные изображения</h5></div>
                    <div class="row">
                        <div v-for="item, key in additionalInfo.img_id_url_hash" class="col-3">
                            <a @click.prevent="inputMaterialTag('editorField', 'img', key);"><img class="w-100" :src="item"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- Result end -->
    </div><!-- wysiwig end -->
</template>

<script>
import axios from 'axios';
import Dropzone from "dropzone";
import pnvCheckbox from "../low/inputs/pnvCheckbox.vue"
import 'emoji-picker-element';


export default {
    name: 'wysiwyg',
    components: {
        Dropzone,
        pnvCheckbox,
    },
    props: {
        additionalInfo: { type: Object },
    },
    data () {
        return {
            rqstContent: [],          // Post content
            wysContent: '',          // Show content with img_id
            wygContent: '',         // Show rev content, with img_url
            imgIdHash: {},         // Imgs hash {imgId: imgUrl}
            vidIdHash: {'<vid_link': '<iframe', 
                        'vid_link/>': '></iframe>',
                                // '<vid_src': '<iframe', 
                               // 'vid_src/>': '></iframe>',
                           }, // Vids hash { vidHardUrl: vidFullUrl }
            videoLink: '',
            videoID: '',
            revVideoLink: '',
            tempVal: "",
            errors: [],
            isCommunityPost: false,
            emojiBrdFlag: false,
        }
    },
    mounted () {
        this.loadPostDraft();
        this.dropzoneImgUploader();
    },
    watch: {
        wysContent: function(val) {
            this.wygContent = this.replaceTags(this.wysContent, this.imgIdHash);
            this.wygContent = this.replaceTags(this.wygContent, this.vidIdHash);
            this.packTextContent()
        },
        videoLink: function(videoLink) {
            if (videoLink != '') {
                let videoProvider = Helper.determineProvider(videoLink)

                this.videoID = Helper.getIDFromUrl(videoLink)
                let newUrlSrc = Helper.prepareSrc(videoProvider, this.videoID)
                document.getElementById('video_preview').src = newUrlSrc
                document.getElementById('preview_form').classList.remove("d-none")
                this.revVideoLink = newUrlSrc
            }
        },
        rqstContent: function (val) {
            this.$emit('content-chng', val);
        }
    },
    computed: {
        imgUploadPath () {
            if (this.additionalInfo.wallPost) {
                return `/add_post_image/?wall_post_id=${this.additionalInfo.postId}`
            } else {
                throw 'Несовместимы тип объекта и редактора!'
            }
        },
    },
    methods: {
        chngEmojiBrdFlag() {
            this.emojiBrdFlag = !this.emojiBrdFlag
        },
        updateCommPostFlag(theVal) {
            this.isCommunityPost = theVal
        },
        // textContent and Image methods
            replaceTags(textContent, tagMap){
                // Create regular expression with our tag map to 
                // replace usual html-tags with ours.
                if (Object.keys(tagMap).length === 0) {
                    return textContent
                } else {
                    let reg = new RegExp(Object.keys(tagMap).join("|"),"gi");
                    return textContent.replace(reg, function(matched){
                        return tagMap[matched];
                    });
                }
            },
            packTextContent() {
                this.rqstContent = this.convertArrayIntoJSON(
                    this.parseTextIntoSections(
                        this.removeEmptyVal(
                            this.splitByTag(
                                this.replaceBreaks()))));
            },
            parseTextIntoSections(theContent) {
                const self = this
                let newSections = []

                // TODO Make Object at once without further convertion of array into obj
                theContent.forEach(element => {
                    if (element.includes('src_id=')) {
                        let theId = element.split('src_id=')[1].replace(/\s/g, '')
                        newSections.push({'type': 'post_image', 'style': '','id': theId})
                    } else if (element.includes('src_link=')) {
                        let parsedLink = self.vidIdHash[element.replace(/\s/g, '')].split(/src='|'/g)[1].replace(/\s/g, ''),
                            hardLink = element.split('src_link=')[1].replace(/\s/g, '');
                        newSections.push({'type': 'video_link', 'style': '','parsed_link': parsedLink, 'hard_link': hardLink})
                    } else if (element.includes('vid_id=')) {
                        let theId = element.split('vid_id=')[1].replace(/\s/g, '')
                        newSections.push({'type': 'post_video', 'style': '', id: theId})
                    } else {
                        newSections.push({'type': 'text', 'style': '','content': element})
                    }
                })
                return newSections;
            },
            convertArrayIntoJSON(processingContent) {
                let theObj = {}

                processingContent.forEach((section, key) => {
                    theObj[key] = section;
                })
                return JSON.stringify(theObj);
            },
            inputMaterialTag(textID, tag, objID) {
                let startPos = $(`#${textID}`).prop('selectionStart'),
                endPos = $(`#${textID}`).prop('selectionEnd')

                if (tag === 'img') {
                    this.wysContent = this.wysContent.substring(0, startPos) + 
                            `<${tag} src_id=` + objID + ` ${tag}/>` +
                            this.wysContent.substring(endPos, this.wysContent.length)
                } else if (tag === 'vid_link') {
                    this.wysContent = this.wysContent.substring(0, startPos) + 
                            `<${tag} src_link=` + objID + ` ${tag}/>` +
                            this.wysContent.substring(endPos, this.wysContent.length)
                }/* else if (tag === 'vid_src') {
                    this.wysContent = this.wysContent.substring(0, startPos) + 
                            `<${tag} vid_id=` + objID + ` ${tag}/>` +
                            this.wysContent.substring(endPos, this.wysContent.length)
                }   */
                else if (tag === 'emoji') {
                    this.wysContent = this.wysContent.substring(0, startPos) + 
                        objID + this.wysContent.substring(endPos, this.wysContent.length)
                } else {
                    throw "Ошибка при вставке фото/видео. Неизвестный формат."
                    // Unknown format
                }
            },
            // TODO add ability to choose which tag to use.
            splitByTag(processingContent, tag) {
                return processingContent.split(/<img|<vid_link|\/\>|vid_link\/\>|img\/\>/g);
                // return textContent.split(/<img|<vid_link|<vid_src|vid_src\/\>|vid_link\/\>|img\/\>/g);
            },
            replaceBreaks() {
                return this.wysContent.replace(/(?:\r\n|\r|\n)/g, '<br>');
            },
            removeEmptyVal(processingContent) {
                return processingContent.filter(elem => elem != '')
            },
            updateImgHash(key, value, baseHash = null) {
                if (!baseHash) {
                    this.imgIdHash[`src_id=${key}`] = `src="${value}" contain width='250px'`;
                } else if (typeof(baseHash) == 'object') {
                    Object.entries(baseHash).forEach(([hkey, hvalue]) => {
                        this.imgIdHash[`src_id=${hkey}`] = `src="${hvalue}" contain width='250px'`;
                    })
                } else {
                    throw "Ошибка при загрузке хэша изображений."
                }
            },
            updateVidHash(srcType, key, value, baseHash = null) {
                let relTag = this.determineRelatedTag(srcType);
                if (!baseHash) {
                    this.vidIdHash[`${relTag}=${key}`] = `src='${value}'`;
                } else if (typeof(baseHash) == 'object') {
                    Object.entries(baseHash).forEach(([hkey, hvalue]) => {
                        this.vidIdHash[`${relTag}=${hkey}`] = `src='${hvalue.replace(/&amp;/g, '&')}'`;
                    })
                } else {
                    // console.log(typeof(baseHash))
                    throw "Ошибка при загрузке хэша видео."
                }
            },
            determineRelatedTag(srcType) {
                if (srcType === 'vid') {
                    return 'vid_id'
                } else if (srcType === 'vid_link') {
                    return 'src_link'
                } else if (srcType === 'img') {
                    return'src_id'
                } else {
                    throw "Неизвестный тип данных."
                }
            },
        // textContent and Image methods end

        // WallPost and postDraft methods
            /*executeSpecialRequest(requestName) {
                if (requestName == 'getContentForPostDraft') {
                    this.packTextContent();
                    this.$emit('savePostDraft', this.rqstContent);
                // } else if (requestName == 'uploadPostDraft') {
                //     this.$emit('loadPostDraft');
                } else if(requestName == '') {
                    // console.log(this.additionalInfo)
                } else {
                    throw "Ошибка при выполнении специального метода. Неизвестный метод!"
                }
            },*/
            loadPostDraft() {
                this.updateImgHash(null, null, this.additionalInfo.img_id_url_hash)
                this.updateVidHash('vid_link', null, null, this.additionalInfo.vid_link_url_hash);
                // this.updateVidHash('vid', null, null, this.additionalInfo.vid_src_url_hash);
                this.buildPostDraft(this.additionalInfo.wallPost.content);
            },
            buildPostDraft(content) {
                // debugger
                // TODO Check if video and images are loading properly
                // this.rqstContent = content
                this.wysContent = this.parseJSONIntoContent(content);
            },
            parseJSONIntoContent(theJSON) {
                let theArray = [],
                    textContent = ''
                Object.entries(theJSON).forEach(([key, value]) => {
                    if (value.type == 'text') {
                        textContent += value.content
                    }  else if (value.type == 'post_image') {
                        textContent +=  `<img src_id=${value.id} img/>`
                    } else if (value.type == 'video_link') {
                        textContent +=  `<vid_link src_link=${value.hard_link} vid_link/>`
                    }/* else if (value.type == 'post_video') {
                        textContent +=  `<vid_src vid_id=${value.id} vid_src/>`
                    }*/
                });

                return textContent;
            },
        // WallPost and postDraft methods end
    
        // Video methods
            addFullVideoUrlByLink() {
                let videoProvider = Helper.determineProvider(this.videoLink)
                this.inputMaterialTag('editorField', 'vid_link', this.videoID );
                this.updateVidHash('vid_link', this.videoID, this.revVideoLink);
                this.clearVideoPreview();
            },
            clearVideoPreview() {
                this.videoLink = ""
                this.revVideoLink = ""
                
                document.getElementById('preview_form').classList.add("d-none");
                document.getElementById('video_link_field').classList.add("d-none");
            },
            addVideo(type) {
                if (type === 'link') {
                    let classList = document.getElementById('video_link_field').classList;
                    
                    classList.contains("d-none") ? classList.remove("d-none") : classList.add("d-none");
                }
                // Add uploaded to VideoList video(2)
            },
        // Video methods end
        // Dropzone
            dropzoneImgUploader() {
                const self = this
                new Dropzone("#entityDropzone", {
                    // Create new Dropzone.
                    paramName: "posts[image_files]",
                    maxFilesize: 10,
                    // url: `/add_post_image/?wall_post_id=${this.additionalInfo.postId}`,
                    url: this.imgUploadPath,
                    parallelUploads: 1,
                    dictDefaultMessage: "Прикрепить изображения",
                    init: function() {
                        const theDropzone = this
                    },
                    success: function(file) {
                        // Get the id and url of created image.
                        let theID = JSON.parse(file.xhr.response).id,
                            theURL= JSON.parse(file.xhr.response).imgURL;

                        self.updateImgHash(theID, theURL);
                        
                        // Remove img from Dropzone, NOT DB!
                        this.removeFile(file);

                        // Wrap img_id into img tags.
                        self.inputMaterialTag('editorField', 'img', theID);
                    },
                    headers: {
                        // Need this header to send CSRF-token.
                        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                    }
                });
            },
        // Dropzone end
    }
}
</script>

<style>
    .dropzone {
        padding: 0;
    }
    .dropzone .dz-message {
        margin-top: 0;
        margin-bottom: 0;
    }

    .dz-message {
        height: 100%;
    }
    .dz-button {
        margin-bottom: auto;
        margin-top: auto;
    }
    #entityDropzone {
        min-height:unset;
    }
    #editorField {
        height: 30vh;
    }
    #emojiContainer {
        position: absolute; 
        right: 1.5em; 
        bottom: 1em;
    }
</style>

<!-- *Add uploaded to VideoList video(1) -->
<!-- <div class="col-3">
    <button class="btn bluBtn1" @click="addVideo('video')" :data-remote="true">Добавить загруженное видео</button>

    <div class="row d-none" id="videos_from_lists" style="max-height: 300px; overflow-block: auto;">
        <div class="col-12">
            <div class="row justify-content-center mt-3">
                <div v-if="!videoCollection.length" class="col-12 text-center text-muted"><em>Вы ещё не добавляли видео</em></div>
                <div v-else>
                    <div v-for="(item, index) in videoCollection">
                        <div class="btn grnBtn1 col-12" @click="addVidToEntity(item)">
                            <img :src="item.video_link" 
                                contain
                                width="150px"
                                >
                            <div class="row justify-content-center">
                                {{item.title}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- *Add uploaded to VideoList video(2) -->
<!--  // else if (type === 'video') {
            //     let classList = document.getElementById('videos_from_lists').classList;
            //     if (classList.contains("d-none")) {
            //         classList.remove("d-none");
            //         this.$emit('getVideosFromLists');
            //     } else {
            //         classList.add("d-none");
            //     }
            //     // TODO implement adding existed video into the post
            // } -->

<!-- *Add uploaded to VideoList video(3) -->
<!-- // addVidToEntity(video) {
        //     document.getElementById('videos_from_lists').classList.add("d-none");
        //     this.inputMaterialTag('editorField', 'vid_src', video.id);
        //     this.updateVidHash('vid', video.id, video.parsed_url);
        // }, -->