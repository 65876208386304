<template>
    <div>
      <pnvCheckbox
        @setChkbxValue="moveSlider($event);"
        :title="title" 
        :checkedState="currentChkbxState" 
        :title_class="title_class"
        :label_class="label_class"
        :lmntId="fieldid"
      ></pnvCheckbox>
      <input type="hidden" v-model="currentChkbxState" :name="formName" :id="fieldid">
    </div>
</template>

<script>
import pnvCheckbox from "../low/inputs/pnvCheckbox.vue";

export default {
    name: 'pnvslider',
    components: {
        pnvCheckbox
    },
    props: {
        fieldid: { type: String, required: true },
        objname: { type: String },
        title: { type: String, required: true },
        title_class: { type: String },
        label_class: { type: String },
        checked_state: { type: Boolean, required: true }
    },
    data () {
        return {
            currentChkbxState: false
            // currentChkbxState: { type: Boolean, default: false }
        }
    },
    created() {
        this.currentChkbxState = this.checked_state
    },
    computed: {
        formName: function() {
            if (this.objname != '') {
                return '[' + this.objname + ']' + this.fieldid
            } else {
                return this.fieldid
            }
        }
    },
    methods: {
        moveSlider(val) {
          // Show checkbox state
          this.currentChkbxState = val;
          console.log(this.currentChkbxState)
        }
    }
}
</script>
