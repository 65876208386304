<template>
<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <label :for="fieldID">{{label}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <textarea class="w-100" v-model="theValue" :placeholder="placeholder" :title="title"></textarea>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "pnvText",
    data() {
        return {
            theValue: ''
            // theConfig: {
            //     isBottomSectionToggled: false,
            //     optionsHeight: 0,
            //     optionHeight: 35,
            //     backgroundColor: "#336633",
            //     backgroundExpandedColor: "darkolivegreen",
            //     hoverBackgroundColor: "#336633",
            //     borderRadius: "1.5em",
            //     textColor: "antiquewhite",
            //     boxShadow: "1px 1px 4px #330033",
            //     border: "2px solid #ccc",
            //     showArrow: true
            // }
        }
    },
    props: {
        choice: { type: String },
        placeholder: { type: String, default: '' },
        title: { type: String, default: '' },
        label: { type: String, default: '' },
        fieldID: { type: String }
        
        // config: { type: Object, default: function() { return {} } },

        // content_size: { type: Number, default: null }
    },
    mounted() {
        this.theValue = this.choice
    },
    updated () {
        this.$emit('setTextValue', this.theValue);
        // console.log(this.theValue)
        // this.theValue = this.choice
    },
    watch: {
        choice: function (val) {
            // console.log("choice: " + val)
            this.theValue = this.choice
        },
    },
    computed: {
        // computedStyles: function() {
        //     if (this.content_size != null) {
        //         let width  = 79;
        //         if (this.theConfig.showArrow) {
        //             width += 40
        //         }
        //         width += 3 * this.content_size.toString().length
        //         this.width = width
        //     }
        //     return [
        //         { "--options-height": this.theConfig.optionsHeight + "px" },
        //         { "--options-height-neg": "-" + this.theConfig.optionsHeight + "px" },
        //         { "--main-el-border-radius": this.theConfig.borderRadius },
        //         { "--dropdown-width": this.theConfig.width + "px" },
        //         { "--dropdown-background-color": this.theConfig.backgroundColor },
        //         { "--dropdown-expanded-color": this.theConfig.backgroundExpandedColor },
        //         { "--dropdown-border": this.theConfig.border },
        //         { "--dropdown-box-shadow": this.theConfig.boxShadow },
        //         { "--dropdown-hover-background-color": this.theConfig.hoverBackgroundColor },
        //         { "--dropdown-default-text-color": this.theConfig.textColor }
        //     ];
        // }
    },
    methods: {
        /*setConfigData() {
            // this.width = this.config.width;
            // TODO Transform below list to array of css properties iteration
            if (!!this.config.backgroundColor) {
                this.theConfig.backgroundColor = this.config.backgroundColor;
            }
            if (!!this.config.backgroundExpandedColor) {
                this.theConfig.backgroundExpandedColor = this.config.backgroundExpandedColor;
            }
            if (!!this.config.border) {
                this.theConfig.border = this.config.border;
            }
            if (!!this.config.hoverBackgroundColor) {
                this.theConfig.hoverBackgroundColor = this.config.hoverBackgroundColor;
            }
            if (!!this.config.textColor) {
                this.theConfig.textColor = this.config.textColor;
            }
            if (!!this.config.borderRadius) {
                this.theConfig.borderRadius = this.config.borderRadius;
            }
            if (!!this.config.optionHeight) {
                this.theConfig.optionHeight = this.config.optionHeight;
            }
            if (!!this.config.boxShadow) {
                this.theConfig.boxShadow = this.config.boxShadow;
            }
            if (!!this.config.boxShadow) {
                this.theConfig.showArrow = this.config.showArrow;
            }
        },*/
        /*setBlockHeight() {
          this.optionsHeight = this.optionHeight * Object.keys(this.theList).length;
        }*/
    }
};
</script>