<template>
<div class="col-sm-12">
    <label for="user_gender">{{title}}</label>
    <fieldset :class="parent_class">
        <div :class="child_class">
            <input @click="chkbxChanged(1)" :id="fieldHtmlId(0)" type="radio" :value="0 ? true : false" name="user[gender]" /> <label :for="fieldHtmlId(0)">{{options[0]}}</label> 
            <input @click="chkbxChanged(0)" :id="fieldHtmlId(1)" type="radio" :value="1 ? true : false" name="user[gender]" /> <label :for="fieldHtmlId(1)">{{options[1]}}</label>
        </div>
    </fieldset>
</div>
</template>

<script>
export default {
	name: 'pnvRadio',
	props: {
        title: { type: String, required: true },
        prefix: { type: String, default: 'radio_val' },
        fieldID: { type: String, default: '' },
        parent_class: { 
            type: String, 
            default: "radioBtn1_1" 
        },
        child_class: { 
            type: String, 
            default: "toggle radioBtn1_2" 
        },
        options: { type: Array, default: 
                function() { return ['Ложь', 'Истина']}
        },
        checkedState: { type: Boolean, default: true },
	},
    data () {
        return {
            isChecked: false,
        }
    },
    mounted () {
        // console.log('mounted')
        // console.log(this.checkedState)
        this.setRadioVal(Boolean(this.checkedState));
    },
    methods: {
        chkbxChanged (val) {
            // console.log(this.currentChkbxState)
            this.setRadioVal(val)
        },
        setRadioVal (val) {
            this.isChecked = Boolean(val)
            this.showVisibleValChng()
            // isChecked is passed contraversal because
            // it uses state before event
            this.$emit('setRadioValue', !this.isChecked);
        },
        showVisibleValChng () {
            document.getElementById(this.fieldHtmlId(0)).checked = this.isChecked
            document.getElementById(this.fieldHtmlId(1)).checked = !this.isChecked
        },
        fieldHtmlId (val) {
            return this.prefix + '_' + val.toString()
        },
        fieldHtmlName (val) {
            if (this.fieldID.length > 0) {
                return this.prefix + '[' + this.fieldID + ']'
            }
            return this.prefix + '-' + val
        },
    }
}
</script>

<style>
/* RADIO BUTTON STYLES */
fieldset.radioBtn1_1 {
  margin: 0;
  box-sizing: border-box;
  display: block;
  min-width: 0;
}
fieldset.radioBtn1_1 legend {
  margin: 0 0 1.5rem;
  padding: 0;
  width: 100%;
  float: left;
  display: table;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 600;
  color: #333;
}
fieldset.radioBtn1_1 legend + * {
  clear: both;
}
/* TOGGLE STYLING */
.toggle.radioBtn1_2 {
  box-sizing: border-box;
  font-size: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
.toggle.radioBtn1_2 input {
  width: 0;
}
.toggle.radioBtn1_2 input + label {
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  border: solid 1px #DDD;
  background-color: cornsilk;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  transition: border-color 0.15s ease-out, color 0.25s ease-out, background-color 0.15s ease-out, box-shadow 0.15s ease-out;
  border-color: darkolivegreen;
  /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
  /*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
  /* ----- */
}
.toggle.radioBtn1_2 input + label:first-of-type {
  border-radius: 6px 0 0 6px;
  border-right: none;
}
.toggle.radioBtn1_2 input + label:last-of-type {
  border-radius: 0 6px 6px 0;
  border-left: none;
}
.toggle.radioBtn1_2 input:hover + label {
  border-color: darkgreen;
}
.toggle.radioBtn1_2 input:checked + label {
  background-color: #336633;
  color: burlywood;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
  border-color: grey;
  z-index: 1;
}
.toggle.radioBtn1_2 input:focus + label {
  outline: dotted 1px #CCC;
  outline-offset: 0.45rem;
}
</style>
