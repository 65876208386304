<template>
<div class="container">
    <div class="row">
        <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div v-show="(!!additionalInfo.src)" class="img-cropper">
                <vue-cropper
                    ref="cropper"
                    :src="additionalInfo.src"
                    alt="New avatar"
                    :viewMode="2"
                    :aspect-ratio="ratioList[currentRatio].value"
                    preview=".preview"
                    :autoCropArea="1"
                    :key="additionalInfo.src"
                    @ready="setNewCropperData()" />
            </div>
            <div style="height:15em;" v-show="!(!!additionalInfo.src)" class="row justify-content-center">
                <div class="spinner-border text-success my-auto" role="status">
                    <span class="visually-hidden">Загрузка...</span>
                </div>
            </div>
            
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
            <div class="actions row justify-content-center">
                <div class="col-12">
                    <a  href="#"
                        role="button"
                        @click.prevent="rotateImg(90)">+90&#176;</a>
                    <a  href="#"
                        role="button"
                        @click.prevent="rotateImg(-90)">-90&#176;</a>
                    <a  href="#"
                        role="button"
                        @click.prevent="reset">Сброс</a>
                    <div>
                    <pnvMenu v-if="!avaEdit"
                        :config="selectMenuDesign"
                        :options="ratioList"
                        :choice="currentRatio"
                        placeholder="Область"
                        @setSelectedOption="setAspectRatio($event);"
                    ></pnvMenu>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12" v-if="!avaEdit">
                    <div class="preview-area">
                        <div class="preview" />
                        <p>Предпросмотр</p>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-6 col-sm-6 col-xs-6 my-2" v-if="avaEdit">
                    <div class="preview-area">
                        <div class="preview rounded mx-auto" />
                        <p class="text-center">Результат</p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-6 my-2" v-if="avaEdit && ratioIsSquare">
                    <div class="row h-100">
                        <div class="preview-area mt-auto">
                            <div class="preview rounded-circle preview-icon mx-auto" />
                            <p class="text-center">Иконка</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form class="row my-2">
        <input type="submit" name="commit" :value="additionalInfo.submitBtnTitle" :data-disable-with="additionalInfo.submitBtnTitle" class="btn btn-lg w-100 grnBtn1" @click.prevent="setAndSubmitData()">
    </form>
</div>
</template>

<script>
import pnvMenu from "../low/inputs/pnvMenu.vue";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
    name: 'pnvImgCropper',
    components: {
        VueCropper,
        pnvMenu
    },
    props: {
        additionalInfo: { type: Object },
    },
    data () { return {
        cropImg: '', // changed image, very large string
        // data: {}, // used for cropper operations
        x: 0,
        y: 0,
        height: 0,
        width: 0,
        rotate: 0,
        hasCropperChanges: false,
        currentRatio: 'square',
        selectMenuDesign: {
            backgroundColor: "#0062CC",
            backgroundExpandedColor: "#0062CC",
            textColor: "antiquewhite",
            borderRadius: "1.5em",
            border: "2px solid #ccc",
        },
        errors: [],
        avaEdit: false, // needs refactoring
        // is used for disabling ratio cahnge menu
        // change to strictAsr
    } },
    computed: {
        ratioIsSquare() {
            return this.currentRatio === "square"
        },
        /*isShowcase() {
            return this.additionalInfo.imgType === "showcase"
        },*/
        imgFormat() {
            return this.additionalInfo.src.split(".")[1]
        },
        newImgLoading() {
            /* const theObj = this.additionalInfo.id === 0
            console.log( "newImgLoading: " + ( typeof theObj === 'object' ? JSON.stringify(theObj) : theObj ) )*/
            return this.additionalInfo.id === 0
        },
        /*imgIdChanged() {
            return this.additionalInfo.id != this.additionalInfo.currentImgId
        },*/
        ratioList() { 
            return {
            'square':     { id: 'square', badge_name: '1/1', value: 1/1 },
            'widescreen': { id: 'widescreen', badge_name: '16/9', value: 16/9 },
            '4/1_banner': { id: '4/1_banner', badge_name: '4/1', value: 4/1 },
            'photo':      { id: 'photo', badge_name: '2/3', value: 2/3 },
            'showcase':   { id: 'showcase', badge_name: '3/2', value: 3/2 },
            'free':       { id: 'free', badge_name: 'free', value: NaN },
            }
        },
    },
    mounted() {
        this.setAspectRatio(this.additionalInfo.asr);
        this.avaEdit = this.additionalInfo.avaEdit;
    },
    methods: {
        setAspectRatio(selectedOption) { 
            this.currentRatio = selectedOption; // Show selected option in the dropdown 
            this.$refs.cropper.setAspectRatio(this.ratioList[this.currentRatio].value);
        },
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            if (this.imgFormat === "png" ) {
                this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
            } else {
                this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 1)
            }
        },
        reset() {
            this.$refs.cropper.reset();
        },
        rotateImg(deg) {
            this.$refs.cropper.rotate(deg);
        },
        /*flipX() {
            const dom = this.$refs.flipX;
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute('data-scale', scale);
        },
        flipY() {
            const dom = this.$refs.flipY;
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleY(scale);
            dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
            this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
            this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
            // console.log(this.$refs.cropper.getData())
            // console.log(this.data)
        },
        move(offsetX, offsetY) {
            this.$refs.cropper.move(offsetX, offsetY);
        },
        setCropBoxData() {
            if (!this.data) return;
            this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
            if (!this.data) return;
            this.$refs.cropper.setData(JSON.parse(this.data));
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        },*/
        setAndSubmitData () {
            this.setNewCropperData(true)
            this.cropImage()
            this.submitImgData()
        },
        setNewCropperData(checkCurrentVals = false) {
            const newParams = this.$refs.cropper.getData(),
                  cropProps = [ "x", "y", "width", "height", "rotate"];
            for (let i = cropProps.length - 1; i >= 0; i--) {
                if (this.checkCropChanges(cropProps[i], newParams[cropProps[i]])) {
                    if (checkCurrentVals) { this.hasCropperChanges = true }
                    this.setNewCropVals(cropProps[i], newParams[cropProps[i]])
                }
            }
        },
        checkCropChanges(param, val) {
            return this[param] !== val;
        },
        setNewCropVals(param, val) {
            this[param] = val;
        },
        hasChanges () {
            // console.log("hasChanges")
            // console.dir( ( this.hasCropperChanges || this.newImgLoading ) )
            return ( this.hasCropperChanges || this.newImgLoading )
        },
        submitImgData () {
                const imgData = this.hasChanges() ? this.cropImg : ""
                this.$emit("setImage", imgData)
                // console.log("imgData")
                // console.dir(imgData)
        },
    }
}
</script>

<style scoped>

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 5px 0;
}

.header h2 {
    margin: 0;
}

.header a {
    text-decoration: none;
    color: black;
}

.content {
    display: flex;
    justify-content: space-between;
}

.cropper-area {
    width: 60%;
}

.actions {
    margin-top: 1rem;
}

.actions a {
    display: inline-block;
    padding: 5px 15px;
    background: #336633;
    color: white;
    text-decoration: none;
    border-radius: 3px;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

textarea {
    width: 100%;
    height: 100px;
}

.preview-area p {
    font-size: 1.25rem;
    margin: 0;
    margin-bottom: 1rem;
}

.preview-area p:last-of-type {
    margin-top: 1rem;
}

.preview {
    width: 100%;
    height: calc(372px * (9 / 16));
    overflow: hidden;
}
.preview-icon {
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.crop-placeholder {
    width: 100%;
    height: 200px;
    background: #ccc;
}

.cropped-image img {
    max-width: 100%;
}

.preview-area p:last-of-type {
    margin-top: 0;
}

.preview-area p {
    margin-bottom: 0;
}
</style>
