import * as visibility from 'reusable/pnv_visibility';
// validate value
    export function validateName(name) {
        // name should be string
        const nameFormat = /^[a-z.\u0400-\u04FF]+$/i;
        return nameFormat.test(name) && name.length >= 1 && name.length <= 20;
    };
    export function validateSurname(surname) {
        // surname should be string
        const surnameFormat = /^[a-z.\u0400-\u04FF]+$/i;
        return surnameFormat.test(surname) && surname.length >= 1 && surname.length <= 20;
    };
    export function validateBirth(birth) {
        let birthArray = birth.split('.') 
        let date = (new Date(birthArray[2], birthArray[0], birthArray[1]))
        return date instanceof Date && !isNaN(date) && date < new Date; 
    };

    export function validateMobile(login) {
        // login should be string
        const mobileFormat = /^\+[1-9]{1}[0-9]{10,14}$/;
        return mobileFormat.test(login);
    };

    export function validateEmail(login) {
        // login should be string
        const mailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return mailFormat.test(login);
    };

    export function validatePassword(passwd) {
        return passwd.length > 5;
    };
// end validate value

// show validation result
    export function controlField(theField, submitAllowedField) {
        // the func requires target field to have
        // submit-lmnt-id data to be set up in views
        // and submitAllowed obj to be declared
        const allowed = Object.values(submitAllowed).every(e => e !== false)
        visibility.controlVisibility(theField, submitAllowedField)
        visibility.controlInput(
            theField.data('submitLmntId'), 
            allowed
        );
        
        return allowed;
    }
// end show validation result
