<template>
<div class="row"><!-- row formFactory starts -->
    <div class="col-12">
        <!-- form fields start -->
        <field v-for="field in currentFields" 
            :payload="field.payload" 
            :cName="field.cName"
            :fieldID="field.fieldID"
            :objName="cfg.html.obj_name"
            :key="randomStringId + field.fieldID"
            :upd="upd"
            :acItems="acItems[field.fieldID]"
            @chngVal="chngFieldVal($event[0], $event[1])"
            @autocomplete="autocomplete($event[0], $event[1])"
            ></field>
        <!-- form fields end -->
        <a v-if="cfg.inner"
            :id="cfg.html.id" 
            :class="submitBtnHtmlClass" 
            @click.prevent="sendDataToParent()">{{ cfg.html.title }}</a>
    </div>
</div><!-- row formFactory ends -->
</template>

<script>
import field from '../mid/pnvInputField.vue'

export default {
	name: 'pnv_form_factory',
    components: {
        field,
    },
	props: {
        config: { type: Object },
        initialFields: { type: Array },
	},
    data () {
        return {
            cfg: {
                html: { /* obj_name, title, class, id */  }
            },
            currentFieldsVals: {},
            currentFields: [],
            dependencyFields: {},
            acItems: {},
            upd: 0,
        }
    },
    created () {
        this.cfg = this.config
        this.currentFields = this.initialFields
        if ( this.hasDependencyFields) { this.setDependencyFields() }
    },
    computed: {
        submitBtnHtmlClass () {
            return !!this.cfg.html.klass ? this.cfg.html.klass : "my-2 grnBtn1 btn-lg btn w-100"
        },
        randomStringId () {
            // in case of multiple forms guarantees their field keys to be unique
            return Math.floor(Math.random() * Date.now()).toString(36);
        },
        fieldsVcblr () {
            // helps find field by its id in the field array
            const vcblr = {},
                  fields = this.currentFields
            for ( let i = fields.length - 1; i >= 0; i-- ) {
                vcblr[fields[i].fieldID] = i
            }
            return vcblr
        },
        hasDependencyFields () {
            // console.log( "dependencyFields: " + ( typeof this.cfg === 'object' ? JSON.stringify(this.cfg) : this.cfg ) )
            return !!(this.cfg.dependencyFields) ? Object.keys(this.cfg.dependencyFields).length > 0 : false
        },
    },
    methods: {
        setDependencyFields () {
            for (const [theFieldID, parents] of Object.entries(this.cfg.dependencyFields)) { // list all fields which have deps
                // console.log(`field - ${theFieldID}: ${JSON.stringify(parents)}`);
                for (let i = parents.length - 1; i >= 0; i--) { // add parent fields IDs to the data and set them to false, i.e. not set yet
                    // console.log(`parent - ${JSON.stringify(parents[i])}`);
                    if ( !(!!this.dependencyFields[ theFieldID ]) ) {
                        this.dependencyFields[ theFieldID ] = {}
                    }
                    this.dependencyFields[ theFieldID ][ parents[i] ] = false
                }
                this.checkIfCanShowFieldWith(theFieldID)
            }
        },
        checkIfCanShowFieldWith (theFieldID) {
            try {
                this.markFieldAsHidden(theFieldID)
            } catch (err) {
                this.showAlertAndInfoInConsole(err)
            }
            this.upd++
        },
        markFieldAsHidden(theFieldID) {
            // if any of parent fields do not permit revealing the field it stays hidden
            this.payload(theFieldID).hidden = Object.
                values(this.dependencyFields[ theFieldID ]).includes(false)
        },
        chngFieldVal (theFieldID, theChoice) {
            // console.log("chngFieldVal: " + theFieldID + " is now " + theChoice)
            const payload = this.payload(theFieldID),
                  realVal = !!payload.autocomplete ? theChoice[0] : theChoice,
                  indexVal = !!payload.autocomplete ? theChoice[1] : theChoice

            if (this.currentFields[this.fieldsVcblr[theFieldID]].cName === 'pnvFile') {
                this.currentFieldsVals[theFieldID] = theChoice
            }
            // console.log( "chngFieldVal - theChoice: " + ( typeof theChoice === 'object' ? JSON.stringify(theChoice) : theChoice ) )

            if ( this.currentFieldsVals[theFieldID] !== realVal ) { // if val changed
                // console.log("chngFieldVal: " + theFieldID + " is now " + theChoice)
                if ( !!payload.autocomplete ) { 
                    // TODO Check if it has sence:
                    this.acItems[theFieldID] = {}
                    payload.inner_choice = indexVal
                }

                this.fieldValUpdProc(theFieldID, indexVal)
                payload.choice = realVal

                this.upd++ // notify child components
                this.$emit("form-field-chng", this.currentFieldsVals) // notify parent component about param val chng
            }
        },
        fieldValUpdProc (theFieldID, theChoice) {
            if (typeof theChoice === 'object') {
                this.multipartValProc(theFieldID, theChoice)
            } else { // monoval proc
                if ( this.denyUnacceptableVals(theChoice, theFieldID) ) {
                    // console.log( `${theFieldID}: ` + ( typeof theChoice === 'object' ? JSON.stringify(theChoice) : theChoice ) )
                    delete this.currentFieldsVals[theFieldID]
                } else {
                    this.currentFieldsVals[theFieldID] = theChoice
                }
                if ( this.hasDependencyFields ) {
                    this.checkDependencies(theFieldID, theChoice)
                }
            }
        },
        multipartValProc (theFieldID, theChoice) { // when the value has separate parts
            // console.log( "multipartValProc-theFieldID: " + ( typeof theFieldID === 'object' ? JSON.stringify(theFieldID) : theFieldID ) )
            // console.log( "multipartValProc-theChoice: " + ( typeof theChoice === 'object' ? JSON.stringify(theChoice) : theChoice ) )
            for (const [key, value] of Object.entries(theChoice)) {
                if ( this.denyUnacceptableVals(value, theFieldID) ) {
                    if (!!this.currentFieldsVals[theFieldID]) {
                        delete this.currentFieldsVals[theFieldID][key]
                    }
                } else {
                    this.currentFieldsVals[theFieldID] = value
                }
            }
        },
        denyUnacceptableVals(theVal, theFieldID) {
            const app = this,
                  payload = this.payload(theFieldID),
                  // object means array (tricky js)
                  unacceptableVals = typeof payload.unacceptable === 'object' ? payload.unacceptable : []
            for (let i = unacceptableVals.length - 1; i >= 0; i--) {
                let item = unacceptableVals[i]
                // console.log( "item: " + ( typeof item === 'object' ? JSON.stringify(item) : item ) )
                if (Helper.checkVal(item, theVal)) {
                    return true
                }
            }
            return false
        },
        checkDependencies (theFieldID, theChoice) {
            // console.log( "theChoice: " + ( typeof theChoice === 'object' ? JSON.stringify(theChoice) : theChoice ) )
            for (const [aFieldID, parents] of Object.entries(this.dependencyFields)) { // jump through each dep field
                const parentIDs = Object.keys(parents)
                /*console.log(`checkDependencies - ${JSON.stringify(parentIDs)}`);
                console.log(`theFieldID - ${JSON.stringify(theFieldID)}`);
                console.log(`parentIDs.includes(theFieldID) - ${JSON.stringify(parentIDs.includes(theFieldID))}`);*/
                if ( parentIDs.includes(theFieldID) ) { // check if their parents include current field 
                    // TODO Make condition much precise
                    // const theObj = this.dependencyFields[ aFieldID ][ parents[ theFieldID ] ]
                    /*const theObj = this.dependencyFields[ aFieldID ][ theFieldID ]
                    console.log( "dependencyFields: " + ( typeof theObj === 'object' ? JSON.stringify(theObj) : theObj ) )*/
                    this.dependencyFields[ aFieldID ][ theFieldID ] = !!theChoice
                    this.checkIfCanShowFieldWith(aFieldID) // recalc unmet conditions
                }
            }
        },
        sendDataToParent() {
            // console.log("this.currentFieldsVals")
            let rqstParams = {}
            for (const [key, value] of Object.entries(this.currentFieldsVals)) {
                if ( !!value ) {
                    rqstParams[key] = value
                }
            }
            this.$emit('submit-data', rqstParams)
        },
        payload (fieldID) {
            return this.currentFields[this.fieldsVcblr[fieldID]].payload
        },
        // autocomplete methods group start
            autocomplete (fieldId, val) {
                const acData = this.payload(fieldId).autocomplete // autocompleteData
                if (!!acData) {
                    const params = this.collectParams(acData.parentFieldIDs, acData.theParam, val)

                    if (this.canMakeAutocompleteRqst(params, acData)) {
                        this.rqstToPopulateFieldOptions(acData.path, params, fieldId)
                    }
                }
            },
            collectParams (parentFieldIDs, mainParam, val) {
                const paramsSkeleton = {}
                    
                paramsSkeleton[mainParam] = val // main param which affects the search
                    // ex. for regions this is title

                for (let i = parentFieldIDs.length - 1; i >= 0; i--) { // From Parent Fields
                    const fieldID = parentFieldIDs[i]
                    paramsSkeleton[fieldID] = this.currentFieldsVals[fieldID]
                }
                // console.log( "autocomplete: " + ( typeof autocomplete === 'object' ? JSON.stringify(autocomplete) : autocomplete ) )
                return paramsSkeleton
            },
            canMakeAutocompleteRqst (params, autocomplete) {
                const hasNoUndefined = !Object.values(params).includes(undefined),
                      hasNoEmpty = !Object.values(params).includes("-1"),
                      hasEnoughLength = params[autocomplete.theParam]?.length > autocomplete.minSize
                return ( hasNoUndefined && hasNoEmpty && hasEnoughLength )
            },
            rqstToPopulateFieldOptions (path, params, fieldId) {
                Helper.makeRqst(path, params).
                    then (response => {
                        /*// console.log( "response: " + ( typeof response === 'object' ? JSON.stringify(response) : response ) )
                        if (response.status === 200) {
                            // console.log( "response.data: " + ( typeof response.data === 'object' ? JSON.stringify(response.data) : response.data ) )
                        } else {
                            this.acItems = response
                        }*/
                        this.acItems[fieldId] = response
                        this.upd++
                    }).
                    catch ( e => {
                        console.log( "error in axios: : " + ( typeof e === 'object' ? JSON.stringify(e) : e ) )
                    });
            },
        // autocomplete end
        showAlertAndInfoInConsole (err) {
            if (err.message === "Cannot read properties of undefined (reading 'payload')") {
                console.log( "err message: " + err.message )
                console.log( "dependencyFields: a field id is wrong!!!" )
                console.log( "Check fields ids in the form declaration, possible problem is in: " + theFieldID )
                alert("dependencyFields fail! Check the console!")
            } else {
                throw err
            }
        },
    }
}
</script>

<style>
    .acWindow {
        position: absolute;
        background: burlywood;
        width: inherit;
    }
</style>
