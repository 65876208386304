<template>
<!-- Modal -->
<div 
@set-image="(theData) => imgSetter(theData)" 
@gallery-show="(imgData) => galleryLoad(imgData)" 
@show-cropper="(imgData) => cropperLoad(imgData)" 
@modal-show="(theData) => stubLoad(theData)" 
@enter-password="(theData) => passwdConfirmLoad(theData)"
@wallPost-form="(theData) => wallPostForm(theData.detail)" 
@post-load="(theData) => postLoad(theData.detail)" 
@show-form="(theData) => showForm(theData.detail)"
@complaintMenu-show="(complData) => complaintMenu(complData)"
v-show="isActive" class="modal fade show" :id="modalid" tabindex="-1" role="dialog" :aria-labelledby="label" style="display: block; padding-right: 12px;" aria-modal="true">
    <div class="modal-fade" @mouseup="this.closeOutsideModal">
        <div :class="modalSize">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="label"></h5>
                    <button @click="modalHide()" type="button" class="close btn redBtn1 ms-auto" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div id="modalScrollDiv" class="modal-main">
                    <div class="modal-body">
                        <div :id="preContentId" class="">
                        </div> 
                        <component @form-submitted="modalHide()" @form-field-chng="formFieldsChng($event)" :additionalInfo="additionalInfo" v-bind:is="currentComponent"></component>
                        <div :id="contentId" class="d-none">
                        </div>
                    </div>
                    <div :id="footerId" class="modal-footer">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import pnvImgCropper from '../low/pnvImgCropper';
import pnvGallery from '../mid/pnvGallery';
import pnvShowPost from '../mid/pnvShowPost';
import pnvStub from '../low/pnvStub';
import pnvPasswdConfirmation from '../low/pnvPasswdConfirmation';
import pnvImgSetter from '../mid/pnvImgSetter';
import pnvComplaintForm from '../low/pnvComplaintForm'; 
import pnvPostForm from '../mid/pnvPostForm' 
import pnv_form_factory from '../mid/pnvFormFactory'

export default {
    name: 'pnvmodal',
    components: { 
        pnvStub,
        pnvImgCropper, 
        pnvGallery,
        pnvPasswdConfirmation,
        pnvImgSetter,
        pnvShowPost,
        pnvPostForm,
        pnvComplaintForm,
        pnv_form_factory,
    },
    props: { 
        visible: { type: Boolean },
        modalid: { type: String, default: 'pnvModal' },
    },
    computed: {
        modalSize () {
            // can be xl or lg or null
            return (!!(this.sizeLg)) ? `modal-dialog modal-${this.sizeLg}` : 'modal-dialog'
        },
        label () {
            return `${this.modalid}Label`
        },
        footerId () {
            return `${this.modalid}Footer`
        },
        contentId () {
            return `${this.modalid}Content`
        },
        preContentId () {
            return `${this.modalid}PreContent`
        },   
    },
    data () {
        return {
            isActive: true,
            sizeLg: 'md',
            currentComponent: "pnvStub",
            additionalInfo: { modalId: this.modalid },
            parentUrl: document.URL.split('?')[0]
        }
    },
    mounted () {
        this.isActive = this.visible;
        // TODO Check if this code is usefull.
        this.$root.$on('postLoad', (postData) => {
            // This structure used to show post from wall.vue.
            this.postLoad(postData);
        });
        // TODO check if event handler (@wallPost-form) in html is still used
        this.$root.$on('wallPost-form', (postData) => {
            // This structure used to show post from wall.vue.
            this.wallPostForm(postData);
        });
        // TODO Check if this code is usefull end.
    },
    methods: {
        modalHide () {
            // hide modal
            this.isActive = false
            // on exit - restore default component
            this.currentComponent = "pnvStub"
            this.additionalInfo = {}
            // remove obsolete data from contentId
            document.getElementById(this.contentId).innerHTML = '';
            window.history.replaceState("", "title", this.parentUrl);
        },
        stubLoad(theData) {
            if (theData.detail.show) {
                this.currentComponent = "pnvStub"
                this.sizeLg = theData.detail.size
                this.isActive = true
            } else {
                this.modalHide();
            }
        },
        cropperLoad (imgData) {
            this.additionalInfo.id = imgData.detail.id
            this.additionalInfo.token = imgData.detail.token
            this.additionalInfo.asr = imgData.detail.asr
            this.additionalInfo.avaEdit = imgData.detail.avaEdit
            this.additionalInfo.src = imgData.detail.src
            this.additionalInfo.width = parseInt(imgData.detail.width, 0)
            this.additionalInfo.height = parseInt(imgData.detail.height, 0)
            this.additionalInfo.modelID = imgData.detail.modelID
            this.additionalInfo.modelClass = imgData.detail.modelClass
            this.defaultRendering("pnvImgCropper")
        },
        passwdConfirmLoad (theData) {
            this.currentComponent = "pnvPasswdConfirmation"
            this.additionalInfo.formId = theData.detail.formId
            this.isActive = true
        },
        imgSetter (theData) {
            const thePath = theData.detail.thePath
            const   details = theData.detail //,
                    // albumId = details.album.id
            // setting initial album info
            this.additionalInfo = details
            this.additionalInfo.avaEdit = true
            this.additionalInfo.label = false
            this.additionalInfo.shape = "circle"
            this.additionalInfo.thePath = thePath
            
            // this.additionalInfo.
            //     albums[albumId]["content"] = details.albumContent
            this.defaultRendering("pnvImgSetter")
        },
        galleryLoad (imgData) {
            if (imgData.detail.img) {
                this.additionalInfo.src_type = 'image'
                this.additionalInfo.total_amount = imgData.detail.total_amount
                this.additionalInfo.img_ids = imgData.detail.img_ids
                this.additionalInfo.pos = imgData.detail.pos
                this.additionalInfo.img = imgData.detail.img
                this.additionalInfo.token = imgData.detail.token
                this.additionalInfo.comments = imgData.detail.comments
                this.additionalInfo.assets = imgData.detail.assets
                this.additionalInfo.nextPage = imgData.detail.nextPage
                this.defaultRendering("pnvGallery")
            } else if (imgData.detail.vid) {
                this.additionalInfo.src_type = 'video'
                this.additionalInfo.total_amount = imgData.detail.total_amount
                this.additionalInfo.vid_ids = imgData.detail.vid_ids
                this.additionalInfo.pos = imgData.detail.pos
                this.additionalInfo.vid = imgData.detail.vid
                this.defaultRendering("pnvGallery")
            }
        },
        formFieldsChng (currentFields) {
            // console.log( "event: triggered" )
            for (const [key, value] of Object.entries(currentFields)) {
              // console.log(`currentFields ${key}: ${value}`);
                for (const [k, v] of Object.entries(this.additionalInfo.fields)) {
                    // console.log(`additionalInfo ${k}: `);
                    // console.dir(v);
                    if (v.fieldID === key) {
                        v.payload.choice = value
                    }
                }
            }
        },
        showForm (formData) {
            // console.log( "formData: " + ( typeof formData === 'object' ? JSON.stringify(formData) : formData ) )
            this.additionalInfo.fields = formData.detail.fields
            this.additionalInfo.config = formData.detail.config
            this.additionalInfo.submit = formData.detail.submit
            this.defaultRendering("pnv_form_factory")
        },
        postLoad(postData) {
            // const theObj = postData
            // console.log( "postLoad: " + ( typeof theObj === 'object' ? JSON.stringify(theObj) : theObj ) )
            const [wallPost, token, current_profile_id, assets, privacyAbilities, showComments, comments, show_comments_btn, fromModal ] = [ postData.wallPost, postData.token, postData.current_profile_id, postData.assets, postData.privacyAbilities, postData.show_comments, postData.comments, false, true ];
            Object.assign(this.additionalInfo, {wallPost, token, current_profile_id, assets, privacyAbilities, showComments, comments, show_comments_btn, fromModal });
            // TODO Check if we can assign postData to additionalInfo completely
            this.defaultRendering("pnvShowPost")
            this.updateUrl(postData.wall_type, postData.wall_id, postData.wallPost.model.id)
        },
        wallPostForm(postData) {
            $( "#pnvModalLabel" ).text( "Написать пост:" );
            const [ wall_type, 
                    wall_id, 
                    token, 
                    current_profile_id, 
                    postId, 
                    wallPost, 
                    img_id_url_hash, 
                    vid_link_url_hash, 
                    vid_src_url_hash, 
                    wall_choices, 
                    privacy_choices, 
                    repost_id, 
                    isRepost, 
                    assets ] = 

                   [postData.wall_type, 
                    postData.wall_id, 
                    postData.token, 
                    postData.current_profile_id, 
                    postData.postId, 
                    postData.wallPost, 
                    postData.img_id_url_hash, 
                    postData.vid_link_url_hash, 
                    postData.vid_src_url_hash, 
                    postData.wall_choices, 
                    postData.privacy_choices, 
                    postData.repost_id, 
                    postData.isRepost, 
                    postData.assets ];
            Object.assign(this.additionalInfo, {wall_type, wall_id, token, current_profile_id, postId, wallPost, img_id_url_hash, vid_link_url_hash, vid_src_url_hash, wall_choices, privacy_choices, repost_id, isRepost, assets});
            

            this.defaultRendering("pnvPostForm");
        },
        complaintMenu(complaintObj) {
            this.additionalInfo = complaintObj.detail

            if (complaintObj.detail.complainable_id && 
                complaintObj.detail.complainable_type) {
                // TODO: 1) declare variables in new.js.erb, 2) declare them dynamically
                this.additionalInfo.path = '/profiles/' + this.additionalInfo.current_profile_id + '/complaints/send_complaint'
            } else {
                this.additionalInfo.path = '/profiles/' + this.additionalInfo.current_profile_id + '/complaints'
                this.sizeLg = "xl"
            }

            this.currentComponent = "pnvComplaintForm"
            this.isActive = true
        },
        closeOutsideModal(e) {
            if (!$(e.target).closest(".modal-dialog").length &&
                $(e.target).closest(".modal-fade").length) {
                    $( "#pnvModalLabel" ).text("");
                    this.modalHide();
            }
        },
        defaultRendering(component) {
            this.currentComponent = component
            this.sizeLg = "xl"
            this.isActive = true
        },
        updateUrl(wallType, wallID, wallpostID){
            let theUrl = Helper._url + `${Helper.pluralModel(wallType)}/${wallID}?wall_posts=${wallpostID}`

            window.history.replaceState("", "title", theUrl);
        }
    }
}
</script>

<style>
    .modal-main {
        /* cover the modal */
        /* hidden for user registration modal */
        position: absolute;
        top: 3.5rem;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 3px - 15rem);
        /* spacing as needed */
        padding: 20px 50px 20px 20px;
        /* let it scroll */
        overflow: auto;
    }
    .modal-content {
        height: calc(100vh - 10rem);
        z-index:  20;
    }
    .modal-header {
        height: 3.5rem;
    }
    .modal-fade:before {
        content: '';
        background: #000;
        position: fixed; 
        left: 0;
        top: 0;
        width: 100%; 
        height: 100%;
        opacity: 0.5;
        z-index: 10;
    }
</style>
