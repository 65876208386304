<template>
<div>
    <input @click="chkbxChanged()" style="display:none;" v-model="isChecked" type="checkbox" :class="title_class" :id="chkbxId">
    <label :class="label_class" :for="chkbxId"><span></span></label>
    <span class="ml-2">{{title}}</span>
</div>
</template>

<script>
export default {
	name: 'pnvCheckbox',
	props: {
        title: { type: String, required: true },
        title_class: { 
            type: String, 
            default: "cbx1_1" 
        },
        label_class: { 
            type: String, 
            default: "cbx_toggle1_1" 
        },
        lmntId: { type: String, required: true },
        checkedState: { type: Boolean, default: false },
	},
    computed: {
        chkbxId: function() {
            return "cbx-" + this.lmntId

        }
    },
    data () {
        return {
            isChecked: false,
        }
    },
    mounted () {
        // console.log('mounted')
        // console.log(this.checkedState)
        this.isChecked = this.checkedState
    },
    methods: {
        chkbxChanged () {
            // console.log(this.currentChkbxState)
            // this.isChecked = !this.isChecked
            // isChecked is passed contraversal because
            // it uses state before event
            this.$emit('setChkbxValue', !(this.isChecked));
            // this.$emit('setChkbxValue', this.isChecked);
        }
    }
}
</script>

<style scoped>
    /* CHECKBOX STYLES */
    /* activate when transformation to vue frontend is completed */
    /*.cbx_toggle1_1 {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 20px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      transform: translate3d(0, 0, 0);
    }
    .cbx_toggle1_1:before {
      content: "";
      position: relative;
      top: 7px;
      left: 3px;
      width: 34px;
      height: 14px;
      display: block;
      background: beige;
      border-radius: 8px;
      transition: background 0.2s ease;
    }
    .cbx_toggle1_1 span {
      position: absolute;
      top: 4px;
      left: 0;
      width: 20px;
      height: 20px;
      display: block;
      background: beige;
      border-radius: 10px;
      box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
      transition: all 0.2s ease;
    }
    .cbx_toggle1_1 span:before {
      content: "";
      position: absolute;
      display: block;
      margin: -18px;
      width: 56px;
      height: 56px;
      background: rgba(79, 46, 220, 0.5);
      border-radius: 50%;
      transform: scale(0);
      opacity: 1;
      pointer-events: none;
    }

    .cbx1_1:checked + .cbx_toggle1_1:before {
      background: darkolivegreen;
    }
    .cbx1_1:checked + .cbx_toggle1_1 span {
      background: darkgreen;
      transform: translateX(20px);
      transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
      box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
    }
    .cbx1_1:checked + .cbx_toggle1_1 span:before {
      transform: scale(1);
      opacity: 0;
      transition: all 0.4s ease;
    }*/
</style>
