<template>
<div class="row">
    <div class="col-12">
        <div v-show="prompt.length > 0" class="row">
            <div class="col-12">
                <span :class="promptHtmlClass">{{ prompt }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-11">
                <h5><span>Выбрано: </span><span>{{ selectedItemIdsLength }}</span> 
                    <span style="color:darkred;" v-if="selectedItemIdsLength === 0">Должен быть выбран по меньшей мере один предмет</span></h5>
            </div>
            <div class="col-1"><button @click.prevent="switchSelectMsgsAndMain()" class="btn btn-light b">x</button></div>
        </div>
        <div class="row">
            <div class="col-12">
                <pnv_form_factory v-if="active" :initialFields="formRawConfig.fields" :config="formRawConfig.config" @form-submitted="submitSelection($event)"></pnv_form_factory>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import pnv_form_factory from '../mid/pnvFormFactory.vue'

export default {
	name: 'pnvItemSelector',
    components: {
        pnv_form_factory
    },
	props: {
        formRawConfig: { type: Object, required: true},
        selectedItemIdsLength: { type: Number, default: 0},
        prompt: { type: String, default: '' },
        title: { type: String, default: 'Должен быть выбран по меньшей мере один предмет' },
        status: { type: Number, default: 0 },
        active: { type: Boolean, default: false },
	},
    computed: {
        promptHtmlClass() {
            switch(this.status) {
                case 0:  // new form
                    return ''
                    break;
                case 200:  // if submit successful
                    return 'green-prompt'
                    break;
                case 403:  // if forbidden
                    return 'red-prompt'
                    break;
                case 500:  // if forbidden
                    return 'orange-prompt'
                    break;
                default:
                    return 'orange-prompt'
                    break;
            }
        },
    },
    methods: {
        switchSelectMsgsAndMain () {
            this.$emit('close')
        },
        submitSelection (response) {
            // console.log("response submitSelection:", response)
            this.$emit('selection-submit', response)
        }
    }
}
</script>

<style scoped>
    .green-prompt {
        color:darkolivegreen;
        text-decoration-line: underline;
        font-style:italic;
    }
    .red-prompt{
        color:darkred;
        font-weight:bold;
    }
    .orange-prompt{
        color: darkorange;
    }
</style>
