<template>
<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <div class="d-none">
                    <input type="hidden" name="form_id" id="form_id">
                </div>
                <div class="field col-12 mx-auto text-center">
                    <label for="current_password">Текущий пароль</label>
                    <input v-model="passwd" class="ml-auto my-auto" type="password" name="current_password" id="current_password">
                </div>
                <div class="field col-12 mx-auto text-center">
                    <input v-on:click="submitPasswd" type="submit" name="commit" value="Подтвердить" class="btn redBtn1" data-disable-with="Подтвердить">
                </div>
            </div>
        </div>

        <div class="card mt-3">
            <div class="card-header" id="headingPassword">
                <h5 class="mb-0">
                    <button id="button_passwd" onclick="blurThatElement(this)" class="btn w-100 collapsed" data-bs-toggle="collapse" data-bs-target="#collapsePassword" aria-expanded="false" aria-controls="collapsePassword">
                        Зачем каждый раз вводить пароль?
                    </button>
                </h5>
            </div>
            <div id="collapsePassword" class="collapse" aria-labelledby="headingPassword" data-parent="#button_passwd">
                <div class="card-body">
                    <div class="row mx-1">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <p>Это нужно для предотвращения хакерских атак, которые с помощью поддельного запроса могут поменять ваш пароль, получить доступ к личным данным и возможность совершать действия от вашего имени.</p>
                                    <p>Подтверждение паролем является одним из наиболее эффективных средств борьбы с такими атаками.</p>
                                    <p>Подробнее можно почитать здесь: <a class="green_link" target="_blank" rel="nofollow" href="https://tproger.ru/articles/mezhsajtovaja-poddelka-zaprosa-zashhita-ot-csrf-atak/">статья на сайте tproger.ru про CSRF-атаки</a></p>
                                    <small>P.S.: Токены нами также используются.</small>
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
            </div>
        </div><!-- password card ends -->
    </div>
</div>
</template>

<script>

export default {
	name: 'pnvPasswdConfirmation',
    props: {
        additionalInfo: { type: Object }
    },
	data () {
        return {
            passwd: "",
            formId: ""
        }
    },
    mounted () {
        // remove _btn ending, get pure id
        const ending = /_btn$/;
        this.formId = this.additionalInfo.formId.replace(ending, "");
    },
    methods: {
        submitPasswd() {
            // set password
            document.getElementById(this.formId + "_current_password").value = this.passwd; 
            // submit the form
            document.getElementById(this.formId).submit();
        }
    }
}
</script>
