<template>
<div class="row">
    <div class="col-12"><label for="user_gender">{{title}}</label></div>
    <div class="col-10 offset-2">
        <div v-show="!formIsShown" class="row">
            <div class="col-12">
                <a @click.prevent="showForm(true)">
                    Редактировать информацию
                    <!-- edit -->
                </a>
            </div>
        </div>
        <div v-show="!formIsShown" class="row">
            <div class="col-12">
                <p v-for="(val, key) in currentFieldsVals">
                    <span>{{key}}: {{val}}</span>
                </p>
                <!-- fields if any -->
            </div>
        </div>
        <!-- or -->
        <div v-show="formIsShown" class="row">
            <div class="col-12 b">
                <pnv_form_workshop 
                    @form-field-chng="updFieldsVal($event)"
                    @submit-data="(params) => saveEdits(params)" 
                    :initialFields="form_config.fields" 
                    :config="form_config.config"></pnv_form_workshop>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import pnv_form_workshop from '../../mid/pnvFormFactoryLight.vue'

export default {
	name: 'pnvPacker',
    components: {
        pnv_form_workshop,
    },
	props: {
        form_config: { type: Object, default: function() { return {} } },
        fieldID: { type: String },
        title: { type: String, default: '' },
        choice: { type: Object },
	},
    data () {
        return {
            currentFieldsVals: {},
            formIsShown: false,
        }
    },
    created () {
        console.log( "choice: " + ( typeof this.choice === 'object' ? JSON.stringify(this.choice) : this.choice ) )
        // console.log( "form_config: " + ( typeof this.form_config === 'object' ? JSON.stringify(this.form_config) : this.form_config ) )
        // console.log( "config: " + ( typeof this.form_config.config === 'object' ? JSON.stringify(this.form_config.config) : this.form_config.config ) )
    },
    computed: {
        today () {
            //get start of the day timestamp
            return Helper.today()
        },
    },
    methods: {
        showForm (val) {
            this.formIsShown = val
        },
        updFieldsVal (newFieldsObj) {
            if ( false ) {
                console.log("not implemented - updFieldsVal")
                // TEST_CODE 
                this.currentFieldsVals = newFieldsObj
            } else {
                // TODO Implement updFieldsVal
                this.currentFieldsVals = newFieldsObj
            }
        },
        saveEdits (vals) {
            if ( false ) {
                console.log("not implemented - saveEdits")
                // TEST_CODE 
                this.showForm(false)
                this.$emit('setJSON', JSON.stringify(this.currentFieldsVals))
            } else {
                // TODO Implement saveEdits
                this.showForm(false)
                this.$emit('setJSON', JSON.stringify(this.currentFieldsVals))
            }
        }
    }
}
</script>
