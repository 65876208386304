<template>
<div id="pnvComplaintForm">
    
    <form :action="this.additionalInfo.path" accept-charset="UTF-8" 
        method="post" class="w-100" data-remote="true">
        
        <div class="row">
            <div class="col-12 redTxt1"> Обращения с несоответсвующей темой будут накладывать бан на отправку будущих обращений. Пожалуйста, будьте внимательны!
            </div>
            
            <div class="col-12">
                Тема обращения: {{chosenOptionTitles}}
            </div>
            
            <div class="col-1">
                <div v-if="hasParentCheck" class="btn redBtn1" @click="returnPrevSelectOption()" 
                    :id="returnBackBtnID">
                    <-
                </div>
            </div>
                
            <div class="col-11" :id="dropdownID">
                <pnvMenu v-if="hasChildrenCheck"
                    @setSelectedOption="setNewChosenOption($event);"
                    :options="selectData.items"
                >
                </pnvMenu>
            </div>
            
            <input name="authenticity_token" type="hidden" :value="additionalInfo.token">
                    
            <input name="[complaint]complaint_type_id" 
                type="hidden" :value="chosenOptionID">
            
            <input name="[complaint]complainable_type" 
                type="hidden" :value="this.additionalInfo.complainable_type">
            
            <input name="[complaint]complainable_id" 
                type="hidden" :value="this.additionalInfo.complainable_id">
        </div>

        <div class="w-100 my-2">
            <div>
                Текст обращения(максимум {{contentLimit}} символов):
            </div>

            <textarea v-model="content" v-on:input="checkTextLimit" 
                name="[complaint]content" type="text" class="w-100">
            </textarea>
        </div>


        <div v-if="!this.checkComplainableObject" class="my-2">
            <div class="">
                <div class="">
                    Ссылка на объект обращения: 
                </div>
                <!-- Change name to object_link -->
                <input id="object_link" name="[complaint]object_link" 
                    type="string" class="w-100">
            </div>
        </div>

        <div class="col-12">
            <div class="row">
                <input type="submit" name="commit" value="Отправить жалобу" 
                    class="btn grnBtn1">
                </input>

                <a :href="additionalInfo.user_agreement_path" class="green_link ml-auto">
                    Пользовательское соглашение
                </a>
            </div>
        </div>
    </form>

</div>
</template>



<script>
import pnvMenu from "./inputs/pnvMenu.vue";

export default {
    name: "pnvComplaintForm",
    components: {
        pnvMenu
    },
    data() {
        return {
            chosenOptionID: '',
            chosenOptionTitles: '',
            chosenOptionSet: [],
            allComplaintTypes: [],
            selectData: {
                items: {},
                placeholder: "Тема",
                backgroundColor: "#0062CC",
                backgroundExpandedColor: "#0062CC",
                textColor: "antiquewhite",
                borderRadius: "1.5em",
                border: "2px solid #ccc",
                width: 250
            },
            dropdownID: 'complaintDropdown',
            returnBackBtnID: 'removeChooseBtn',
            content: '',
            contentLimit: 1500,
            hasChildren: true
        };
    },
    props: { 
        additionalInfo: { type: Object, required: true }
    },
    updated () {
        // this.btnSwitcher();
    },
    computed: {
        checkComplainableObject() {
            return this.additionalInfo.complainable_type;
        },
        hasChildrenCheck() {
            return this.hasChildren
        },
        hasParentCheck() {
            return this.chosenOptionSet.length != 0
        }
    },
    methods: {
        setOptionsList(theParentID) {
            // Returns new list of options based on chosen option.
            // If new list is empty, close dropdown.
            let newSelectOptionsSet = {}
            
            this.allComplaintTypes.forEach(function(complaintType)
                {
                if (complaintType.parent_id == theParentID ) {
                    newSelectOptionsSet[complaintType.id] = complaintType
                }
            });
            // debugger
            // return newSelectOptionsSet;    

            if (Object.keys(newSelectOptionsSet).length) {
                return newSelectOptionsSet;    
            } else {
                // this.btnSwitcher(this.dropdownID, false);
                this.hasChildren = false
                return this.selectData.items
            }

            // this.btnSwitcher(this.returnBackBtnID, true);
        },
        setNewChosenOption(chosenID) {
            // Update chosen option, its path and 
            // dropdown select with new values.
            let chosenOption = this.selectData.items[chosenID]
            this.chosenOptionSet.push(chosenOption);
            this.chosenOptionID = chosenOption.id;
            this.selectData.items = this.setOptionsList(this.chosenOptionID);
            this.chosenOptionTitles = this.computeChosenOptionTitles();
        },
        returnPrevSelectOption() {
            let chosenOption = this.chosenOptionSet.pop();
            this.hasChildren = true
            this.chosenOptionID = chosenOption.parent_id;
            this.selectData.items = this.setOptionsList(this.chosenOptionID);
            this.chosenOptionTitles = this.computeChosenOptionTitles();
        },
        btnSwitcher() {
            let dropdownClassList = document.getElementById(this.dropdownID).classList
            let returnBtnClassList = document.getElementById(this.returnBackBtnID).classList

            if (this.chosenOptionTitles == '') {
                returnBtnClassList.add('d-none');
            } else {
                returnBtnClassList.remove('d-none');
            }

            if (!this.selectData.items) {
                dropdownClassList.add('d-none');
            } else {
                dropdownClassList.remove('d-none');
            }
        },
        computeChosenOptionTitles() {
            let result = '';
            
            this.chosenOptionSet.map(function(elem) {
                result += ' -> ' + elem.title;
            });
            
            return result;
        },
        checkTextLimit() {
            this.content = this.content.substr(0, this.contentLimit);
        }
    },
    created() {
        this.allComplaintTypes = JSON.parse(this.additionalInfo.complaint_types);
        this.selectData.items = this.setOptionsList(null);
    }
};
</script>
