<template>
	<div :id="theId"></div>
</template>

<script>
// used in
// pnvModal
export default {
	name: 'pnvStub',
	props: {
	    additionalInfo: { type: Object }
	},
    computed: {
        theId () {
            return `${this.additionalInfo.modalId}Stub`
        }
    },
}
</script>
