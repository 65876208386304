<template>
<div id="pnv-gallery" class="row">
    <div class="col-12">
        <div v-if="additionalInfo.total_amount > 0" class="row justify-content-center my-1">
            <div class="col-1 btn bluBtn1" @click.prevent="getImage('prev')"> < </div>
            <div class="col-1 text-center">
                {{ pos + 1 }} / {{ additionalInfo.total_amount }}
            </div>
            <div class="col-1 btn bluBtn1" @click.prevent="getImage('next')"> > </div>
        </div>
        
        <div v-if="loading" class="row">
            <div class="col-12 text-center mt-5">Загрузка...</div>
        </div>

        <div v-else class="row">
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-8">
                        <div class="row" style="max-height: 640px; width: auto;">
                            <img v-if="(this.additionalInfo.src_type === 'image')" v-bind:src="currentImg.src" class="m-auto mw-100 rounded" style="max-height: 640px; width: auto;">

                            <iframe v-else-if="additionalInfo.src_type === 'video'" :src="currentImg.src" class="m-auto mw-100 rounded" width="850" height="480" allowfullscreen>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-if="!loading" class="row">
            <div class="col-12"><!-- footer -->
                <div class="row justify-content-center mt-2">
                    <div class="col-8">
                        <pnvVotingBlock 
                            :assets="additionalInfo.assets" 
                            :cached_votes_up="Number(additionalInfo.img.cached_votes_up)" 
                            parent_type="Album" 
                            :parent_id="additionalInfo.img.album_id.toString()" 
                            item_type="Image" 
                            :item_id="additionalInfo.img.id.toString()" 
                            :wasVoted="additionalInfo.img.voted"
                        >
                        </pnvVotingBlock>
                    </div>
                </div>
                <div class="row justify-content-center mt-2">
                    <div class="col-xs-1 mr-5">
                        <strong>Добавлено: </strong> {{ currentImg.created_at }}
                    </div>
                </div>
                <div v-if="!!(currentImg.dscr)" class="row mt-2"> 
                    <div class="col-12">
                        <strong>Описание: </strong>{{ currentImg.dscr }}
                    </div>        
                </div>
            </div><!-- footer end -->
        </div>
    </div>
    <!-- comments -->
    <div class="row" >
        <div class="col-12">
            <div class="row" v-if="cmtSelectionMode">
                <pnvItemSelector
                @selection-submit="parseSubmitResult($event)" 
                :selectedItemIds="selectedCmtsIds" 
                :selectedItemIdsLength="selectedCmtsIds.length" 
                :formRawConfig="dscnFormConfig"
                selectionFieldName="seeds"
                :prompt="selectorPrompt" 
                :status="selectorStatus" 
                :active="cmtSelectionMode"
                @close="switchSelectCmntsAndForm()"></pnvItemSelector>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    Ваш Комментарий:
                </div>
                <small class="ml-2 text-muted">(От 2 до 300 символов)</small>

                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <pnv_form_factory 
                            @form-submitted="(params) => addNewCmnt(params)" 
                            @form-field-chng="(params) => renewForm(params)"
                            :additionalInfo="cmtFormConfig"></pnv_form_factory>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    Комментарии ({{ currentImg.comments_count }})
                </div>
            </div>
            <div class="row ps-2">
                <TransitionGroup id="commentImage" name="item" class="col-12">
                    <pnvComment 
                        @selected="defineCmtInSelection($event)" 
                        @dscn-form="initDscnForm($event)" 
                        @upload-subcomments="uploadSubComments($event)"
                        v-for="(comment, index) in sortedComments" 
                        :key="index" 
                        :comment="comment" 
                        :assets="additionalInfo.assets" 
                        :show_comments_btn="true" 
                        :token="token" 
                        :new_comment="newComment" 
                        :selectionMode="cmtSelectionMode"
                        :wasVoted="false"
                    >
                    </pnvComment>
                </TransitionGroup>
            </div>
            <div v-show="currentImg.comments_count > 0" class="row">
                <div class="col-12" v-if="currentImg.pageNumber">
                    <button @click="loadMoreComments()">Показать следующие  комментарии</button>
                </div>
            </div>
        </div>
    </div>
    <!-- comments end -->
</div>
</template>

<script>
import axios from 'axios';
import { PnVjsHelper } from '../../reusable/pnVjsHelper';

import pnvComment from "../mid/pnvComment.vue";
import pnvIcon from "../low/pnvIcon.vue";
import pnvVotingBlock from "../low/pnvVotingBlock.vue";
import pnvItemSelector from "../mid/pnvItemSelector.vue";
import pnv_form_factory from '../mid/pnvFormFactory.vue' 
// import 'reusable/pnv_selectable'; 

export default {
    name: 'pnvGallery',
    components: {
        pnvComment,
        pnvIcon,
        pnvVotingBlock,
        pnvItemSelector,
        pnv_form_factory,
    },
    props: {
        additionalInfo: { type: Object },
    },
    data () {
        return {
            pos: 0,
            imgIds: [],
            imgs : [],
            domain: '',
            currentImg: {
                pageNumber: 2
            },
            errors: [],
            total_amount: 0,
            loading: false,
            cmtSelectionMode: false,
            selectedCmtsIds: [],
            selectorPrompt: '',
            selectorStatus: 0,
            cmtFormConfig: {},
            newComment: {},
        }
    },
    created () {
        if (this.additionalInfo.src_type === 'image') {
            this.domain = this.additionalInfo._domain
            this.imgIds = this.additionalInfo.img_ids
            this.total_amount = this.additionalInfo.total_amount
            this.pos = this.additionalInfo.pos
            this.setUpImgs()
            this.placeImgInTheGallery(this.additionalInfo.img)
            this.setCurrentImg()
        } else if (this.additionalInfo.src_type === 'video') {
            this.domain = this.additionalInfo._domain
            this.imgIds = this.additionalInfo.vid_ids
            this.total_amount = this.additionalInfo.total_amount
            this.pos = this.additionalInfo.pos
            this.setUpImgs()
            this.placeImgInTheGallery(this.additionalInfo.vid)
            this.setCurrentImg()
            this.setVideo(this.additionalInfo.vid)
        }
        this.$set(this.currentImg, "comments", this.additionalInfo.comments)
        this.$set(this.currentImg, "dscns", this.additionalInfo.dscns)
        this.$set(this.currentImg, "pageNumber", this.additionalInfo.nextPage)
        // this.currentImg.comments = this.additionalInfo.comments
        this.token = this.additionalInfo.token
        // this.currentImg.pageNumber = this.additionalInfo.nextPage
        this.cmntFormConfigSetup('')
        
    },
    computed: {
        lastItemIndex () {
            return this.total_amount - 1;
        },
        sortedComments() {
            const sortedComms = Object.fromEntries(
                Object.entries(this.currentImg.comments).sort(([,a],[,b]) => new Date(b.main.model.created_at)-new Date(a.main.model.created_at))
            );

            return sortedComms
        },
    },
    methods: {
        renewForm(params) {
            this.cmntFormConfigSetup(params['body']) 
        },
        cmntFormConfigSetup(theBodyVal) {
            this.cmtFormConfig = {
                fields: [
                    {
                        cName: 'pnvHiddenField',
                        fieldID: 'commentable_id', 
                        payload: {
                            choice: this.currentImg.id,
                        },
                    },
                    {
                        cName: 'pnvHiddenField',
                        fieldID: 'commentable_type', 
                        payload: {
                            choice: 'Image',
                        },
                    },
                    {
                        cName: 'pnvText',
                        fieldID: 'body', 
                        payload: {
                            choice: theBodyVal,
                            fieldID: 'body'
                        }
                    }
                ],
                config: {
                    url: this.commentPath(), 
                    remote: true, 
                    method: 'post',
                    token: this.additionalInfo.token,
                    html_data: {obj_name: 'comment'},
                    flexibleForm: true,
                },
                submit: {
                    htmlTitle: 'Создать', 
                    htmlClass: 'btn btn-sm grnBtn1'
                }
            }
        },
        getImage (direction) {
            // Helper = new PnVjsHelper()
            this.definePosition(direction)
            if ( Helper.isObjEmpty(this.imgs[this.pos]) ) {
                // if object is empty
                this.requestImg()
            } else {
                this.setCurrentImg()
            }
        },
        setUpImgs () {
            this.imgs = Array(this.total_amount)
            for (let i = this.lastItemIndex; i >= 0; i--) {
                this.imgs[i] = {}
            }
        },
        definePosition (direction) {
            if (direction == "next") {
                if ( this.pos == (this.lastItemIndex) ) {
                    // max value -> start over
                    this.pos = 0
                } else {
                    this.pos = this.pos + 1
                }
            } else {
                if (this.pos == 0) {
                    // min_value -> show last img
                    this.pos = this.lastItemIndex
                }
                else {
                    this.pos = this.pos - 1
                }
            }
        },
        setCurrentImg () {
            this.currentImg = this.imgs[this.pos]
        },
        setVideo(video) {
            this.currentImg.src = video.src
        },
        placeImgInTheGallery (imgData) {
            this.imgs[this.pos] = imgData
        },
        requestImg () {
            const self = this
            this.loading = true
            axios.get(Helper._url + 'images/' + this.imgIds[this.pos] + ".json")
            .then(response => {
                self.placeImgInTheGallery(response.data)
                self.setCurrentImg()

                self.currentImg.comments = response.data.comments
                self.token = response.data.token
                self.currentImg.pageNumber = response.data.nextPage
                self.currentImg.cached_votes_up = response.data.cached_votes_up
                self.currentImg.album_id = response.data.album_id
                self.currentImg.comments_count = response.data.comments_count
                self.currentImg.voted = response.data.voted

                self.loading = false
            })
            .catch(e => {
                self.errors.push(e)
            })
        },
        checkIfParentCmtInForm (cmtStatus) {
            return (cmtStatus) ? !(cmtStatus) : this.cmtSelectionMode
        },
        switchSelectCmntsAndForm() {
            this.cmtSelectionMode = false
        },
        initDscnForm (cmtId) {
            // TODO Make parent_id field work
            this.parentCmtId = cmtId
            // this.selectedCmtsIds.push(cmtId)
            this.cmtSelectionMode = true
        },
        parseSubmitResult(response) {
            if (response.isAxiosError) {
                this.showDscnError(response) 
            } else {
                this.startNewDscn(response)
            }
        },
        startNewDscn (response) {
            if (!!response) {
                this.selectorPrompt = response.msg
                this.selectorStatus = response.status
                window.location.href = 'http://localhost:3000/discussions/' + response.dscn.id;
            } else {
                console.log( "response: " + ( typeof response === 'object' ? JSON.stringify(response) : response ) )
                this.selectorPrompt = "Ошибка при создании дискуссии"
                this.selectorStatus = "Ошибка"
            }
        },
        showDscnError (error) {
            this.selectorPrompt = "Ошибка при создании дискуссии. Сервис дискуссий временно не доступен."
            this.selectorStatus = 500
        },
        addNewCmnt(params) {
            this.$set(this.currentImg.comments, params.comment.main.model.hash_id, params.comment)
            this.cmntFormConfigSetup('')
        },
        commentPath() {
            return Helper._url + Helper.pluralModel('Image') + '/' + this.currentImg.id + '/comments'
        },
        loadMoreComments() {
            const self = this,
                  route = Helper._url + 'images/' + this.currentImg.id + '/get_comments',
                  data = {
                    page: this.currentImg.pageNumber
                  }

            axios.get(route, {params: data})
                .then(response => {
                    for (let [key, value] of Object.entries(response.data.comments)) {
                        self.$set(self.currentImg.comments, key, value)
                        // self.currentImg.comments[key] = value
                    }

                    for (let [key, value] of Object.entries(response.data.dscns)) {
                        self.$set(self.currentImg.dscns, key, value)
                        // self.currentImg.dscns[key] = value
                    }

                    if (response.data.next_page) {
                        self.currentImg.pageNumber++
                    } else {
                        self.currentImg.pageNumber = null
                    }
                })
                .catch(e => {
                    self.errors.push(e)
                })

                // this.$emit('get-more-comments', { 
                //             wallPostID: this.wallPost.model.id, 
                //             page: this.pageNumber});
        },
        uploadSubComments(commentObj) {
            const self = this,
                  route = (Helper._url + 'comments/' + commentObj.commentID + '/replies'),
                  data = {
                      commentable_type: 'Image',
                      commentable_id: this.currentImg.id,
                      hash_id: commentObj.commentHash,
                      updated_at: commentObj.updatedAt
                  }
            self.commentHash = commentObj.commentHash

            axios.get(route, {params: data})
                .then(response => {
                    if (response.data.nesting.length > 0) {
                        let mainCmntPath, childrenPath
                        response.data.nesting.forEach((depth) => {
                            mainCmntPath = self.currentImg.comments[depth]
                            childrenPath = response.data.comments[depth]
                        });

                        mainCmntPath.children[self.commentHash].children = childrenPath.children[self.commentHash].children

                        mainCmntPath.children[self.commentHash].main = childrenPath.children[self.commentHash].main
                    } else {
                        if (!Helper.isObjEmpty(response.data.comments)) {
                            self.currentImg.comments[self.commentHash].children = response.data.comments[self.commentHash].children 

                            self.currentImg.comments[self.commentHash].main = response.data.comments[self.commentHash].main
                        }
                    }
                })
                .catch(e => {
                    console.log(e)
                    this.errors.push(e)
                })
        },

    }
}
</script>
