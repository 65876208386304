<template>
<div>
    <div :class="defineClass">
        <img v-if="sectionType === 'post_image'"
            style="max-width:50vw;max-height:25vh;width:auto;"  
            :src="section.src" 
            class="rounded"
        />
        <div v-if="sectionType === 'text'"  v-html="section.content">
        </div>
        <iframe v-if="sectionType === 'video_link'" 
            style="max-width:50vw;"
            :src="section.parsed_link" 
            :width="width" 
            :height="height"
            class="rounded"
        >
        </iframe>
        <iframe v-if="sectionType === 'post_video'" 
            style="max-width:50vw;"
            :src="section.src" 
            :width="width" 
            :height="height"
            class="rounded"
        >
        </iframe>
    </div>
</div>
</template>

<script>

export default {
    name: 'pnvshowsection',
    components: {
    },
    props: {
        section: {type: Object, required: true},
        wallType: { type: String, required: true }
    },
    data () {
        return {
            sectionType: '',
            width: 'auto',
            height: '400px'
        }
    },
    created () {
        this.defineWallType();
        this.defineSectionType(); 
    },
    computed: {
        defineClass() {
            if (this.sectionType == 'text') {
                return 'col-12'
            } else {
                return 'col-12 text-center'
            }
        }
    },
    methods: {
        defineSectionType() {
            // console.log(this.section)
            this.sectionType = this.section.type.toLowerCase().replaceAll(/\s/g,'');
            if (this.sectionType == 'video_link') {
                this.section.parsed_link = this.section.parsed_link.replace(/&amp;/g, '&');
            }
        },
        defineWallType() {
            // TODO Check if it can be reduced to
            // passing just width as a prop
            if (this.wallType == 'Profile') {
                this.width = '720px';
            } else if (this.wallType == 'Community') {
                this.width = '512px';
            }
        },
        defineImgSize() {
            if (this.section.style == 'small') {
                this.width = '320px';
            }
        }
    }
}
</script>
