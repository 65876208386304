<template>
<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <label :for="fieldID">{{label}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <input  type="file" class="w-100" 
                        :id="fieldID" :ref="reference"
                        :files="files" 
                        :accept="acceptedExtensions" 
                        @change="onChange"
                />
                <button @click.prevent="clear()">Убрать выбранное изображение</button>
            </div>
            <div class="col-12">
                <div class="image-preview" v-if="imageData.length > 0">
                    <img class="preview" :src="imageData">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "pnvFile",
    data() {
        return {
            theValue: '',
            files: [],
            imageData: "",
            /*theConfig: {
                isBottomSectionToggled: false,
                optionsHeight: 0,
                optionHeight: 35,
                backgroundColor: "#336633",
                backgroundExpandedColor: "darkolivegreen",
                hoverBackgroundColor: "#336633",
                borderRadius: "1.5em",
                textColor: "antiquewhite",
                boxShadow: "1px 1px 4px #330033",
                border: "2px solid #ccc",
                showArrow: true
            }*/
        }
    },
    props: {
        choice: { type: String },
        label: { type: String, default: '' },
        fieldID: { type: String },
        fileType: { type: String, default: 'image' },  
        // config: { type: Object, default: function() { return {} } },
        // content_size: { type: Number, default: null }
    },
    mounted() {
        this.theValue = this.choice
    },
    updated () {

        // console.log(this.theValue)
        // this.theValue = this.choice
    },
    /*watch: {
        choice: function (val) {
            // console.log("choice: " + val)
            this.theValue = this.choice
        },
        theValue: function (val) {
            this.$emit('setFileValue', this.theValue);
        },
    },*/
    computed: {
        reference () {
            return this.fieldID + 'ref'
        },
        acceptedExtensions () {
            let values = ''
            if (this.fileType === 'image') {
                values = ".jpg,.jpeg,.png"
            } else {
                if ( true ) {
                    console.log("not implemented - acceptedExtensions")
                    // TEST_CODE 
                } else {
                    // TODO Implement acceptedExtensions other file types
                }
            }
            return values
        },
        // computedStyles: function() {
        //     if (this.content_size != null) {
        //         let width  = 79;
        //         if (this.theConfig.showArrow) {
        //             width += 40
        //         }
        //         width += 3 * this.content_size.toString().length
        //         this.width = width
        //     }
        //     return [
        //         { "--options-height": this.theConfig.optionsHeight + "px" },
        //         { "--options-height-neg": "-" + this.theConfig.optionsHeight + "px" },
        //         { "--main-el-border-radius": this.theConfig.borderRadius },
        //         { "--dropdown-width": this.theConfig.width + "px" },
        //         { "--dropdown-background-color": this.theConfig.backgroundColor },
        //         { "--dropdown-expanded-color": this.theConfig.backgroundExpandedColor },
        //         { "--dropdown-border": this.theConfig.border },
        //         { "--dropdown-box-shadow": this.theConfig.boxShadow },
        //         { "--dropdown-hover-background-color": this.theConfig.hoverBackgroundColor },
        //         { "--dropdown-default-text-color": this.theConfig.textColor }
        //     ];
        // }
    },
    methods: {
        clear() {
            this.files = new DataTransfer().files
            this.imageData = ''
        },
        onChange(e) {
            this.files = e.target.files
            let theObj = this.files

            console.log( "onChange: " + ( typeof theObj === 'object' ? JSON.stringify(theObj) : theObj ) )
            this.$emit('setFileValue', this.files[0]);
            // this.files = this.$refs[this.reference].files
            this.previewImage(e)
        },
        previewImage: function(event) {
            // Reference to the DOM input element
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if (input.files && input.files[0]) {
                // create a new FileReader to read this image and convert to base64 format
                var reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                    // Read image as base64 and set to imageData
                    this.imageData = e.target.result;
                }
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL(input.files[0]);
            }
        },
    }
};
</script>