// element visibility
    export function hide(queryDef) {
        // remove any d-* classes and adds d-none
        // lmntId should be html element's id with '#' included
        $(queryDef).
            addClass('d-none'). // hide 
            removeClass('d-flex d-block d-inline d-inline-block') // remove odd classes      
    };

    export function show(queryDef, showStyle='flex') {
        // can show element in block, flex and etc styles
        // lmntId should be html element's id with '#' included
        $(queryDef).
                removeClass('d-none').
                addClass('d-' + showStyle)
    };

    export function swapVisibility(queryDef0, queryDef1, showStyle='flex') {
        // lmntId should be html element's id with '#' included
        this.show(queryDef1, showStyle); // show the second element
        this.hide(queryDef0); //  hide the first element
    };

    export function switchVisibility(lmntId, show, showClass = 'block') {
        // show or hide element depending on show param val
        // lmntId should be html element's id with '#' included
        show ? this.show(lmntId, showClass) : this.hide(lmntId)
    }

    export function controlVisibility(theField, fieldCondition) {
        // swaps valid and invalid class properties
        // theField should be a jQuery object
        const fieldSelector = theField.data('validClassId');
            if (fieldCondition) {
                this.swapVisibility('.invalid' + fieldSelector, '.valid' + fieldSelector)
            } else {
                this.swapVisibility('.valid' + fieldSelector, '.invalid' + fieldSelector)
            }
            return fieldCondition
    };
// end element visibility

// disable button
    export function enableInput(lmnt) {
        const theLmnt = $(lmnt)
        if (theLmnt.is('A')) {
            theLmnt.removeAttr("disabled");
        } else {
            theLmnt.prop("disabled", false);
        }
    };

    export function disableInput(lmnt) {
        const theLmnt = $(lmnt)
        if (theLmnt.is('A')) {
            theLmnt.attr("disabled", "disabled");
        } else {
            theLmnt.prop("disabled", true);
        }
    };

    export function controlInput(lmntId, fieldCondition) {
        fieldCondition ? this.enableInput(lmntId) : this.disableInput(lmntId)
    };
// end disable button
