<template>
<div class="row position-relative" :style="dropdownHeigthStyle">
    <div class="col-12 w-100 position-absolute">
        <div v-if="!!(label)" class="row">
            <div class="col-12">
                <label :for="fieldID">{{label}}</label>
            </div>
        </div>
        <div class="row">
            <div :class="zIndexClass">
                <div
                    class="dropdown"
                    @click="toggle"
                    :class="{ expanded: isExpanded }"
                    :style="computedStyles"
                >
                    <div class="dropdown-label-container">
                        <div class="dropdown-label">
                            <span class="text" v-if="thePlaceholder.length > 0">
                                {{ (config.prefix ? config.prefix : "") + " "
                                }}{{ thePlaceholder ? thePlaceholder : "" }}
                            </span>
                            <slot></slot>
                            <i v-if="theConfig.showArrow" class="angle-down" :class="{ toggled: isExpanded }"></i>
                        </div>
                    </div>

                    <div v-expand="isExpanded" :class="optionsExpandClass">
                        <div
                            v-for="option in theList"
                            class="option"
                            :title="option.title"
                            @click="setCurrentSelectedOption(option);"
                        >{{ option.badge_name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
    name: "pnvMenu",
    data() {
        return {
            theList: {},
            thePlaceholder: '',
            isExpanded: false,
            initialChoiceSetterOn: false,
            theConfig: {
                isBottomSectionToggled: false,
                optionsHeight: 0,
                optionHeight: 4.5,
                backgroundColor: "#336633",
                backgroundExpandedColor: "darkolivegreen",
                hoverBackgroundColor: "#336633",
                borderRadius: "1.5em",
                textColor: "antiquewhite",
                boxShadow: "1px 1px 4px #330033",
                border: "2px solid #ccc",
                showArrow: true,
            }
        }
    },
    props: {
        choice: { type: String },
        fieldID: { type: String },
        label: { type: String, default: '' },
        placeholder: { type: String, default: '' },
        dropDirection: { type: String, default: 'dropdown' },
        options: { type: Object, required: true },
        config: { type: Object, default: function() { return {} } },
        expanded: { type: Number, default: 0 },
        expandedEventOn: { type: Boolean, default: false },
        choiceForceSet: { type: Boolean, default: false },
        choiceResetPossible: { type: Boolean, default: false }, // allows user to reset a choice option setting the choice val to undefined
        // not documented
    },
    mounted() {
        this.setOptions();
        this.setBlockHeight();
        this.setConfigData();
        // console.log(this.options)
    },
    updated () {
        this.setOptions();
        this.setInitialPlaceholder();
        // console.log(this.choice)
    },
    watch: {
        choice: function (val) {
            // console.log("binding: " + val)
            if (val === "-1") {
                this.$emit('setSelectedOption', undefined);
            } else {
                this.$forceUpdate()
            }
        },
        expanded: function (val) {
            this.isExpanded = false
        },
    },
    computed: {
        optionsExpandClass: function() {
            if (this.dropDirection !== 'dropdown') {
                return "options " + (this.dropDirection + ' rounded ') + "expand"
            } else {
                return "options expand"
            }
        },
        computedStyles: function() {
            if (this.content_size != null) {
                let width  = 79;
                if (this.theConfig.showArrow) {
                    width += 40
                }
                width += 3 * this.content_size.toString().length
                this.width = width
            }
            return [
                { "--options-height": this.theConfig.optionsHeight + "px" },
                { "--options-height-neg": "-" + this.theConfig.optionsHeight + "px" },
                { "--main-el-border-radius": this.theConfig.borderRadius },
                { "--dropdown-width": this.theConfig.width + "px" },
                { "--dropdown-background-color": this.theConfig.backgroundColor },
                { "--dropdown-expanded-color": this.theConfig.backgroundExpandedColor },
                { "--dropdown-border": this.theConfig.border },
                { "--dropdown-box-shadow": this.theConfig.boxShadow },
                { "--dropdown-hover-background-color": this.theConfig.hoverBackgroundColor },
                { "--dropdown-default-text-color": this.theConfig.textColor }
            ];
        },
        zIndexClass: function() {
            if (this.isExpanded) {
                return "col-12 zi100"
            } else {
                return "col-12 zi99"
            }
        },
        dropdownHeigthStyle: function() {
            return `height: ${this.theConfig.optionHeight}em;`
        }
    },
    directives: {
        expand: {
            inserted: function(el, binding) {
                // console.log("inserted")
                if (binding.value !== null) {
                  function calcHeight() {
                    const currentState = el.getAttribute("aria-expanded");
                    el.classList.add("u-no-transition");
                    el.removeAttribute("aria-expanded");
                    el.style.height = null;
                    el.style.height = el.clientHeight + "px";
                    el.setAttribute("aria-expanded", currentState);

                    setTimeout(function() {
                        el.classList.remove("u-no-transition");
                    });
                  }
                  el.classList.add("expand");
                  el.setAttribute("aria-expanded", binding.value ? "true" : "false");
                  calcHeight();
                  el.parentElement.addEventListener("click", calcHeight);
                }
            },
            update: function(el, binding) {
                // console.log("update")
                if (el.style.height && binding.value !== null) {
                  el.setAttribute("aria-expanded", binding.value ? "true" : "false");
                }
            }
        }
    },
    methods: {
        toggle() {
            this.isExpanded = !this.isExpanded
            if (!this.isExpanded) {
                this.$emit('hide')
            } else {
                if (this.expandedEventOn) {
                    this.$emit('expanded', this.fieldID)
                }
            }
        },
        setCurrentSelectedOption(option) {
            this.$emit('setSelectedOption', option.id.toString());
        },
        setOptions() {
            // console.log(this.options)
            this.theList = this.options;
            if (this.choiceResetPossible && !!this.options) {
                this.theList[-1] = { id: -1, badge_name: "н/д", title: "не выбрано" }
            }
        },
        setInitialPlaceholder() {
            // console.log("choice: " + this.choice)
            if (this.choice !== undefined && this.choice !== "" ) {
                this.thePlaceholder = this.theList[this.choice].badge_name;
                if (this.initialChoiceSetterOn) {
                    this.$emit('setSelectedOption', this.choice.toString());
                }
            } else {
                this.thePlaceholder = this.placeholder;
            }
        },
        setConfigData() {
            // this.width = this.config.width;
            // TODO Transform below list to array of css properties iteration
            if (!!this.config.backgroundColor) {
                this.theConfig.backgroundColor = this.config.backgroundColor;
            }
            if (!!this.config.backgroundExpandedColor) {
                this.theConfig.backgroundExpandedColor = this.config.backgroundExpandedColor;
            }
            if (!!this.config.border) {
                this.theConfig.border = this.config.border;
            }
            if (!!this.config.hoverBackgroundColor) {
                this.theConfig.hoverBackgroundColor = this.config.hoverBackgroundColor;
            }
            if (!!this.config.textColor) {
                this.theConfig.textColor = this.config.textColor;
            }
            if (!!this.config.borderRadius) {
                this.theConfig.borderRadius = this.config.borderRadius;
            }
            if (!!this.config.optionHeight) {
                this.theConfig.optionHeight = this.config.optionHeight;
            }
            if (!!this.config.boxShadow) {
                this.theConfig.boxShadow = this.config.boxShadow;
            }
            if (!!this.config.boxShadow) {
                this.theConfig.showArrow = this.config.showArrow;
            }
            this.initialChoiceSetterOn = this.choiceForceSet
        },
        setBlockHeight() {
          this.optionsHeight = this.optionHeight * Object.keys(this.theList).length;
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../../../styles/vue-dropdown";
</style>
