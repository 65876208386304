<template>
<div class="row"><div class="col-12">
    <div class="row" :style="setVerticalOffset()"></div>
    <div v-if="!( showOnlyCropper )" v-show="isShowingStartMenu" class="row" name="ChooseMenu">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-5">
            <label for="ava_upload">
                <span class="btn grnBtn1 btn-lg w-100" aria-hidden="true">
                    Загрузить новое изображение
                </span>
                <input
                    id="ava_upload"
                    ref="input"
                    type="file"
                    name="image"
                    accept="image/*"
                    @change="setImage"
                />
            </label>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-5">
            <button class="btn grnBtn1 btn-lg w-100" @click="showAlbumsMenu()">Выбрать изображение из альбомов</button>
        </div>
    </div>
    <div class="row" name="Switcher">
        <div class="col-12">
            <div v-if="isShowingCropper" class="row" name="Cropper">
                <div class="col-12">
                    <div class="row mb-4">
                        <div class="col-12">
                            <button v-if="showOnlyCropper" class="btn redBtn1 w-100" @click="switchSections('CloseModal')">Назад</button>
                            <button v-else class="btn redBtn1 w-100" @click="switchSections('Start')">Назад</button>
                        </div>
                    </div>
                    <pnvImgCropper :additionalInfo="cropperParams" @setImage="submitImg(cropperParams.id, $event)" />
                </div>
            </div>

            <div v-if="!( showOnlyCropper )" v-show="isShowingAlbums" class="row" name="Albums">
                <div class="col-12">
                    <div class="row mb-4">
                        <div class="col-12">
                            <button class="btn redBtn1 w-100" @click="switchSections('Start')">Назад</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <pnvMenu
                                :config="selectData"
                                :choice="additionalInfo.default_album.id.toString()"
                                :options="additionalInfo.albums"
                                @setSelectedOption="setNewSelectedOption($event);"
                            ></pnvMenu>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-3">
                        <pnvIcon v-for="(item, index) in currentCollection" 
                            :key="item.id" 
                            linkClass="col-3 b green_link" 
                            :inline="additionalInfo.label" 
                            :shape="additionalInfo.shape" 
                            :url="item.img_url" size="lg" 
                            path="empty" event="Cropper" 
                            @pnvIconEvent="setupCropperWithImg($event, index)"
                        >{{item.badge_name}}</pnvIcon>
                        <div v-if="!currentCollection.length" class="col-12 text-center text-muted"><em>Пустой альбом</em></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="currentAvatarPresent && !( showOnlyCropper )" v-show="isShowingStartMenu" class="row my-4">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-5">
            <button class="btn redBtn1 btn-lg w-100" @click="showCurrentAvatarInCropper()">Править установленное сейчас</button>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-5">
            <button class="btn redBtn1 btn-lg w-100" @click="destroyCurrent()">Удалить установленное сейчас</button>
        </div>
    </div>
</div></div>
</template>

<script>
import axios from 'axios';
import pnvImgCropper from '../low/pnvImgCropper';
import pnvMenu from "../low/inputs/pnvMenu.vue";
import pnvIcon from "../low/pnvIcon.vue";

export default {
    name: 'pnvImgSetter',
    components: { 
        pnvMenu,
        pnvIcon,
        pnvImgCropper,
    },
    props: {
        additionalInfo: { type: Object },
    },
    computed: {
        isBanner() {
            return this.additionalInfo.imgType === "banner"
        },
        genPath() {
            return !!(this.additionalInfo.thePath) ? this.additionalInfo.thePath : ( '/' + this.additionalInfo.modelClass + '/' + this.additionalInfo.modelID )
        },
        genUpdPath() {
            return !!(this.additionalInfo.thePath) ? this.additionalInfo.thePath : ( this.genPath + "/upd_img.js" )
        },
        currentAvatarPresent() {
            return !!this.additionalInfo.currentImg
        },
        showOnlyCropper() {
            return this.additionalInfo.imgEditOptions === 'only-cropper'
        },
    },
    data () {
        return {
            selectData: {
                items: {}, 
                placeholder: "Альбомы",
                backgroundColor: "#336633",
                textColor: "antiquewhite",
                borderRadius: "1.5em",
                border: "2px solid #ccc",
                width: 250,
            }, // User's albums dropdown
            cropperParams: {
                id: 0,
                asr: 'free',
                src: '',
                // width: 0,
                // height: 0,
                avaEdit: false,
                currentImgId: 0,
            }, // params passed to ImgCropper
            currentCollection: [], // sets current images visible to user
            axiosErrors: [],
            isShowingAlbums: false,
            isShowingCropper: false,
            isShowingStartMenu: true,
            updated: false, // service val to keep ImgSetter up-to-date
        }
    },
    mounted () {
        this.copyParamsToData()
        this.setInitialSection()
    },
    methods: {
        setInitialSection(){
            this.showOnlyCropper ? this.showCurrentAvatarInCropper() : this.switchSections('Start')
        },
        showAlbumsMenu() {
            this.populateAlbumsDropdown()
            this.switchSections('Albums')
        },
        setVerticalOffset() {
            return (this.isShowingCropper || this.isShowingAlbums) ? '' : 'height:25vh;'
        },
        populateAlbumsDropdown() {
            const albums = this.additionalInfo.albums,
                  dfltAlbum = this.additionalInfo.default_album
            // transfer albums data to the dropdawn
            this.selectData.items = albums
            // transfer default albums images to the the data.albums
            albums[dfltAlbum.id].images = dfltAlbum.images
            // sets currently showed images
            this.currentCollection = dfltAlbum.images
            // update the albums dropdown
            this.updated = !(this.updated)
        },
        copyParamsToData() {
            // this.cropperParams.imgType = this.additionalInfo.imgType
            this.cropperParams.asr = this.additionalInfo.asr;
            this.cropperParams.avaEdit = this.additionalInfo.avaEdit;
            this.cropperParams.submitBtnTitle = this.additionalInfo.submitBtnTitle;
            this.cropperParams.currentImgId = !!(this.additionalInfo.currentImg) ? this.additionalInfo.currentImg.id : "0";
        },
        destroyCurrent() {
            this.submitImg(-1)
        },
        showCurrentAvatarInCropper() {
            if (this.additionalInfo.currentImg) { // if imgSetter was called by clicking on the image
                this.preloadCORSsafeImg(this.additionalInfo.currentImg.id)
                // Don't remove!!!
                // if CORS answer is updated with OPTIONS it should be turned to:
                // this.cropperParams.src = this.additionalInfo.currentImg.dataURL
                this.cropperParams.id = this.additionalInfo.currentImg.id
                this.switchSections('Cropper');
            }
        },
        setImage(e) {
            const file = e.target.files[0];

            if ( this.gotProblemWithImg(file) || this.isNotSupportingFileReaderAPI() ) { return; };

            const reader = new FileReader();

            reader.onload = (event) => {
                this.imgSrc = event.target.result;
                
                this.cropperParams.src = this.imgSrc;
                this.cropperParams.asr = this.additionalInfo.asr
                this.cropperParams.avaEdit = this.additionalInfo.avaEdit

                this.switchSections("Cropper");
                $("#modalScrollDiv").animate({ scrollTop: 0 }, 400);
            };

            reader.readAsDataURL(file);
        },
        isNotSupportingFileReaderAPI() {
            const fileReaderStatusOK = typeof FileReader === 'function'
            if ( !( fileReaderStatusOK ) ) {
              alert('Sorry, FileReader API is not supported!');
            }
            return !( fileReaderStatusOK )
        },
        gotProblemWithImg(file) {
            const imgStatusNotOK = file.type.indexOf('image/') === -1
            if (imgStatusNotOK) {
              alert('Please select an image file');
            }
            return imgStatusNotOK
        },
        switchSections(objName){
            // sets visible sections of the markup
            if ( objName === 'Start' ) {
                this.isShowingStartMenu = true
                this.isShowingCropper = false
                this.isShowingAlbums = false
            } else if (objName === 'Albums') {
                this.isShowingAlbums = true
                this.isShowingCropper = false
                this.isShowingStartMenu = false
            } else if (objName === 'Cropper') {
                this.isShowingCropper = true
                this.isShowingStartMenu = false
                this.isShowingAlbums = false
            } else if (objName === 'CloseModal') {
                this.$emit("form-submitted")
            } else {
                throw 'Invalid section name';
            }
        },
        setNewSelectedOption(selectedOption) {
            if ( this.imgsHaveBeenLoaded(selectedOption) ) { // in previous requests
                // just link them to currentCollection
                this.currentCollection = this.selectData.items[selectedOption].images
            } else {
                // make request to load the requested album content
                this.loadAlbmContent(selectedOption)
            }
        },
        loadAlbmContent(theId) {
            const setter = this
            axios.get('/albums/' + theId + ".json")
                .then(response => {
                    setter.placeImgInTheGallery(response.data)
                })
                .catch(e => { setter.axiosErrors.push(e) })
        },
        placeImgInTheGallery(theAlbum) {
            const albums = this.additionalInfo.albums
            albums[theAlbum.id].images = theAlbum.images
            this.currentCollection = theAlbum.images
        },
        imgsHaveBeenLoaded(theId) {
            const albums = this.selectData.items
            !!(albums[theId].hasOwnProperty("images"))
        },
        submitImg(theImgId, cropperResponse) {
            const setter = this,
                  theForm = {
                    authenticity_token: setter.additionalInfo.token,
                    image: {
                        id: theImgId,
                        image_type: setter.additionalInfo.imgType,
                    },
                  };
                // console.log("cropperResponse")
                // console.dir(!!cropperResponse)
            if ( !!cropperResponse ) {
                theForm.image.image_file = cropperResponse
            }
            axios({
                method: 'PUT',
                url: setter.genUpdPath,
                data: theForm,
            })
                .then(response => {
                    location.reload()
                })
                .catch(e => {
                    setter.axiosErrors.push(e)
                    // console.log("Не удалось загрузить данное изображение:")
                    // console.log(e)
                    alert("Изображение не было загружено. Пожалуйста, попробуйте ещё раз. Если ошибка будет повторятся, пожалуйста, свяжитесь со Службой Поддержки. Мы вам поможем!")
                })
        },
        setupCropperWithImg(theEvent, imgID) {
            this.cropperParams.id = this.currentCollection[imgID].id;
            this.preloadCORSsafeImg(this.currentCollection[imgID].id)
            this.cropperParams.asr = this.additionalInfo.asr
            this.cropperParams.avaEdit = this.additionalInfo.avaEdit
            this.switchSections('Cropper');
        },
        async prepareImg(theId) {
            // dl the img file on PNV and
            // give a CORS acceptable url to the browser
            const imgPromise = await axios.get(this.genCloudImgUrl(theId))
            return imgPromise
        },
        genCloudImgUrl(theId) {
            // leads to img dl on PNV
            // TODO Change it to POST request
            return `/${this.additionalInfo.modelClass}/${this.additionalInfo.modelID}/get_cors_safe_img.json?image[id]=${theId}&image[image_type]=${this.additionalInfo.imgType}`
        },
        preloadCORSsafeImg (imgId) {
            // when shitty CORS rules would be satisfied
            // imgs should be loaded such way
            // this.cropperParams.src = this.currentCollection[imgID].big_img_url;
            this.prepareImg(imgId).then(response => {
                this.cropperParams.src = response.data["imgUrl"]
            }).catch(e => { self.axiosErrors.push(e) })
        },
    }
}
</script>

<style scoped>

input[type="file"] {
  display: none !important;
}

</style>
