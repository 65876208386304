<template>
	<pnvicon :inline="label" :shape="shape" :size="size" :url="url" :showTitle="showtitle" :path="thepath" :linkClass="linkclass" :remoteStatus="remotestatus" :badgeName="badgename" >
		<slot></slot>
	</pnvicon>
</template>

<script>
import pnvicon from '../low/pnvIcon.vue'

export default {
	name: 'pnvsingleicon',
	components: {
		pnvicon
	},
	props: {
		url: {type: String, required: true },
		shape: { type: String, required: true },
		size: { type: String, required: true },
		showtitle: { type: Boolean, default: true },
		label: { type: Boolean, default: false },
		thepath: { type: String, required: true },
		linkclass: { type: String, required: true },
		remotestatus: { type: Boolean, default: false },
		badgename: { type: String, required: false }
	}
}
</script>
