import axios from 'axios';
class PnVjsHelper {
    constructor(
        protocol = "http", 
        domain = 'localhost', 
        port = "3000",
        options) {
        this._protocol = protocol
        this._domainName = domain
        this._domain = protocol + "://" + domain
        this._port = port
        this._url = protocol + "://" + domain + ":" + port + "/"
        this._dictModels = {
            Profile: 'profiles',
            Community: 'communities',
            WallPost: 'wall_posts',
            Album: 'albums',
            Image: 'images',
            Video: 'videos',
            VideoList: 'video_lists',
            Complaint: 'complaints',
            Comment: 'comments'
        }

        /*----------------------- RELATIONS BLOCK -----------------------*/
        this._accept = "Принять запрос"
        this._decline = "Отклонить запрос"
        this._cancel = "Отменить запрос"
        this._remove = "Удалить"
        this._ban = "Заблокировать"
        this._remove_from_list = "Убрать из данного списка"
        this._apply_for_relative = "Подать запрос в родственники"
        this._apply_for_dating = "Подать запрос 'есть друг/подруга'"
        this._apply_for_married = "Подать запрос 'женат/замужем'"
        this._apply_for_engaged = "Подать запрос 'помолвлены'"

        this._improvedTorIDs = [11, 12, 13, 14, 15, 16, 17, 18] 

        this._torTitles = {
              1: { 'id': 1, 'title': "Subscribed", 'ru_title': "Подписан"},
              2: { 'id': 2, 'title': "Friend", 'ru_title': "Друг"},
              3: { 'id': 3, 'title': "Relative", 'ru_title': "Родственник"},
              4: { 'id': 4, 'title': "Married", 'ru_title': "Женат"},
              5: { 'id': 5, 'title': "Engaged", 'ru_title': "Помолвлен"},
              6: { 'id': 6, 'title': "Dating", 'ru_title': "Есть подруга"},
              7: { 'id': 7, 'title': "Deleted", 'ru_title': "Удалил"},
              8: { 'id': 8, 'title': "Blocked", 'ru_title': "Заблокировал"},
              9: { 'id': 9, 'title': "FDeleted", 'ru_title': "Удалил"},
             10: { 'id': 10, 'title': "FBlocked", 'ru_title': "Заблокировал"},
             11: { 'id': 11, 'title': "Relative?", 'ru_title': "Родственники?"},
             12: { 'id': 12, 'title': "Dating?", 'ru_title': "В отношениях?"},
             13: { 'id': 13, 'title': "Engaged?", 'ru_title': "Помолвлены?"},
             14: { 'id': 14, 'title': "Married?", 'ru_title': "В браке?"},
             15: { 'id': 15, 'title': "FRelative?", 'ru_title': "Родственники?"},
             16: { 'id': 16, 'title': "FMarried?", 'ru_title': "В браке?"},
             17: { 'id': 17, 'title': "FEngaged?", 'ru_title': "Помолвлены?"},
             18: { 'id': 18, 'title': "FDating?", 'ru_title': "В отношениях?"},
             19: { 'id': 19, 'title': "Restored", 'ru_title': "Подписан"},
             20: { 'id': 20, 'title': "Both side block", 'ru_title': "Оба заблокированы"}
        }
        /*----------------------- RELATIONS BLOCK END -----------------------*/
        
        // Setup and establish default options.
        this._options = options || {};
    };

  // PnVjsHelper Internal Methods
  // --------------
    makeRqst(theUrl, theParams, options = {}) {
        // TODO Replace Dscn method makeRqst with the method
        /*console.log("theUrl: " + theUrl)
        console.log("theParams: " + JSON.stringify(theParams))
        console.log("options: " + JSON.stringify(options))*/
        const self = this,
              theMethod = options.hasOwnProperty('method') ? options.method : 'get',
              rqstUrl = theUrl + (options.hasOwnProperty('urlExtention') ? options.urlExtention : '') + ( theMethod === 'get' ? ( self.getParamsLineMaker(theParams) ) : '' )
        /*console.log("theMethod: " + theMethod)
        console.log("rqstUrl: " + rqstUrl)*/
        return axios({
              method: theMethod,
              url: rqstUrl,
              data: theParams
            }).then(response => {
                if (!!options?.debugMode) {
                    console.log("response: " + JSON.stringify(response))
                    console.log("data: " + JSON.stringify(response?.data))
                }
                
                return (!self.isObjEmpty(response?.data) ? response.data : response)
            }).catch(e => {
                console.log("axios error:")
                console.log(e)
                // alert("Ошибки не могут быть автоматически исправлены! Проверьте Ваше соединение, и если оно в порядке - пожалуйста, свяжитесь со Службой Поддержки!")
            })
    };
    getParamsLineMaker (params) {
        if (!this.isObjEmpty(params)) {
            return '?' + Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')
        } else {
            return ''
        }
    };

    // Not used anywhere.
    /*async makeAsyncRqst(url) {
        // console.log("makeAsyncRqst: " + "called")
        return await axios.get(url)
            .then(response => {
                return response
            })
            .catch(e => {
                console.log("async axios error:")
                console.log(e)
                // alert("Ошибки не могут быть автоматически исправлены! Проверьте Ваше соединение, и если оно в порядке - пожалуйста, свяжитесь со Службой Поддержки!")
            })
    };*/

  // PnVjsHelper External Methods
  // --------------
    checkVal(item, theVal) {
        /*console.log( "theVal: " + ( typeof theVal === 'object' ? JSON.stringify(theVal) : theVal ) )
        const theObj = typeof theVal
        console.log( "typeof theVal: " + ( typeof theObj === 'object' ? JSON.stringify(theObj) : theObj ) )
        const theObj2 = typeof item
        console.log( "typeof item: " + ( typeof theObj2 === 'object' ? JSON.stringify(theObj2) : theObj2 ) )*/
        switch(item) {
            case 0:  // if nulls are unacceptable
                // console.log( 'equals 0: ' )
                // console.log( theVal === 0 )
                return [0, "0", NaN, "NaN"].includes(theVal)
                break;
            case -1:  // if negatives are unacceptable
                // console.log( 'less than 0: ')
                // console.log( theVal < 0 )
                return theVal < 0
                break;
            case 'undefined':  // if undefined is unacceptable
                // console.log( 'equals undefined: ')
                // console.log( theVal === 'undefined')
                return ["null", "undefined"].includes(theVal)
                break;
            default:
                if (typeof item === 'object') {
                    if (!!item["min"]) {
                        // console.log( 'min val: ')
                        // console.log( theVal < item["min"] )
                        return theVal < item["min"]
                    } else if (!!item["max"]) {
                        // console.log( 'max val: ')
                        // console.log( theVal > item["max"] )
                        return theVal > item["max"]
                    } else {
                        throw 'Unknown unacceptable vals condition!'
                    }
                } else {
                    throw 'Unknown unacceptable vals symbol!'
                }
        }
    };
    scrollBottom (scrlEltId, eltId, skipCheck = false, noScroll = false) {
        // elememt on which we use scroll
        const element = document.getElementById(eltId),
        // element which contains coordinates
        scrollEl = document.getElementById(scrlEltId)
        this.scrlBtm (scrollEl, element, skipCheck, noScroll) 
    };
    scrlBtm (scrlElt, lmnt, skipCheck = false, noScroll = false) {
        // Edge scrollBottom version
        // doesn't rely on elements with htmlIds
        // can use any elements selected by fancy js features such as names, childs and etc
        // TODO Replace scrollBottom with this func
        // elememt on which we use scroll
        if (!(noScroll)) {
            // if the scrollable zone is scrolled more
            // than 1.75 times of its height 
            // then ignore scrolling signals 
            // unless it is strictly ordered to scroll
            const maxScrlAlwdZone = Math.floor(scrlElt.clientHeight*(1.75)),
                  currentScrollPosition = scrlElt.scrollHeight - scrlElt.scrollTop,
                  scrollingAllowed = skipCheck ? skipCheck : (currentScrollPosition < maxScrlAlwdZone)
            // !!! DO NOT delete the code below (it is used for debugging) !!!
                /*console.log("scrollingAllowed: " + scrollingAllowed )
                console.log("currentScrollPosition: " + currentScrollPosition )
                console.log("maxScrlAlwdZone: " + maxScrlAlwdZone )
                console.log("skipCheck: " + skipCheck )
                console.dir(scrlElt )
                console.dir(lmnt )
                debugger*/
            // if we are reading history - don't bother with scrolling down
            if ( scrollingAllowed ) { 
                // console.log("scrollIntoView")
                // debugger
                lmnt.scrollIntoView(false); }
        }
    };
    pluralModel (the_model) {
        return this._dictModels[the_model]
    };
    sizeToPixels (aStringVal) {
        switch (aStringVal) {
            case 'xs':
                return "16"
            case 'sm':
                return "32"
            case 'md':
                return "48"
            default:
                return "64"
        }
    };
    manageHtmlClass ( theArray, htmlClass, val ) {
        const check = theArray.includes(htmlClass)
        if ( val ) {
            this.addHtmlClass(theArray, htmlClass, !check)
        } else {
            this.rmHtmlClass(theArray, htmlClass, check)
        }
    };
    addHtmlClass ( theArray, htmlClass, check ) { 
        if ( check ) {
            theArray.push(htmlClass)
        } else {
            console.log( "htmlClass: " + JSON.stringify(htmlClass) )
            throw "Something went wrong! The html class already presents!"
        }
    };
    rmHtmlClass ( theArray, htmlClass, check ) {
        if ( check ) {
            Helper.removeArrLmnt(htmlClass, theArray)
        } else {
            console.log( "htmlClass: " + JSON.stringify(htmlClass) )
            throw "Something went wrong! The html class is missing!"
        }
    };
    // Obj&Arr extensions
        removeArrLmnt (lmnt, theArray) {
            const idx = theArray.indexOf(lmnt)
            if (idx != -1) {
                 theArray.splice(idx, 1)
            }
        };
        isObjEmpty (obj) {
            return jQuery.isEmptyObject(obj)
        };
        sortObject (obj)  {
            return Object.keys(obj).
            sort().
            reduce((r, k) => (r[k] = obj[k], r), {});
        };
        insertObjIntoAnother(obj1, obj0) {
            // when we have to add contents of obj1
            // to obj0 and obj1 contains data that should 
            // always overwrite obj0 data
            for ( const key in obj1 ) {
                obj0[key] = obj1[key]
            }
                // console.log("insertObjIntoAnother")
                // console.log(obj0)
            // return obj0
        };
    // Time management methods
        currentTimeLog(msg, isActive = false) { 
            if (isActive) {
            const currentdate = new Date(),
                dateString = "now is: " + currentdate.getDate() + "/"
                      + (currentdate.getMonth()+1)  + "/" 
                      + currentdate.getFullYear() + " @ "  
                      + currentdate.getHours() + ":"  
                      + currentdate.getMinutes() + ":" 
                      + currentdate.getSeconds();
                console.log(msg + " - time is - " + dateString)
            }
        };
        calcYesterdayTimestamp (day) {
            const partsOfTheDay = day.split("-")
            // calculate day for the next request
            let yesterDay = (parseInt(partsOfTheDay[2]) - 1)
            if (yesterDay == 0) {
                yesterDay = this.formatDate(
                    new Date(new Date().setDate(new Date().getDate()-1)))
            } else {
                yesterDay = yesterDay.toString()
            }
            // console.log("yesterDay:")
            // console.log(yesterDay)

            return (partsOfTheDay[0] + '-' + partsOfTheDay[1] + '-' + ( yesterDay.length > 1 ? yesterDay : ("0" + yesterDay)))
        };
        formatDate(date) {
            const d = new Date(date),
                year = d.getFullYear();
            let month = '' + (d.getMonth() + 1),
                day = '' + d.getDate();
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        };
        formatTime(timestamp, locale = 'ru', tz = 'UTC') {
            const n = 'numeric',
                  options = {
              year: n, month: n, day: n, 
              hour: n, minute: n, second: n,
              timezone: tz
            };
          return new Date(timestamp).toLocaleString(locale, options)
        };
        tsGolangToJs(ts) {
            return Number(ts.toString().substring(0, 13))
        };
        getIsoDate(theDate) {
            return theDate.toISOString().slice(0, 10);
        };
        today() {
            // returns smth like: "2023-03-29"
            return this.getIsoDate(new Date()).toString();
        };
        now() {
            return this.getTimestamp(new Date());
        };
        onlyHMInDate(timeString) {
            const date = new Date(timeString);
            return date.toLocaleTimeString('ru-RU', {
                hour: '2-digit',
                minute:'2-digit'
            });
        };
        getHourTimestamp(year, month, day, hour) {
            return this.getTimestamp( year + "-" + 
                        String(month).padStart(2, '0') + "-" + 
                        String(day).padStart(2, '0') + "T" + 
                        String(hour).padStart(2, '0') + ":00:00");
        };
        tsOfDate(theDay) {
            return this.getHourTimestamp(theDay.getFullYear(), 
                (theDay.getMonth() + 1), 
                theDay.getDate(), 0)
        };
        getTimestamp(date=null) {
            // returns current time as timestamp
            // like: 1680081493000
            // to get start of the day - pass as param: new Date().toISOString().slice(0, 10)
            // to get yesterday: new Date().toISOString().slice(0, 10) - 864e5
            // with no params allows checking if the action was perfomed today
            return Date.parse( (date === null) ? new Date() : date );
        };
    // Time management block ends

    // Helper methods for wysiwyg
        actionPath(pathParts = {}, paramsObj = {}, format = '') {
            // pathParts contain main parts of th path like:
            // /parent/:id/child/:id 
            // it is normal if part doesnt have a value like: 
            // {complaints: '', new: ''} which produces paths like:
            // /complaints/new
            // format is response format we expect like:
            // .json, .js
            // paramsObj contains additional params like:
            // ?param1=val1&param2=val2
            let thePath = "", firstParam = true
            const params = Object.entries(paramsObj)
            for (const [key, value] of Object.entries(pathParts)) {
                const val_part = value.length > 0 ? ( '/' + value ) : ''
                thePath = thePath + '/' + key + val_part
            }

            if (format.length > 0) { thePath = thePath + format }
            
            // if (params.length > 0) {
                for (const [key, value] of params) {
                  thePath = thePath + 
                    firstParam ? '?' : '&' +
                    key + '=' + value
                    firstParam = false
                }
            // }
            return thePath;
        };
        findComponentByName(currentComp, compName) {
            let foundComponent = {}
            currentComp.$root.$children.forEach((element) => {
                if (element.$options.name == compName) {
                    foundComponent = element;
                }
            });
            return foundComponent;
        };
    // Helper methods for wysiwyg end

    // VIDEO helper methods
        getIDFromUrl(videoUrl) {
            let videoID = '';

            if (videoUrl.includes('youtube') || videoUrl.includes('youtu.be')) {
                let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                let match = videoUrl.match(regExp);

                videoID = (match && match[2].length === 11) ? match[2] : null;

                if (videoID == null) {
                    regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/)([^#&?]*).*/;
                    match = videoUrl.match(regExp);

                    videoID = (match && match[2].length === 11) ? match[2] : null;
                }
            } /*else if videoUrl.includes('youtu.be') {
                
            }*/ else {
                Error('wrong provider');
            }

            return videoID;
        };

        prepareSrc(videoProvider, videoID) {
            let newUrlSrc = '';
            if (videoProvider = 'youtube') {
                newUrlSrc = "https://www.youtube.com/embed/" + videoID
            } /*else if {
            
            }*/ else {
                console.log("Unknown video provider")
                return
            }

            return newUrlSrc
        };

        determineProvider(videoUrl) {
            let provider = '';
            if (videoUrl.includes('vk.com')) {
                provider = 'vk';
            // } else if (videoUrl.includes('zen.ya')) {
            //     provider = 'dzen';
            } else if (videoUrl.includes('youtube') || videoUrl.includes('youtu.be')) {
                provider = 'youtube';
            } else if (videoUrl.includes('rutube')) {
                provider = 'rutube';
            } else {
                Error('wrong provider');
            }

            return provider;
        };

    // VIDEO helper methods end

    // DSCN helper methods
        dscnTabName(theName, the_index) {
            return '#' + theName.toLowerCase().replace(/ /g, '-') + '-' + (the_index + 1)
        }
    // DSCN helper methods end

    /*------------------------- PRIVACY ABILITY BLOCK -------------------------*/
    checkAbility(privacyObj, model, action, addInfo = null) {
        if (privacyObj) {
            switch(model) {
                case "WallPost":
                    return this.checkWallPostAbility(privacyObj, action, addInfo)
                    break;
                case "Comment":
                    return this.checkCommentAbility(privacyObj, action, addInfo)
                    break;
                default:
                    return false
            } 
        } else {
            return false
        }
    }

    checkWallPostAbility(privacyObj, action, addInfo) {
        switch(action) {
            case "create":
                return privacyObj['wall_post'][action]
                break;
            case "feat_matl":
                return privacyObj['wall_post'][action]
                break;
            case "edit":
                return addInfo.isOwner && !this.isExpired(addInfo.model)
                break;
            case "destroy":
                return addInfo.isOwner || privacyObj['wall_post'][action]
                break;
            
            default:
                return false
        } 
    }

    checkCommentAbility(privacyObj, action, addInfo) {
        switch(action) {
            case "create":
                return privacyObj['comment']['create']
                break;
            case "reply":
                return privacyObj['comment']['create'] && addInfo.depth < 5
                break;
            case "edit":
                return addInfo.isOwner && !this.isExpired(addInfo.model)
                break;
            case "destroy":
                return addInfo.isOwner || privacyObj['comment'][action]
                break;
            
            default:
                return false
        } 
    }

    isExpired(model) {
        let editRange1, editRange2, voutesLimit
        if (model.wallType == "Profile") {
            editRange1 = 1*60*60*1000
            editRange2 = 15*60*1000
            voutesLimit = 1
        } else if (model.wallType == "Community") {
            editRange1 = 1*24*60*60*1000
            editRange2 = 1*60*60*1000
            voutesLimit = 1
        } else {
            return true
        }

        if ((new Date()) < (new Date(model.createdAt.getTime() + editRange1))) {
            return false
        } else {
            if ((model.voutesCount < voutesLimit)
                &&
            ((new Date()) < (new Date(model.createdAt.getTime() + editRange2)))) {
                return false
            } else {
                return true
            }
        }
    }   

    /*----------------------- PRIVACY ABILITY BLOCK END -----------------------*/
}

export { PnVjsHelper }
