<template>
<div :id="dynamicalCommentID" :class="htmlClassContent" :style="defineCommentStyle" @close-form="cmtReplyFormShow()">
    <div :class="selectionModeHtmlClass">
        <div class="row">
            <div class="col-12" v-if="isDeleted">
                Комментарий удалён.
            </div>
            
            <div class="col-12" v-else-if="!isDeleted">
            <!-- header -->
                <div class="row justify-content-between bg_dark rounded">
                    <div class="col-2">
                        <pnvIcon 
                            linkClass="row h-100 bwood_link" 
                            :inline="true" 
                            shape="circle" 
                            :url="comment.main.misc.avatar_url" 
                            size="sm" 
                            :path="prflPath"
                        >{{ comment.main.profile.first_name }}</pnvIcon>
                    </div>
                    <div class="col-2">
                        <small class="pr-2"> {{ comment.main.misc.created }} </small>
                    </div>
                </div>
            <!-- header end -->
            <!-- comment body -->
                <div class="bg_light" v-show="commentStatusMsg">
                    {{ statusMsg }}
                </div>
                <div class="row">
                    <div v-show="editFormActive">Сейчас:</div>
                    <div class="col-12 comment_body my-4" v-html="comment.main.model.body">
                    </div>
            <!-- comment body end -->
            <!-- edit comment form -->
                    <div class="pl-2 w-100" style="background:rgba(0, 0, 0, .05)" v-show="editFormActive">
                        <div>Новый вариант:</div>
                        <div class="col-12">
                            <pnv_form_factory 
                                @form-submitted="(params) => editCmnt(params)" 
                                @form-field-chng="(params) => renewEditForm(params['body'])"
                                :initialFields="editCmntFormConfig.fields"
                                :config="editCmntFormConfig.config">
                            </pnv_form_factory>
                        </div>
                    </div>
                </div>
            <!-- edit comment form end -->
            <!-- comment footer -->
                <div class="row justify-content-between">
                    <div class="col-4 position-relative">
                        <div class="position-absolute bottom-0 start-0 bg_light">
                                <span><a v-if="checkAbility('Comment', 'reply')" @click.prevent="cmtReplyFormShow()">Ответить</a>
                                </span> <span><a v-if="checkAbility('Comment', 'destroy')" data-confirm="Точно удалить?" rel="nofollow" data-method="delete" :href="actionPath()" data-remote="true" @click="cmtDestroy()">
                                    Удалить
                                </a></span>
                                <span><a v-if="checkAbility('Comment', 'edit')" @click.prevent="cmtEditFormShow()">
                                    Изменить
                                </a></span>
                        </div>
                    </div>
                    <div class="col-6 me-2">
                        <pnvVotingBlock 
                            @dscn-form="showDscnForm($event)" 
                            :assets="assets" 
                            :cached_votes_up="comment.main.model.cached_votes_up" 
                            :parent_type="comment.main.model.commentable_type.toString()" 
                            :parent_id="comment.main.model.commentable_id.toString()"
                            item_type="Comment" 
                            :item_id="comment.main.model.id.toString()"
                            :isComment="true"
                            :wasVoted="voted"
                            :dscn_id="comment.main.model.dscn_id">
                        </pnvVotingBlock>
                    </div>
                </div>
            <!-- comment footer end -->
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-show="replyFormActive">
                <pnv_form_factory 
                    @submit-data="(params) => addReply(params)" 
                    @form-field-chng="(params) => renewReplyForm(params['body'])"
                    :initialFields="replyCmntFormConfig.fields"
                    :config="replyCmntFormConfig.config">
                </pnv_form_factory>
            </div>
        </div>
    </div>
    <div v-if="selectionMode && !mainCmntInDscn" class="col-1">
        <div class="row">
            <div class="text-center">
                <pnvCheckbox 
                    @setChkbxValue="highlightTheComment($event);" 
                    title="" 
                    :lmntId="cmtCbxHtmlId" 
                    :checkedState="selected"
                >
                </pnvCheckbox>
            </div>
        </div>
    </div>
    
    <div class="col-12">
        <div class="row justify-content-center">
            <div class="col-2 btn btn-sm btn-outline-success" v-if="childrenCount > 0" @click="showReplies()">
                {{showRepliesBtnTxt}}
            </div>  
        </div>
    </div>
    
    <transition name="show-replies">
        <div class="col-12" v-if="showRepliesFlag">
            <pnvComment v-for="(comment, index) in comment.children" 
                @upload-subcomments="uploadSubComments($event)"
                :key="comment.hash_id" 
                :comment="comment" 
                :assets="assets" 
                :prflPath="prflPath" 
                :show_comments_btn="true" 
                :token="token" 
                :new_comment="newComment"
                :privacyAbilities="privacyAbilities"
                :current_profile_id="current_profile_id"
            >
            </pnvComment>
        </div>
    </transition>
</div>
</template>

<script>
import pnvIcon from "../low/pnvIcon.vue";
import pnvVotingBlock from "../low/pnvVotingBlock.vue";
import pnvComment from "../mid/pnvComment.vue";
import pnvCheckbox from '../low/inputs/pnvCheckbox.vue';
import pnv_form_factory from '../mid/pnvFormFactory.vue';

export default {
	name: 'pnvComment',
    components: {
        pnvIcon,
        pnvVotingBlock,
        pnvCheckbox,
        pnv_form_factory,
    },
	props: {
        assets: { type: Object, required: true },
        comment: { type: Object, required: true },
        new_comment: { type: Object },
        privacyAbilities: { type: Object, required: true },
        show_comments_btn: { type: Boolean, default: true },
        discussable: { type: Boolean, default: false },
        selectionMode: { type: Boolean, default: false },
        wasVoted: { type: Boolean, default: false },
        token: { type: String, required: true },
        current_profile_id: { type: Number },
	},
    data () {
        return {
            replyFormActive: false,
            editFormActive: false,
            newComment: { hash: undefined, parent_hash: undefined },
            classes: ["row", "comments-show", "bg_comment", "rounded"],
            htmlClassContent: 'row comments-show bg_comment rounded',
            selected: false,
            isDeleted: false,
            voted: false,
            commentStatusMsg: false,
            statusMsg: "",
            showRepliesFlag: false,
            repliesUploaded: false,
            editCmntFormConfig: {},
            replyCmntFormConfig: {},
            mainCmntInDscn: false,
            errors: [],
        }
    },
    computed: {
        cmtCbxHtmlId () {
            return "cmtCbx-" + this.comment.main.model.id
        },
        selectionModeHtmlClass () {
            return `col-${this.selectionMode ? '11' : '12'} comment`
        },
        isNewCommentParent() {
            return this.new_comment.parent_hash === this.comment.main.model.hash_id
        },
        isNewComment() {
            return this.new_comment.hash === this.comment.main.model.hash_id
        },
        isRootComment() {
            return this.comment.main.model.parent_id === null
        },
        defineCommentStyle() {
            const seniority = this.isRootComment ? '' : 'background: rgba(0, 0, 0, .05);margin-left:5%;width:95%;',
            new_comment = this.isNewComment ? 'border: 3px solid olivedrab;' : '',
            parent_of_new_comment = this.isNewCommentParent ? 'border: 2px solid darkolivegreen;' : ''

            return seniority + new_comment + parent_of_new_comment
        },
        prflPath () {
            return "/profiles/" + this.comment.main.profile.id
        },
        dynamicalCommentID() {
            return `comment_id_${this.comment.main.model.id}`
        },
        childrenCount() {
            return this.comment.main.model.children_count
        },
        showRepliesBtnTxt() {
            if (this.showRepliesFlag) {
                return `Скрыть ответы(${this.childrenCount})`
            } else {
                return `Показать ответы(${this.childrenCount})`
            }
        },
        allowedDepth() {
            return this.comment.main.model.depth < 5
        },
        defCmntFormConfig() {
            return {
                fields: [
                    {
                        cName: 'pnvHiddenField',
                        fieldID: 'commentable_id', 
                        payload: {
                            // choice: this.comment.main.model.commentable_id,
                        },
                    },
                    {
                        cName: 'pnvHiddenField',
                        fieldID: 'commentable_type', 
                        payload: {
                            // choice: this.comment.main.model.commentable_type,
                        },
                    },
                ],
                config: {
                    // url: 'yourUrl'
                    inner: true, 
                    // method: 'post',
                    token: this.token,
                    html: {
                        obj_name: 'comment',
                        title: 'Сохранить', 
                        klass: 'btn btn-sm grnBtn1'
                    },
                },
            }
        },
        editCmntUrl () {
            return `/comments/${this.comment.main.model.id}`
        },
        replyCmntUrl () {
            return Helper._url  + 'comments.json'
        },
        /*hasParent() {
            return typeof this.comment.main.model.parent_id !== 'undefined' && 
                        this.comment.main.model.parent_id !== null
        }*/
    },
    updated () {
        this.setNewComment()
    },
    created () {
        this.setNewComment()
        this.voted = this.wasVoted
        this.renewReplyForm('')
        this.renewEditForm(this.comment.main.model.body)
    },
    methods: {
        renewReplyForm(body) {
            this.replyCmntFormConfig = this.makeForm(body, this.replyCmntUrl, this.comment.main.model.id, "Comment")
        },
        renewEditForm(body) {
            this.editCmntFormConfig = this.makeForm(body, this.editCmntUrl, this.comment.main.model.commentable_id, this.comment.main.model.commentable_type, 'put')
        },
        setNewComment() {
            if (this.new_comment !== undefined) {
                this.newComment = this.new_comment
            }
        },
        makeForm (theBodyVal, theUrl, theCmntblID, theCmntblType, theMethod='post') {
            // Copy this.defCmntFormConfig into formConfig
            // JS doesnt allow copying objects creating only symlinks
            const formConfig = JSON.parse(JSON.stringify(this.defCmntFormConfig));
            formConfig.fields[2] = {
                            cName: 'pnvText',
                            fieldID: 'body', 
                            payload: {
                                choice: theBodyVal,
                                fieldID: 'body'
                            }
                        }
            formConfig.config.url = theUrl
            formConfig.config.method = theMethod 
            formConfig.fields[0].payload.choice = theCmntblID
            formConfig.fields[1].payload.choice = theCmntblType
            return formConfig
        },
        actionPath(action_type = '', params='') {
            // edit, [ upvote, unvote, downvote ], repost, comment
            // params can contain string of additional request params like:
            //  '?param1=val1&param2=val2&...&paramN=valN'
            // return `/comments/${this.comment.main.model.id}/${action_type}${params}`
            let thePath = ""

            if (action_type === '') {
                thePath = `/comments/${this.comment.main.model.id}/${action_type}${params}`
            } else {
                thePath = `/${Helper.pluralModel(this.comment.main.model.commentable_type)}/${this.comment.main.model.commentable_id}/comments/${this.comment.main.model.id}/${action_type}${params}`
            }
            return thePath
        },
        cmtDestroy() {
            this.isDeleted = true
        },
        cmtReplyFormShow() {
            this.replyFormActive = !(this.replyFormActive)
        },
        cmtEditFormShow() {
            this.editFormActive = !(this.editFormActive)
        },
        highlightTheComment (theVal) {
            if (typeof theVal !== 'boolean') { 
                console.log("specify the action, please!")
                throw 'Wrong value!'
                return 
            }
            theVal ? this.classes.push('highlighted') : Helper.removeArrLmnt('highlighted', this.classes)
            this.$emit( 'selected', [ this.comment.main.model.id, theVal ] );
        },
        showDscnForm () {
            this.highlightTheComment(true)
            this.mainCmntInDscn = true
            this.selected = true
            this.$emit( 'dscn-form', this.comment.main.model.id )
        },
        showReplies() {
            if (!this.showRepliesFlag) {
                this.getReplies()
            }
            this.showRepliesFlag = !this.showRepliesFlag
        },
        getReplies() {
            this.$emit("upload-subcomments", {
                commentID: this.comment.main.model.id,
                commentHash: this.comment.main.model.hash_id,
                updatedAt: !this.repliesUploaded ? null : new Date(this.comment.main.model.updated_at).getTime()
            })
            this.repliesUploaded = true
        },
        uploadSubComments(data) {
            this.$emit("upload-subcomments", data)
        },
        childrenUploaded(obj) {
            for (const prop in obj.children) {
                if (Object.hasOwn(obj.children, prop)) {
                    // console.log("Object.hasOwn(obj.children, prop)")
                    // console.log(Object.hasOwn(obj.children, prop))
                    return true;
                }
            }

            return false;
        },
        editCmnt(params) {
            if (params.time_expired) {
                this.statusMsg = "Невозможно изменить комментарий. Вышло время или комментарий набрал много лайков."
            } else {
                if (params.success) {
                    this.statusMsg = "Комментарий успешно обновлен."
                    this.comment.main.model = params.comment
                    this.editCmntFormConfig = this.makeForm(
                        params.comment.body, this.editCmntUrl)
                } else {
                    this.statusMsg = "Что-то пошло не так. Комментарий не был обновлен."
                }    
            }

            this.commentStatusMsg = true
            this.cmtEditFormShow()
        },
        addReply(params) {
            const komment = this,
                  the_params = {};
            // console.log( "komment: " + ( typeof komment.comment.main.model.id === 'object' ? JSON.stringify(komment.comment.main.model.id) : komment.comment.main.model.id ) )
            
            the_params.comment = params
            the_params.authenticity_token = komment.token
            Helper.makeRqst(komment.replyCmntUrl, the_params, { method: 'post' }).
                then( response => {
                    // console.log( "addReply: " + ( typeof response === 'object' ? JSON.stringify(response) : response ) )
                    komment.$set(komment.comment.children, response.comment.main.model.hash_id, response.comment)
                    komment.cmtReplyFormShow()
                    komment.replyCmntFormConfig = komment.makeForm(
                        '', komment.replyCmntUrl)
                    komment.commentStatusMsg = true
                    komment.comment.main.model.children_count++
                    komment.statusMsg = "Ответ успешно добавлен."
                }).
                catch( e => {
                    komment.statusMsg = "Что-то пошло не так. Ответ не был добавлен."
                    komment.errors.push(e)
                })
        },
        checkAbility(model, action) {
            let addInfo = {
                'isOwner': this.current_profile_id === this.comment.main.model.profile_id
            }

            if (action === "edit") {
                addInfo.model = {
                    'wallType': this.comment.main.model.parent_commentable_type,
                    'createdAt': new Date(this.comment.main.model.created_at),
                    'votesCount': this.comment.main.model.cached_votes_total,
                }
            } else if (action === "reply") {
                addInfo.depth = this.comment.main.model.depth + 1
            } 

            return Helper.checkAbility(
                this.privacyAbilities,
                model, 
                action,
                addInfo,
            )
        },
    },
    watch: {
        classes: function(val) {
            // returns concatenated string
            this.htmlClassContent = ""
            for (let i = 0; i < this.classes.length; i++) {
                if (this.htmlClassContent.length > 0) {
                    this.htmlClassContent = this.htmlClassContent + " " + this.classes[i]
                } else {
                    this.htmlClassContent = this.classes[i]
                }
            }
        },
        selectionMode: function(val) {
            if (!val) {
                Helper.removeArrLmnt('highlighted', this.classes)
            }
        },
    },
}
</script>

<style scoped>
    .highlighted {
        background-color: khaki;
    }

    .show-replies-enter-active, .show-replies-leave-active {
      transition: opacity .5s;
    }
    .show-replies-enter, .show-replies-leave-to {
      opacity: 0;
    }
</style>
