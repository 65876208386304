<template>
<div class="row voting_block">
    <div class="col-12 d-flex" style="height: 48px;">
        <div class="row h-75 align-self-center">
            <div class="btn-group btn-group-sm">
                <div class="bg_dark rounded-pill px-2 mx-1">
                    <a  class="btn nav-link"
                        :href="this.votePath()" 
                        data-remote="true" 
                        rel="nofollow">
                        <img :class="voteHtmlClass"
                            alt="PNV" 
                            :src="assets.handImgUrl">
                        <span class="bwd_txt_clr"
                            @vote="(theData) => vote(theData)" 
                            :id="voteHtmlId"
                            v-html="voteSize"></span>
                    </a>
                </div>
                <div v-if="this.showBtnForItem('repost')" class="bg_dark rounded-pill px-2 mx-1">
                    <a  class="btn nav-link"
                        :href="this.defaultActionPath('repost_form')"
                        data-remote="true" 
                        rel="nofollow" >
                        <img class="icon-sm"
                            alt="Repost" 
                            title="Репост" 
                            :src="assets.repostImgUrl">
                        <span class="bwd_txt_clr" 
                              v-html="repostSize"></span>
                    </a>
                </div>
                <div v-if="show_comments_btn" class="bg_dark rounded-pill px-2 mx-1" v-on:click="showPost()">
                    <div v-if="this.showBtnForItem('show_comments')" 
                        class="btn nav-link">
                        <img 
                            alt="Comment" 
                            title="Комментарии" 
                            :src="assets.commentImgUrl" 
                            class="icon-sm">
                        <span class="bwd_txt_clr" v-html="commentSize"></span>
                    </div>
                </div>
                
                <div v-if="this.showBtnForItem('dscn')">
                    <div v-if="!hasDscn" 
                        class="bg_dark rounded-pill px-2 mx-1">
                        <a class="btn nav-link"
                            @click.prevent="cmtDscn()" 
                            rel="nofollow" >
                            <img class="icon-sm"
                                alt="Создать дискуссию" 
                                title="Дискуссии" 
                                :src="assets.dscnImgUrl">
                            <span class="bwd_txt_clr">Создать дискуссию</span>
                        </a>
                    </div>
                    <div v-else class="bg_dark rounded-pill px-2 mx-1">
                        <a  class="btn nav-link"
                            :href="this.showDscnPath()" 
                            rel="nofollow">
                            <img class="icon-sm"
                                alt="Перейти в дискуссию" 
                                title="Дискуссии" 
                                :src="assets.activeDscnImgUrl">
                            <span class="dg_txt_clr">Перейти в дискуссию</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
	name: 'pnvVotingBlock',
	props: {
        assets: { type: Object, required: true },
        cached_votes_up: { type: Number/*, required: true, default: 0*/ },
        reposts_count: { type: Number/*, required: true, default: 5*/ },
        comments_count: { type: Number/*, required: true, default: 15*/ },
        // canEditPost: { type: Boolean, default: false },
        isComment: { type: Boolean, default: false },
        item_type: { type: String, required: true },
        item_id: { type: String, required: true },
        show_comments_btn: { type: Boolean, default: false },
        parent_type: { type: String, required: true },
        parent_id: { type: String, required: true },
        wasVoted: { type: Boolean, default: false, required: true },
        dscn_id: { type: Number, default: null },
	},
    data () {
        return {
            voteSize: 0,
            repostSize: 0,
            commentSize: 0,
            voted: false,
            dscnStatus: 1,
            dscnID: 0
        }
    },
    mounted () {
        this.voteSize = this.cached_votes_up;
        this.repostSize = this.reposts_count;
        this.commentSize = this.comments_count;
        this.voted = this.wasVoted;
        // this.dscnStatus = this.has_dscn;
        this.dscnID = this.dscn_id;
    },
    computed: {
        /*complaintPath() {
            return Helper.actionPath(
                {complaints: '', new: ''}, 
                {complainable_type: this.wall_type, 
                 complainable_id: this.item_id})
        },*/
        voteHtmlId() {
            return 'voting_index_' + this.item_id
        },
        voteHtmlClass() {
            return 'icon-sm vote' + ( this.voted ? ' shinyBox1 rounded-circle' : '' )
            // return 'icon-nav vote' + ( this.voted ? ' shinyBox1 rounded-circle' : '' )
        },
        hasDscn() {
            return this.dscnID && this.dscnID != 0
        }
    },
    methods: {
        cmtDscn () {
            this.$emit('dscn-form');
        },
        dscnStatuses () {
            return {
                1: 'new',
                2: 'created',
                3: 'finished',
                4: 'halted',
                5: 'banned',
                6: 'prohibited'
            }
        },
        defaultActionPath(action_type = '') {
            // edit, [ upvote, unvote, downvote ], repost, comment
            let pathObj = {}, paramsObj = {}, format = ''
            
            if (action_type == 'repost_form') {
                pathObj[Helper.pluralModel(this.parent_type)] = this.parent_id
                pathObj[Helper.pluralModel(this.item_type)] = this.item_id
                pathObj[action_type] = ''
            } else if (action_type == 'show_dscn') {
                pathObj["discussions"] = this.dscnID.toString()
            } else {
                pathObj[Helper.pluralModel(this.item_type)] = this.item_id
                pathObj[action_type] = ''
            }

            // TODO make show action with param to 
            // autoscroll to comments section of the show view
            return Helper.actionPath(pathObj, paramsObj, format)
        },
        showDscnPath () {
            return this.defaultActionPath("show_dscn")
        },
        showPost() {
            this.$emit('show-post-in-modal');
        },
        votePath(type = "upvote") {
            // [ upvote, unvote, downvote ]
            if (this.voted) {
                // post has received our vote
                // we can remove it
                // return `/wall_posts/${this.wallPost.id}/unvote`
                return this.defaultActionPath('unvote')
            } else {
                /* Switched off until downvoting is active
                if (type == "downvote" && this.wallPost.misc.canDownvote) {
                    // user has additional privilegies
                    // they can also downvote
                    // return `/wall_posts/${this.wallPost.id}/downvote`
                    return this.defaultActionPath(type)
                }*/
                // post can be upvoted
                // return `/wall_posts/${this.wallPost.id}/upvote`
                return this.defaultActionPath(type)
            }
        },
        vote(theData) {
            const data = theData.detail
            this.repostSize = data.reposts_count
            this.voteSize = data.votes_count
            this.commentSize = data.comments_count
            if (data.success) {
                this.voted = !(this.voted)
            }
            // TODO Make a disappearing message when voting
            // this.msg = ( data.message == "+1" )
        },
        showBtnForItem(btnAction) {
            if (this.item_type == "WallPost") {
                if (btnAction == "show_comments") {
                    return true
                } else if (btnAction == "repost") {
                    return true
                } else if (btnAction == "dscn") {
                    return false
                } else {
                    return false
                }
            } else if (this.item_type == "Comment") {
                if (btnAction == "show_comments") {
                    return false
                } else if (btnAction == "repost") {
                    return false
                } else if (btnAction == "dscn") {
                    return true
                } else {
                    return false
                }
            } else if (this.item_type == "Image") {
                if (btnAction == "show_comments") {
                    return true
                } else if (btnAction == "repost") {
                    return false
                } else if (btnAction == "dscn") {
                    return false
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    }
}
</script>
