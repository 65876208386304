require("@rails/ujs").start();
require("turbolinks").start();
require("jquery");

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import pnvmodal from '../components/mid/pnvModal.vue'
import pnvsingleicon from '../components/mid/pnvSingleIcon.vue'
import pnvslider from '../components/mid/pnvSlider.vue'
import { PnVjsHelper } from '../reusable/pnVjsHelper';

import * as validator from 'reusable/pnv_validatable';
import * as visibility from 'reusable/pnv_visibility';
import * as datepicker   from 'jquery-ui/ui/widgets/datepicker';
import * as tabs from 'jquery-ui/ui/widgets/tabs';
import * as $ from 'jquery';

Vue.use(TurbolinksAdapter)

$(document).on('turbolinks:load', () => {
    window.Helper = new PnVjsHelper("http", "localhost", "3000")
    const app3 = new Vue({
        el: '#pnvPageBody',
        components: {
            pnvmodal,
            pnvslider,
            pnvsingleicon//,
            //bgrd
        }
    });

    window.submitAllowed = { name: false, surname: false, birth: false, email: false, mobile: false, password: false, passwd_confirm: false };
    window.fieldIDs = { name: "#user_name", surname: "#user_surname", birth: "#user_birth", email: '#user_email', mobile: '#user_mobile', password: '#user_password', passwd_confirm: '#user_password_confirmation'};
    checkPasswdFields();
    checkField(fieldIDs.name, 'name');
    checkField(fieldIDs.surname, 'surname');
    checkField(fieldIDs.birth, 'birth');
    checkField(fieldIDs.email, 'email');
    checkField(fieldIDs.mobile, 'mobile');
    checkField(fieldIDs.password, 'password');

    const signInBtn = document.getElementById("signInBtn")
    if (!!(signInBtn)) {
        signInBtn.addEventListener("click", function () {
            document.getElementById("new_user").submit();
        });
    };

    $( "#tabs" ).tabs();
    $("#user_birth").datepicker({
        changeMonth: true,
        changeYear: true,
        dateFormat:"dd.mm.yy",
        defaultDate: "-25y",
        yearRange: "c-70:c+20",
        firstDay: 1,
        dayNamesMin: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
        monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июнь", "Июль", "Авг", "Сен", "Окт", "Ноя", "Дек"]
    });
    // Disable Turbolinks for Same-Page or Empty Links:
    document.querySelectorAll('a[href^="#"]').forEach(function (el) {
        el.setAttribute('data-turbolinks', false);
    });    
});

function submitFormWithoutButton(formId) {
    document.getElementById(formId).submit();
};

function navDisplaySwitch(elementId, className, scrolledType, scrolledVal) {
    // switches on and off desired elements depending on scrolledType
    if($(className).length > 0){
            window.onscroll = function() {
            var scrolled = window.pageYOffset;
            if (scrolledType == "moreThan") {
                visibility.switchVisibility(elementId, scrolled > scrolledVal);
            } else {}
                visibility.switchVisibility(elementId, scrolled < scrolledVal);
        };
    };
}

window.blurThatElement = function (thatElement) {
  thatElement.blur();

};

// Validation of login based on checking e-mail or mobile phone number starts
    function validateSignInLogin(login) {
        // used when both e-mail and mobile could be accepted
        return !!(smth(login).val()) ? (validator.validateMobile(login.val()) || validator.validateEmail(login.val())) : false
    };

    function validateNameField(name) {
        // used only for e-mail
        return  !!(smth(name).val()) ? validator.validateName(name.val()) : false
    };

    function validateSurnameField(surname) {
        // used only for e-mail
        return  !!(smth(surname).val()) ? validator.validateSurname(surname.val()) : false
    };

    function validateBirthField(birth) {
        // used only for e-mail
        return  !!(smth(birth).val()) ? validator.validateBirth(birth.val()) : false
    };

    function validateMailField(login) {
        // used only for e-mail
        return  !!(smth(login).val()) ? validator.validateEmail(login.val()) : false
    };

    function validateMobileField(login) {
        // used only for mobile
        return  !!(smth(login).val()) ? validator.validateMobile(login.val()) : false
    };

    function validatePasswordField(passwd) {
        return  !!(smth(passwd).val()) ? validator.validatePassword(passwd.val()) : false
    };

    function validatePasswordConfirmation(passwd, passwdConfirmation) {
        if ( !!(passwd) && !!(passwdConfirmation) )  {
            return passwd.val() === passwdConfirmation.val()
        } else {
            return false
        }
    };

    function checkField(fieldDef, fieldType, fieldDef0=null) {
        // the func demands submitAllowed object to be set up
        let theField = fieldDef0 != null ? 
                        $(fieldDef0).add(fieldDef) :
                        $(fieldDef);
            theField.bind('click keyup', (function() {
            // mark field as valid
            switch (fieldType) {
                case 'name':
                    submitAllowed[fieldType] = validateNameField(theField);
                    break;
                case 'surname':
                    submitAllowed[fieldType] = validateSurnameField(theField);
                    break;
                case 'birth':
                    submitAllowed[fieldType] = validateBirthField(theField);
                    break;
                case 'email':
                    submitAllowed[fieldType] = validateMailField(theField);
                    break;
                case 'mobile':
                    submitAllowed[fieldType] = validateMobileField(theField);
                    break;
                case 'password':
                    submitAllowed[fieldType] = validatePasswordField(theField);
                    break;
                case 'passwd_confirm':
                    // debugger
                    submitAllowed[fieldType] = validatePasswordConfirmation(
                        smth($(fieldDef0)), 
                        smth(fieldDef)
                    );
                    theField = $(fieldDef);
                    break;
                default:
                    throw "Validation couldn't be proceeded: unknown field type!";
                    break;
            };
                
            // launch visual effects
            validator.controlField(theField, submitAllowed[fieldType]);
        }));
    };

    function checkPasswdFields() {
        // the func requires target field to have
        // submit-lmnt-id data to be set up in views
        const passwd = $('#user_password'),
              passwdConfirmation = $('#user_password_confirmation'),
              submitBtn = $(passwdConfirmation.data('submitLmntId'))
        let slowDisappearing = new Promise(function() {});
        submitBtn.on('click', function(event){
            if ($(this).is('[disabled]')) {
                for (const [key, value] of Object.entries(submitAllowed)){
                    if (!!value == false) {
                        visibility.controlVisibility($(fieldIDs[key]), value)
                    }
                }

                event.preventDefault();
                slowDisappearing = new Promise(function(resolve, reject) {
                    visibility.show('.invalid.form', 200)
                });
                slowDisappearing.then(
                    visibility.hide('.invalid.form', 3000)
                 )
            }
        });

        // controlVisibility(field0Def, submitAllowed.some(function() {}))
        checkField(passwdConfirmation, 'passwd_confirm', passwd);
    };

    function smth(lmnt) {
        // checks if element exists
        // if (lmnt.length > 0 && lmnt[0].value != "") {
            return lmnt
        // } else {
            // return false
        // };
    };
// Validation of login based on checking e-mail or mobile phone number ends

// Validation of cookies acceptance
    function setCookie(name, value, days) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name) {
        const matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    };

    function checkCookies() {
        const cookieNote = document.getElementById('cookie_note');

        // Если куки cookies_policy нет или она просрочена, то показываем уведомление
        if ( (typeof cookieNote !== undefined) && !getCookie('cookies_policy') ) {
            cookieNote.classList.remove('d-none');
        }
    };

    window.acceptCookies = function () {
        // При клике на кнопку устанавливаем куку cookies_policy на один год
        setCookie('cookies_policy', 'true', 365);
        const cookieNote = document.getElementById('cookie_note');
        cookieNote.classList.add('d-none');
    };

    /*window.resetCookies = function () {
        setCookie('cookies_policy', 'false', -1);
    };*/

    $(document).on('turbolinks:load', () => {
        checkCookies();
    });
// Validation of cookies acceptance ends
    window.showInvitation = function () {
        const priglos = document.getElementById('priglos')
        if ( (typeof priglos !== undefined) ) {
            if (priglos.childNodes.length > 0) {
                priglos.innerHTML = '';
            } else {
                priglos.innerHTML = "<i>Вы можете попросить приглашение, написав на почту:</i><br><i>".concat("mail", "@", "albus-online", ".ru</i>");
            }
        };
    };
