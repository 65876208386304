<template>
<div class="row">
    <div v-show="false" v-if="!!upd">{{tsUpdater}}</div>
    <div class="col-12"><label :for="fieldID">{{label}}</label></div>
    <div class="col-md-12 col-sm-7 col-lg-12 col-xl-7 col-xs-7">
        <div class="row">
            <div class="col-6 date pe-lg-0 pe-xl-0"><pnvMenu 
                :placeholder="dateConfig.placeholder" 
                :options="dateConfig.options"
                :choice="date.toString()"
                :fieldID="subMenuId(dateConfig.fieldID)"
                :label="dateConfig.label"
                :config="dateConfig.config"
                :dropDirection="dateConfig.dropDirection"
                :expandedEventOn="true"
                :expanded="dateExpanded"
                :choiceResetPossible="outputConfig.partsOfDate"
                @expanded="hideOtherTwo(['yearExpanded', 'monthExpanded'])"
                @setSelectedOption="chngDay($event);"
                >
            </pnvMenu></div>
            <div class="col-6 month px-lg-1 px-xl-1"><pnvMenu 
                :placeholder="monthConfig.placeholder" 
                :options="monthConfig.options"
                :choice="month.toString()"
                :fieldID="subMenuId(monthConfig.fieldID)"
                :label="monthConfig.label"
                :config="monthConfig.config"
                :dropDirection="monthConfig.dropDirection"
                :expandedEventOn="true"
                :expanded="monthExpanded"
                :choiceResetPossible="outputConfig.partsOfDate"
                @expanded="hideOtherTwo(['dateExpanded', 'yearExpanded'])"
                @setSelectedOption="chngMonth($event);"
                >
            </pnvMenu></div>
        </div>
    </div>
    <div class="col-md-12 col-sm-5 col-lg-12 col-xl-5 col-xs-5 year"><pnvMenu 
        :placeholder="yearConfig.placeholder" 
        :options="yearConfig.options"
        :choice="year.toString()"
        :fieldID="subMenuId(yearConfig.fieldID)"
        :label="yearConfig.label"
        :config="yearConfig.config"
        :dropDirection="yearConfig.dropDirection"
        :expandedEventOn="true"
        :expanded="yearExpanded"
        :choiceResetPossible="outputConfig.partsOfDate"
        @expanded="hideOtherTwo(['dateExpanded', 'monthExpanded'])"
        @setSelectedOption="chngYear($event);"
        >
    </pnvMenu></div>
</div>
</template>

<script>
import pnvMenu from './pnvMenu.vue'

export default {
	name: 'pnvDate',
    components: {
        pnvMenu,
    },
	props: {
        fieldID: { type: String, default: ""},
        choice: { type: Object, default: function() { return {} } },
        gap: { type: Number, default: -14 }, // defines gap to current date
        // ex. min age is 16 then gap should be -16
        yearDepth: { type: Number, default: 107 }, // defines how many years should be populated
        // ex. max age is 107 then yearDepth should be 107
        dropDirection: { type: Boolean, default: false },
        stylesConfig: { type: Object, default: function() { return {} } },
        label: { type: String, default: "" },
        outputConfig: { type: Object, default: function() { return {} } }, // partsOfDate, dateFormat
        upd: { type: Number, default: 0 },
	},
    data () {
        return {
            date: 0,
            month: 0,
            year: 0,
            dateConfig: {},
            monthConfig: {},
            yearConfig: {},
            dateExpanded: 0,
            monthExpanded: 0,
            yearExpanded: 0,
        }
    },
    created () {
        if (this.outputConfig.partsOfDate) {
            this.setDatePartly(this.choice)
        } else {
            this.setTheDate( this.setInitialOrDfltDate() )
        }
        this.setSubMenuConfigs(this.year, this.month, this.date)
    },
    computed: {
        tsUpdater () {
            if (!this.outputConfig.partsOfDate) {
                this.setTheDate( new Date (this.upd) )
            }
            return this.upd
        },
        defaultDate () {
            return new Date(new Date().getFullYear() + this.gap, 0, 1)
        },
        outputFormat() {
            if (!!this.outputConfig?.dateFormat) {
                console.log("outputFormat is not ready yet!")
            }
        },
    },
    methods: {
        ifChoiceTsPresents () {
            return !!this.choice?.ts
        },
        setDatePartly (parts) {
            this.year = !!parts.year ? parts.year : -1
            this.month = !!parts.month ? parts.month : -1
            this.date = !!parts.day ? parts.day : -1
        },
        setTheDate (date) {
            this.year = date.getFullYear()
            this.month = date.getMonth() + 1
            this.date = date.getDate()
        },
        setInitialOrDfltDate() {
            return this.ifChoiceTsPresents() ? this.makeDateOutOfTs() : this.defaultDate
        },
        makeDateOutOfTs() {
            return new Date(this.choice.ts)
        },
        // submenu methods
            setSubMenuConfigs(year, month, date) {
                this.makeYearConfig(year)
                this.makeMonthConfig(month)
                this.makeDateConfig(date)
            },
            makeYearConfig (val) {
                this.yearConfig = {
                        options: this.makeYearOptions(),
                        fieldID: "year",
                        label: "Год",
                        config: this.stylesConfig,
                }
            },
            makeMonthConfig (val) {
                this.monthConfig = {
                        options: this.makeMonthOptions(),
                        fieldID: "month",
                        label: "Месяц",
                        config: this.stylesConfig,
                }
            },
            makeDateConfig (val) {
                this.dateConfig = {
                        options: this.makeDateOptions(),
                        fieldID: "date",
                        label: "День",
                        config: this.stylesConfig,
                }
            },
            makeYearOptions () {
                return this.optionsMaker( this.makeYearRange() )
            },
            makeMonthOptions () {
                return this.optionsMaker( this.makeMonthRange() )
            },
            makeDateOptions () {
                return this.optionsMaker( this.makeDateRange() )
            },
            optionsMaker(items) {
                const options = {}
                for (let i = items.length - 1; i >= 0; i--) {
                    const date = items[i].toString();
                    options[date] = {id: date, badge_name: date, title: date}
                }
                return options
            },
            makeDateRange () {
                return Array.from( { length: this.daysInCurrentMonth() }, (_, i) => this.daysInCurrentMonth() - i );
            },
            makeMonthRange () { 
                return Array.from({ length: 12 }, (_, i) => 12 - i);
            },
            makeYearRange() { 
                // TODO make method to make obj with further year stating with lesser index
                return Array.from( { length: (this.yearDepth + this.gap) }, (_, i) => new Date().getFullYear() + this.gap - i );
            },
            daysInCurrentMonth () { // Use 1 for January, 2 for February, etc.
                if (this.month !== -1) {
                    return new Date(this.year, this.month, 0).getDate();
                } else {
                    return 31
                }
            },
            hideOtherTwo(ids) {
                this.hideSubMenu(ids[0])
                this.hideSubMenu(ids[1])
            },
            hideSubMenu(id) {
                this[id] = this[id] + 1
            },
            subMenuId (configId) {
                return this.fieldID + '_' + configId
            },
            // submenu methods end
        chngDay (val) {
            // console.log( "chngDay: " + ( typeof val === 'object' ? JSON.stringify(val) : val ) )
            this.date = val
            this.chngDate()
        },
        chngMonth (val) {
            this.month = val
            this.dateReconfig()
            this.chngDate()
        },
        chngYear (val) {
            this.year = val
            this.dateReconfig()
            this.chngDate()
        },
        chngDate () {
            const theObj = {
                year: this.year, 
                month: this.month !== -1 ? this.month : this.month, // keep -1 val as it is
                day: this.date
            }
            if (!!this.outputConfig.partsOfDate) {
                this.$emit('setSelectedOption', {
                    year: this.year, 
                    month: this.month !== -1 ? this.month : this.month, // keep -1 val as it is
                    day: this.date
                });
            } else {
                const val = Number( new Date(this.year, this.month - 1, this.date) )
                this.$emit('setSelectedOption', { ts: val });
            }
        },
        dateReconfig () {
            if ( !this.outputConfig.partsOfDate ) {
                const date = new Date(this.year, this.month - 1, this.date),
                      firstMonthDay = new Date(this.year, this.month - 1, 1),
                      monthHasSuchDate = date.getMonth() === this.month - 1
                this.makeDateConfig(monthHasSuchDate ? date : firstMonthDay) // dates list reconf
                if (!monthHasSuchDate) { // if the chosen date disappeared
                    this.date = firstMonthDay.getDate() // drop the choice
                }
            }
        },
    }
}
</script>
