<template>
<div class="row rounded my-3"
    @add-comment="(theData) => reloadCmnts(theData.detail)" 
    @wallpost-destroy="hideDestroyedPost()" 
    :id="wallpostHtmlId" 
    :style="bgrd()">
    <div class="col-12">
        <div class="row" v-if="destroyed === 1">
            <div class="col-12">
                Пост удалён. Для его просмотра <a class="pnv_clr_green" href="#" @click.prevent="showDestroyedPost()"> нажмите здесь </a>.
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-12" v-if="destroyed === 2">Этот пост был удалён. Чтобы его скрыть<a class="pnv_clr_red" href="#" @click.prevent="hideDestroyedPost()"> нажмите здесь </a>.</div>
            <div class="col-12">
            <!-- head -->
                <div class="row bg_dark rounded-top">
                    <div class="col-sm-3">
                        <div class="row h-100 justify-content-center">
                            <div class="col-12">
                                <pnvIcon linkClass="row h-100 bwood_link" :inline="true" shape="circle" :url="wallPost.misc.avatar_url" size="sm" :path="prflPath">
                                    {{ wallPost.misc.badge_name }}
                                </pnvIcon>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 text-center bwd_txt_clr">
                        <div class="row h-100 justify-content-center">
                            <div class="my-auto pr-1" v-html="wallPost.misc.created">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 text-end">
                        <div class="row">
                            <div class="ml-auto pr-2 col-xs-1">
                                <a v-if="checkAbility('WallPost', 'feat_matl')"
                                    :href="actionPath('add_featured_materials', `?&wallpost_id=${wallPost.model.id}`)"
                                    data-method="put" 
                                    data-remote="true">
                                    <img class="icon-md p-2" alt="add to featured materials" :src="additionalInfo.assets.addToFeatMatlImgUrl">
                                </a>

                                <a v-if="checkAbility('WallPost', 'edit')" 
                                    :href="actionPath('edit')"
                                    data-remote="true">
                                    <img class="icon-md p-2" alt="edit on the post" :src="additionalInfo.assets.editPostImgUrl">
                                </a>

                                <a v-else 
                                    :href="complaintPath"
                                    data-remote="true">
                                    <img class="icon-md p-2" alt="complain the post" :src="additionalInfo.assets.complaintImgUrl">
                                </a>
                                <a v-if="checkAbility('WallPost', 'destroy')"
                                    data-confirm="Точно удалить?" 
                                    rel="nofollow" 
                                    :href="actionPath('delete')" 
                                    data-method="delete" 
                                    data-remote="true">
                                    <img alt="Удалить" :src="additionalInfo.assets.destroyPostImgUrl" class="icon-md p-2">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- head end -->
            <!-- body -->
                <div class="row wallPost dark_green_border" @click="showPostInModal">
                    <div class="w-100 m-3">
                        <pnvshowsection v-for="(section, key) in wallPost.model.content" :section="section" :key="key" :wallType="wallPost.model.wall_type"></pnvshowsection>
                        
                        <div id="repost_body" v-if="hasRepost" class="col-10 my-2 mx-4">
                            <div v-if="repostExists">
                                <pnvShowPost :additionalInfo="additionalInfoForRepost(additionalInfo.wallPost.repost)">
                                </pnvShowPost>
                            </div>
                            <div v-else>
                                Пост был удален.
                            </div>
                        </div>
                    </div>
                </div>
            <!-- body end -->
            <!-- footer -->
                <div class="row">
                    <div class="col-12 bg_dark rounded-bottom">
                        <pnvVotingBlock 
                            :assets="additionalInfo.assets" 
                            :cached_votes_up="wallPost.model.cached_votes_up" 
                            :reposts_count="wallPost.model.reposts_count" 
                            :comments_count="wallPost.model.comments_count" 
                            :show_comments_btn="additionalInfo.show_comments_btn" 
                            :parent_type="wallPost.model.wall_type" 
                            :parent_id="wallPost.model.wall_id.toString()" 
                            item_type="WallPost" 
                            :item_id="wallPost.model.id.toString()" 
                            :wasVoted="voted"
                            @show-post-in-modal="showPostInModal()">
                        </pnvVotingBlock>
                    </div>
                </div>
            <!-- footer end -->
            <!-- comments -->
                <div v-if="fromModal" class="row mt-3 modal-footer" >
                    <div class="col-12">
                        <div class="row" v-if="cmtSelectionMode">
                            <pnvItemSelector
                            @selection-submit="parseSubmitResult($event)" 
                            :selectedItemIds="selectedCmtsIds" 
                            :selectedItemIdsLength="selectedCmtsIds.length" 
                            :formRawConfig="dscnFormConfig"
                            selectionFieldName="seeds"
                            :prompt="selectorPrompt" 
                            :status="selectorStatus" 
                            :active="cmtSelectionMode"
                            @close="switchSelectCmntsAndForm()"></pnvItemSelector>
                        </div>
                        <div v-if="checkAbility('Comment', 'create')" class="row">
                            <div class="col-12">
                                Ваш Комментарий:
                            </div>
                            <small class="ml-2 text-muted">(От 2 до 300 символов)</small>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <pnv_form_factory 
                                        @submit-data="(params) => addNewCmnt(params)" 
                                        @form-field-chng="(params) => renewForm(params)"
                                        :initialFields="cmtFormConfig.fields"
                                        :config="cmtFormConfig.config"></pnv_form_factory>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2 btn">
                                Комментарии ({{ additionalInfo.wallPost.model.comments_count }})
                            </div>
                            <!-- <div class="col-2 btn">
                                Топ комментариев 
                            </div>
                            <div class="col-2 btn">
                                Мои комментарии
                            </div> -->
                        </div>
                        <div class="row ps-2">
                            <TransitionGroup id="commentWall" name="item" class="col-12">
                                <pnvComment 
                                    @selected="defineCmtInSelection($event)" 
                                    @dscn-form="initDscnForm($event)" 
                                    @upload-subcomments="uploadSubComments($event)"
                                    v-for="(comment, index) in sortedComments" 
                                    :key="index" 
                                    :comment="comment" 
                                    :assets="additionalInfo.assets" 
                                    :show_comments_btn="true" 
                                    :token="token" 
                                    :new_comment="newComment" 
                                    :selectionMode="cmtSelectionMode"
                                    :wasVoted="false"
                                    :current_profile_id="additionalInfo.current_profile_id"
                                    :privacyAbilities="additionalInfo.privacyAbilities">
                                </pnvComment>
                            </TransitionGroup>
                        </div>

                        <div v-show="additionalInfo.wallPost.model.comments_count > 0" class="row">
                            <div class="col-12" v-if="pageNumber">
                                <button @click="loadMoreComments()">Показать следующие  комментарии</button>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- comments end -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import pnvComment from "../mid/pnvComment.vue";
import pnvIcon from "../low/pnvIcon.vue";
import pnvshowsection from '../low/pnvShowSection.vue';
import pnvVotingBlock from "../low/pnvVotingBlock.vue";
import pnvItemSelector from "../mid/pnvItemSelector.vue";
import axios from 'axios';
import pnv_form_factory from '../mid/pnvFormFactory.vue'

export default {
    name: 'pnvShowPost',
    components: {
        pnvComment,
        pnvIcon,
        pnvshowsection,
        pnvVotingBlock,
        pnvItemSelector,
        pnv_form_factory,
    },
    props: {
        additionalInfo: { type: Object }
    },
    data () {
        return {
            wallPost: {},
            voted: false,
            destroyed: 0,
            repostCounter: 0,
            newComment: {},
            comments: {},
            token: '',
            fromModal: false,
            cmtSelectionMode: false,
            selectedCmtsIds: [],
            parentCmtId: null,
            selectorPrompt: '',
            selectorStatus: 0,
            pageNumber: 2,
            upd: 0,
            cmtFormConfig: {},
            errors: [],
        }
    },
    created () {
        this.wallPost = this.additionalInfo.wallPost
        this.voted = this.additionalInfo.wallPost.misc.voted
        this.comments = this.additionalInfo.comments
        this.token = this.additionalInfo.token
        this.fromModal = !!(this.additionalInfo.fromModal)
        this.pageNumber = this.additionalInfo.wallPost.nextPage
        this.cmntFormConfigSetup('')
    },
    computed: {
        wallpostHtmlId() {
            return 'wall_post_id_' + this.wallPost.model.id + (this.fromModal ? '_modal' : '')
        },
        sortedComments() {
            const sortedComms = Object.fromEntries(
                Object.entries(this.comments).sort(([,a],[,b]) => new Date(b.main.model.created_at)-new Date(a.main.model.created_at))
            );

            return sortedComms
        },
        canEditPost () {
            return (this.wallPost.profile.id === this.additionalInfo.current_profile_id)
        },
        prflPath() {
            const author = this.wallPost.model.comm_post ? "communities" : "profiles",
                  authorID = this.wallPost.model.comm_post ? this.wallPost.model.wall_id : this.wallPost.profile.id
            return "/" + author + "/" + authorID
        },
        complaintPath() {
            if (this.additionalInfo.current_profile_id) {
                return `/profiles/${this.additionalInfo.current_profile_id}/complaints/new?complainable_type=${this.wallPost.model.wall_type}&complainable_id=${this.wallPost.model.id}`
            } else {
                return ''
            }
            
        },
        voteHtmlId() {
            return 'voting_index_' + this.wallPost.model.id
        },
        voteHtmlClass() {
            return 'icon-nav vote' + ( !!(this.voted) ? ' shinyBox1 rounded-circle' : '' )
        },
        hasRepost() {
            return this.wallPost.model.repost_id !== null
        },
        repostExists() {
            return this.wallPost.repost !== null
        },
        dscnFormConfig () {
            return {
                fields: [
                    {
                        cName: 'pnvHiddenField',
                        fieldID: 'seeds', 
                        payload: {
                            choice: this.selectedCmtsIds,
                        },
                    },
                    {
                        cName: 'pnvHiddenField',
                        fieldID: 'parent_type', 
                        payload: {
                            choice: "Comment",
                        },
                    },
                    {
                        cName: 'pnvHiddenField',
                        fieldID: 'parent_id', 
                        payload: {
                            choice: this.parentCmtId,
                        },
                    },
                    {
                        cName: 'pnvString',
                        fieldID: 'title', 
                        payload: {
                            choice: 'today in brooney',
                            placeholder: 'test_placeholder_2',
                            title: 'Название дискуссии',
                            label: 'Название'
                        }
                    },
                    {
                        cName: 'pnvText',
                        fieldID: 'dscr', 
                        payload: {
                            choice: 'Sed ex libero, dapibus vel dapibus vitae, malesuada ut turpis. ',
                            title: 'Описание дискуссии',
                            label: 'Описание'
                        }   
                    },
                    {
                        cName: 'pnvCheckbox',
                        fieldID: 'peer', 
                        payload: {
                            label: 'Частная',
                            title: 'Только просмотр',
                            choice: 'false'
                        }   
                    },
                ],
                config: {
                    url: 'http://localhost:3000/discussions', 
                    method: 'post',
                    token: this.token,
                    html: {
                        obj_name: 'discussion',
                        title: "Создать",
                        controller: "discussions",
                        action: "create",
                    },
                },
            }
        },
        isRepost() {
            return this.additionalInfo.isRepost
        }
    },
    methods: {
        checkAbility(model, action) {
            let addInfo = {
                'isOwner': this.additionalInfo.current_profile_id === this.wallPost.model.profile_id
            }

            if (action === "edit") {
                addInfo.model = {
                    'wallType': this.wallPost.model.wall_type,
                    'createdAt': new Date(this.wallPost.model.created_at),
                    'votesCount': this.wallPost.model.cached_votes_total
                }
            } 

            return Helper.checkAbility(
                this.additionalInfo.privacyAbilities,
                model, 
                action,
                addInfo
            )
        },
        renewForm(params) {
            this.cmntFormConfigSetup(params['body']) 
        },
        cmntFormConfigSetup(theBodyVal) {
            this.cmtFormConfig = {
                fields: [
                    {
                        cName: 'pnvHiddenField',
                        fieldID: 'commentable_id', 
                        payload: {
                            choice: this.wallPost.model.id,
                            fieldID: 'commentable_id'
                        },
                    },
                    {
                        cName: 'pnvHiddenField',
                        fieldID: 'commentable_type', 
                        payload: {
                            choice: 'WallPost',
                            fieldID: 'commentable_type' 
                        },
                    },
                    {
                        cName: 'pnvText',
                        fieldID: 'body', 
                        payload: {
                            choice: theBodyVal,
                            fieldID: 'body'
                        }
                    },
                ],
                config: {
                    url: this.commentPath(), 
                    method: 'post',
                    token: this.additionalInfo.token,
                    inner: true,
                    html: {
                        obj_name: 'comment',
                        title: 'Создать', 
                        klass: 'btn btn-sm grnBtn1',
                    },

                },
            }
        },
        checkIfParentCmtInForm (cmtStatus) {
            return (cmtStatus) ? !(cmtStatus) : this.cmtSelectionMode
        },
        bgrd() {
            return this.destroyed === 2 ? 'background-image: linear-gradient(45deg, #d65c5c 25%, #F6F0CF 25%, #F6F0CF 50%, #d65c5c 50%, #d65c5c 75%, #F6F0CF 75%, #F6F0CF 100%);background-size: 33.94px 33.94px;padding:0.5rem;' : ''
        },
        actionPath(action_type = '', params='') {
            // edit, [ upvote, unvote, downvote ], repost, comment
            // params can contain string of additional request params like:
            //  '?param1=val1&param2=val2&...&paramN=valN'
            if (action_type === 'delete') {
                return `/wall_posts/${this.wallPost.model.id}/${params}`
            } else if (action_type === "add_featured_materials") {
                return `${Helper._url}${Helper.pluralModel(this.wallPost.model.wall_type)}/${this.wallPost.model.wall_id}/${action_type}${params}`
            } else {
                return `/wall_posts/${this.wallPost.model.id}/${action_type}${params}`
            }
        },
        votePath(type = "upvote") {
            // [ upvote, unvote, downvote ]
            if (this.voted) {
                // post has received our vote
                // we can remove it
                // return `/wall_posts/${this.wallPost.id}/unvote`
                return this.actionPath('unvote')
            } else {
                /* Switched off until downvoting is active
                if (type == "downvote" && this.wallPost.misc.canDownvote) {
                    // user has additional privilegies
                    // they can also downvote
                    // return `/wall_posts/${this.wallPost.id}/downvote`
                    return this.actionPath(type)
                }*/
                // post can be upvoted
                // return `/wall_posts/${this.wallPost.id}/upvote`
                return this.actionPath(type)
            }
        },
        vote(theData) {
            const data = theData.detail
            this.wallPost.model.reposts_count = data.reposts_count
            this.wallPost.model.cached_votes_up = data.votes_count
            this.wallPost.model.comments_count = data.comments_count
            this.wallPost.misc.voted = data.success
            this.voted = ( data.message === "+1" )
        },
        // TODO Replace it with plain links
        setUpPostInfo(wallPost = null) {
            const postInfo = {}

            postInfo.token = this.additionalInfo.token
            postInfo.wall_type = this.additionalInfo.wall_type
            postInfo.wall_id = this.additionalInfo.wall_id
            postInfo.postId = this.$vnode.key
            postInfo.wallPost = wallPost
            postInfo.assets = this.additionalInfo.assets
            postInfo.canManagePost = this.additionalInfo.canManagePost

            return postInfo
        },
        postEdit() {
            this.$root.$emit('wallPostForm', this.setUpPostInfo());
        },
        showDestroyedPost() {
            this.destroyed = 2
        },
        hideDestroyedPost() {
            this.destroyed = 1
        },
        loadMoreComments() {
            const self = this,
                  route = Helper._url + 'wall_posts/' + this.wallPost.model.id + '/get_comments.json',
                  data = {
                    page: this.pageNumber
                  }

            axios.get(route, {params: data})
                .then(response => {
                    for (let [key, value] of Object.entries(response.data.comments)) {
                        self.$set(self.comments, key, value)
                        // self.comments[key] = value
                    }

                    for (let [key, value] of Object.entries(response.data.dscns)) {
                        self.$set(self.wallPost.dscns, key, value)
                        // self.wallPost.dscns[key] = value
                    }

                    if (response.data.next_page) {
                        self.pageNumber++
                    } else {
                        self.pageNumber = null
                    }
                })
                .catch(e => {
                    self.errors.push(e)
                })

            // this.$emit('get-more-comments', { 
            //             wallPostID: this.wallPost.model.id, 
            //             page: this.pageNumber});
        },
        additionalInfoForRepost(theWallRepost) {
            const additionalInfo = {}
            const [wall_type, wall_id, token, wallPost, current_profile_id, isRepost] = [ theWallRepost.wall_type, theWallRepost.wall_id, this.token, theWallRepost, this.current_profile_id, true ];
            Object.assign(additionalInfo, {wall_type, wall_id, token, wallPost, current_profile_id, isRepost});
            if (theWallRepost !== null) {
                additionalInfo.canManagePost = 'false'
                additionalInfo.assets = this.additionalInfo.assets
            }
            
            return additionalInfo
        },
        reloadCmnts(cmntsData) {
            /*const [wallPost, token, current_profile_id, assets, canManagePost, showComments, comments, show_comments_btn ] = [ postData.wallPost, postData.token, postData.current_profile_id, postData.assets, postData.canManagePost, postData.show_comments, postData.comments, false ];
            Object.assign(this.additionalInfo, {wallPost, token, current_profile_id, assets, canManagePost, showComments, comments, show_comments_btn });*/
            // console.log("event")
            this.newComment = cmntsData.newComment
            this.comments = cmntsData.comments
            this.token = cmntsData.token
            // debugger
            if (this.newComment.parent_hash === "") {
                document.getElementById(cmntsData.htmlFormId).reset();
            } else {
                // event = new CustomEvent("close-form");
                // document.getElementById(cmntsData.htmlFormId).dispatchEvent(event);
            }

            // document.getElementById(cmntsData.htmlFormId).reset();
        },
        parseSubmitResult(response) {
            response.isAxiosError ? this.showDscnError(response) : this.startNewDscn(response)
        },
        startNewDscn (response) {
            if ( false ) {
                console.log("in development - startNewDscn")
                console.log("response: " + JSON.stringify(response))
                console.log("response: " + JSON.stringify(response.dscn))
                // TEST_CODE
                this.selectorPrompt = response.msg
                this.selectorStatus = response.status
                // http://localhost:3000/discussions/1
                window.location.href = 'http://localhost:3000/discussions/' + response.dscn.id;

            } else {
                if (!!response) {
                    this.selectorPrompt = response.msg
                    this.selectorStatus = response.status
                    window.location.href = 'http://localhost:3000/discussions/' + response.dscn.id;
                } else {
                    console.log( "response: " + ( typeof response === 'object' ? JSON.stringify(response) : response ) )
                    this.selectorPrompt = "Ошибка при создании дискуссии"
                    this.selectorStatus = "Ошибка"
                }
            }
        },
        showDscnError (error) {
            this.selectorPrompt = "Ошибка при создании дискуссии. Сервис дискуссий временно не доступен."
            this.selectorStatus = 500
        },
        switchSelectCmntsAndForm() {
            this.cmtSelectionMode = false
        },
        initDscnForm (cmtId) {
            // TODO Make parent_id field work
            this.parentCmtId = cmtId
            // this.selectedCmtsIds.push(cmtId)
            this.cmtSelectionMode = true
        },
        defineCmtInSelection (theCmtStatus) {
            if (theCmtStatus[0] === this.parentCmtId) {
                console.log("Комментарий-база дискуссии обязательно должен быть добавлен в саму дискуссию!")
                throw 'Wrong action!'
                return 
            }
            if (theCmtStatus[1]) {
                this.selectedCmtsIds.push(theCmtStatus[0])
            } else {
                this.selectedCmtsIds = this.selectedCmtsIds.filter(theKey => theKey !== theCmtStatus[0]);
            }
            /*console.log("defineCmtInSelection: ")
            console.log(theCmtStatus)
            console.log(this.selectedCmtsIds)*/
        },
        showPostInModal() {
            this.$emit('show-post-in-modal');
        },
        uploadSubComments(commentObj) {
            const self = this,
                  route = (Helper._url + 'comments/' + commentObj.commentID + '/replies.json'),
                  data = {
                      commentable_type: 'WallPost',
                      commentable_id: this.wallPost.model.id,
                      hash_id: commentObj.commentHash,
                      updated_at: commentObj.updatedAt
                  }
            self.commentHash = commentObj.commentHash

            axios.get(route, {params: data})
                .then(response => {
                    // console.log("response")
                    // console.log(response)

                    if (response.data.nesting.length > 0) {
                        let mainCmntPath, childrenPath
                        response.data.nesting.forEach((depth) => {
                            mainCmntPath = self.comments[depth]
                            childrenPath = response.data.comments[depth]
                        });

                        mainCmntPath.children[self.commentHash].children = childrenPath.children[self.commentHash].children

                        mainCmntPath.children[self.commentHash].main = childrenPath.children[self.commentHash].main
                    } else {
                        if (Object.keys(response.data.comments).length) {
                            self.comments[self.commentHash].children = response.data.comments[self.commentHash].children 

                            self.comments[self.commentHash].main = response.data.comments[self.commentHash].main
                        }
                    }
                })
                .catch(e => {
                    // console.log(e)
                    this.errors.push(e)
                })
        },
        commentPath() {
            return Helper._url + '/comments.json'
        },
        addNewCmnt(params) {
            const post = this;
            // console.log( "addNewCmnt: " + ( typeof params === 'object' ? JSON.stringify(params) : params ) )
            params["authenticity_token"] = post.additionalInfo.token

            Helper.makeRqst(
                post.commentPath(), params, { method: 'post' }).
                then( response => {
                    // console.log( "addNewCmnt: " + ( typeof response === 'object' ? JSON.stringify(response) : response ) )
                    //  Vue.set(object, key, value) 
                    post.$set(post.comments, response.comment.main.model.hash_id, response.comment)
                    post.wallPost.model.comments_count++
                    post.cmntFormConfigSetup('')
                } ).
                catch( e => {
                    post.errors.push(e)
                } )
        },
    }
}
</script>

<style>
    .voting_block {
        font-family: Roboto;
        font-size: 16px;
    }
    .wallPost {
        background-color: #F0DB9F;
    }
    .dark_green_border {
        border: 2px solid rgba(51, 102, 51, 0.8);
    }
/* TODO Move appearing animation to separate file 
to share it between components */
/* 1. declare transition */
.item-move,
.item-enter-active,
.item-leave-active {
  transition: all 2s cubic-bezier(0.1, 0.5, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.item-enter-from,
.item-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.item-leave-active {
  position: absolute;
}
</style>
