<template>
<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <label :for="fieldID">{{label}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <input type="text" class="w-100" v-model="theValue" :placeholder="placeholder" :title="title">
            </div>
            <div class="col-12">
                <ul style="list-style-type:none;">
                    <li v-for="item in acItems">
                        <a @click.prevent="selectVal(item.id)"> {{ item.title }} </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "pnvString",
    data() {
        return {
            theValue: '',
            acOn: false, // autocompleteOn
            acOff: false,
            globalAc: false,
            /*theConfig: {
                isBottomSectionToggled: false,
                optionsHeight: 0,
                optionHeight: 35,
                backgroundColor: "#336633",
                backgroundExpandedColor: "darkolivegreen",
                hoverBackgroundColor: "#336633",
                borderRadius: "1.5em",
                textColor: "antiquewhite",
                boxShadow: "1px 1px 4px #330033",
                border: "2px solid #ccc",
                showArrow: true
            }*/
        }
    },
    props: {
        choice: { type: String },
        placeholder: { type: String, default: '' },
        title: { type: String, default: '' },
        label: { type: String, default: '' },
        fieldID: { type: String },
        autocompleteOn: { type: Boolean, default: false },
        acItems: { type: Object }, // autocomplete items received from rqst
        // config: { type: Object, default: function() { return {} } },
        // content_size: { type: Number, default: null }
    },
    mounted() {
        this.theValue = this.choice
        this.acOn = this.autocompleteOn
        this.globalAc = this.autocompleteOn
    },
    watch: {
        choice: function (val) {
            // console.log("choice: " + val)
            this.theValue = this.choice
            if (this.globalAc) {
                this.acOff = true
            }
        },
        theValue: function (val) {
            if (this.globalAc) {
                if (this.acOff) {
                    this.acOn = true
                    this.acOff = false
                } else {
                    this.$emit( ( this.acOn ? 'autocomplete' : 'setStringValue' ), this.theValue);
                }
            } else {
                this.$emit('setStringValue', this.theValue);
            }
        },
    },
    computed: {
        // computedStyles: function() {
        //     if (this.content_size != null) {
        //         let width  = 79;
        //         if (this.theConfig.showArrow) {
        //             width += 40
        //         }
        //         width += 3 * this.content_size.toString().length
        //         this.width = width
        //     }
        //     return [
        //         { "--options-height": this.theConfig.optionsHeight + "px" },
        //         { "--options-height-neg": "-" + this.theConfig.optionsHeight + "px" },
        //         { "--main-el-border-radius": this.theConfig.borderRadius },
        //         { "--dropdown-width": this.theConfig.width + "px" },
        //         { "--dropdown-background-color": this.theConfig.backgroundColor },
        //         { "--dropdown-expanded-color": this.theConfig.backgroundExpandedColor },
        //         { "--dropdown-border": this.theConfig.border },
        //         { "--dropdown-box-shadow": this.theConfig.boxShadow },
        //         { "--dropdown-hover-background-color": this.theConfig.hoverBackgroundColor },
        //         { "--dropdown-default-text-color": this.theConfig.textColor }
        //     ];
        // }
    },
    methods: {
        selectVal (id) {
            this.acOn = false
            this.$emit('setStringValue', [ this.acItems[id].title, id ]);
        },
    }
};
</script>