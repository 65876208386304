<template>
<a @click="launchParentEvent($event)" :href="computedPath" :title="badgeName" :class="linkClass" :data-remote="remoteStatus">
    {{truncateName}}
    <div class="col-12" style="min-width: 80px;">
        <div v-if="inline" class="row h-100">
            <div class="col-3">
                <div class="p-0 row h-100">
                    <div :class="classHtml" :style="background"></div>
                </div>
            </div>
            <div class="col-9">
                <div class="row pt-1 h-100 justify-content-center">
                    <div class="col-12 text-center">
                        <div class="row h-100">
                            <div class="my-auto">
                                <slot>
                                    {{name}}
                                    <br>
                                    {{surname}}
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-12">
                <div class="row">
                    <div :class="classHtml" :style="background"></div>
                </div>
                <div v-if="showTitle" class="row">
                    <div class="mx-auto text-center">
                        <slot>
                            {{name}}
                            <br>
                            {{surname}}
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>
</template>

<script>
export default {
    name: 'pnvicon',
    props: {
        url: String,
        size: String, 
        shape: String,
        showTitle: Boolean,
        inline: Boolean,
        path: String,
        linkClass: String,
        remoteStatus: Boolean,
        badgeName: String,
        event: String
    },
    data () {
        return {
            name: '',
            surname: '',
            truncateLength: 10
        }
    },
    computed: {
        computedPath() {
            if (this.path == "empty") {
                return "#"
            } else {
                return this.path
            }

        },
        computedShape () {
            if (this.shape == 'rounded') {
                return this.shape;
            } else if (this.shape == 'circle') {
                return "rounded-circle";
            } else {
                return "";
            }
        },
        classHtml () {
            return ["p-0 m-auto", "icon-" + this.size, this.computedShape].join(" ");
        },
        background () {
            return "background-image: url(" + this.url + ");background-repeat: no-repeat;display: flex;background-size:" + Helper.sizeToPixels(this.size) + "px;"
        },
        truncateName () {
            const badgeName = !!(this.badgeName) ? this.badgeName : ""
            return this.splitName(badgeName, this.truncateLength);
        }
    }, 
    methods: {
        launchParentEvent(event) {
            if (event && !!(this.event)) {
                event.preventDefault();
            }
            this.$emit("pnvIconEvent", this.event);
        },
        splitName(fullname, length) {
            // Split fullname into two parts.
            const splitedFullname = fullname.split(" ");
            
            // Truncate name if needed.
            this.name = splitedFullname[0];
            if (this.name.length > length) {
                this.name = this.name.slice(0, length - 3) + '...';
            }

            // If surname exists, do the same.
            if (splitedFullname.length > 1) {
                this.surname = splitedFullname[1];
                if (this.surname.length > length) {
                    this.surname = this.surname.slice(0, length - 3) + '...';
                }    
            } 
        }
    }
}
</script>
